<template>
    <div>
    <el-container >
        <el-main height="">
           <el-form :model="item" ref="item" :rules="rules" label-width="100px" size="small">
               <el-form-item label="名称:" prop="name">
                   <el-input v-model.trim="item.name" placeholder="请输入名称"></el-input>
               </el-form-item>
                <el-form-item label="标识:" >
                   <el-input v-model.trim="item.flag" placeholder="请输入标识"></el-input>
               </el-form-item>
                <el-form-item label="值:">
                   <el-input v-model.trim="item.data" placeholder="请输入值"></el-input>
               </el-form-item>
                <el-form-item label="排序号:" prop="seq">
                   <el-input v-model.trim="item.seq" placeholder="请输入排序号"></el-input>
               </el-form-item>
                <el-form-item label="状态:">
                    <el-switch v-model="item.status" :active-value="1" :inactive-value="0" active-text="启用"
                    inactive-text="禁用">
                </el-switch>
               </el-form-item>
               <el-form-item>
                   <el-button type="primary" @click="submit">提交</el-button>
                   <el-button @click="close">关闭</el-button>
               </el-form-item>
           </el-form>
        </el-main> 
    </el-container>
    </div>
</template>

<script>
import DictionaryService from '../../../service/systemManage/DictionaryService';
    export default {
        data()
        {
            return{
                "item":{"categorySN": sessionStorage.getItem("categorySN"),"status":1},
                rules: {
                    name: {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    },
                      seq: {
                        required: true,
                        message: '请输入排序号',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted()
        {
        },
        methods:
        {
            //提交
            submit()
            {
                this.$refs.item.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.item.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add()
            {
               	DictionaryService.addItem(this.item, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update()
            {
 	            DictionaryService.updateItem(this.item, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close()
            {
              this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>