import workOrderView  from '../view/workOrder/WorkOrderView';
import listView from '../view/workOrder/ListView';
import newView from '../view/workOrder/NewView';
export default{
    path:'workOrder',
    component:workOrderView,
    children:[
        {
            path:'list',
            component:listView
        },
        {
            path:'new',
            component:newView
        }
    ]
}