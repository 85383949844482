import axios from '../config/AxiosConfig';
import {Message} from 'element-ui';
import LoadingHelper from '../util/LoadingHelper';
//axios帮助类
class AxiosHelper {
    //post请求
    static post(url, data, responseHandle) {
        var loading=LoadingHelper.showLoading();
        axios.post(url, data)
            .then(res => {
                if(res!=undefined)
                {
                responseHandle(res.data);
                }
                loading.hide();
            })
            .catch(error => {
                // Message.error({
                //     message: error,
                //     type: 'error'
                // })
                 loading.hide();
            })
    }
    //get请求
    static get(url, params, responseHandle) {
        var loading=LoadingHelper.showLoading();
        axios.get(url, {
                params: params
            })
            .then(res => {
                if(res!=undefined)
                {
                responseHandle(res.data);
                }
                loading.hide();
                
            })
            .catch(error => {
                // Message.error({
                //     message: error,
                //     type: 'error'
                // })
                 loading.hide();
            })
    }
    // 文件下载
    static download = (url, params,handle) => {
        axios.get(url, {
            responseType: 'blob',
            params: params
        }).then((res) => {
            const fileName = res.config.params.fileName;
            let url = window.URL.createObjectURL(new Blob([res.data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            console.log(res);
            link.click();
            handle();
        })

    };
	// 文件导出
	static export = (url, params, handle) => {
			console.log("进来这边",url,params)
	    axios.get(url, {
	        responseType: 'blob',
	        params: params
	    }).then((res) => {
			console.log(">>>>",res)
	        const filename = decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]) || '.xls'
	        const blob = new Blob([res.data], {
	        type: 'application/octet-stream'
	      })
	        let url = window.URL.createObjectURL(blob);
	        let link = document.createElement('a');
	        link.style.display = 'none';
	        link.href = url;
	        link.setAttribute('download', filename);
	        document.body.appendChild(link);
	        link.click();
	    })
	
	};
    //设置请求头
    static setHeader(key, value) {
        axios.defaults.headers[key] = value;
    }
}
export default AxiosHelper;