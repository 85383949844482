<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form :model="user" ref="user" :rules="rules" label-width="100px" size="small">
                    <el-form-item label="用户ID:" prop="userId">
                        <el-input v-model.trim="user.userId" placeholder="请输入用户ID"  ></el-input>
                    </el-form-item>
                    <el-form-item label="姓名:" prop="name">
                        <el-input v-model.trim="user.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="密码:" prop="password" v-show="user.id==null">
                        <el-input v-model.trim="user.password" placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="部门:" prop="departmentSN">
                        <el-select v-model="user.departmentSN" filterable placeholder="请选择">
                            <el-option v-for="item in departmentList" :key="item.id" :label="item.name"
                                :value="item.sn">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色:" prop="roleSN">
                        <el-select v-model="user.roleSN" filterable placeholder="请选择">
                            <el-option v-for="item in roleList" :key="item.id" :label="item.name"
                                :value="item.sn">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-switch v-model="user.status" :active-value="1" :inactive-value="0" active-text="启用"
                            inactive-text="禁用">
                        </el-switch>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit">提交</el-button>
                        <el-button @click="close">关闭</el-button>
                    </el-form-item>
                </el-form>

            </el-main>

        </el-container>

    </div>
</template>

<script>
    import DepartmentService from '../../../service/systemManage/DepartmentService';
    import UserService from '../../../service/systemManage/UserService';
    import RoleService from '../../../service/systemManage/RoleService';
    export default {
        data() {
            return {
                "user": {
                    "status": 1
                },
                "departmentList": [],
                "roleList": [],
                rules: {
                     "userId": {
                        required: true,
                        message: '用户ID不能为空',
                        trigger: 'blur'
                    },
                    "name": {
                        required: true,
                        message: '用户名称不能为空',
                        trigger: 'blur'
                    },
                    "password": {
                        required: true,
                        message: '密码不能为空',
                        trigger: 'blur'
                    },
                    "departmentSN": {
                        required: true,
                        message: '请选择部门',
                        trigger: 'blur'
                    },
                    "roleSN": {
                        required: true,
                        message: '请选择角色',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted() {
            //获取部门列表
            this.getDepartmentList();
            //获取角色列表
            this.getRoleList();
        },
        methods: {
            //获取部门列表
            getDepartmentList() {
                var params={
                    "pageIndex":1,
                    "pageSize":1000,
                    "status":1
                }
               DepartmentService.getList(params,(response)=>{
                 this.departmentList=response.list;
               })
            },
            //获取角色列表
            getRoleList() {
                var params={
                    "pageIndex":1,
                    "pageSize":1000,
                    "status":1
                }
               RoleService.getList(params,(response)=>{
                 this.roleList=response.list;
               })
            },
            //提交
            submit() {
                this.$refs.user.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.user.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                UserService.add(this.user, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                UserService.update(this.user, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>