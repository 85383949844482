import AxiosHelper from '../../util/AxiosHelper';
class MapInfoService
{
    //获取列表
    static getList(params,handle)
    {
      AxiosHelper.get("/map/mapInfo/getList",params,handle);
    }
   //新增
   static add(obj,handle)
   {
    AxiosHelper.post("/map/mapInfo/add",obj,handle);
   }
   //修改
   static update(obj,handle)
   {
    AxiosHelper.post("/map/mapInfo/update",obj,handle);
   }
   //删除
   static delete(params,handle)
   {
    AxiosHelper.post("/map/mapInfo/delete",params,handle);
   }
    //获取地图列表
    static getDocumentList(params,handle)
    {
       AxiosHelper.get("/map/mapInfo/getDocumentList",params,handle);
    }
    //通过系统获取地图列表
    static getListBySystem(params,handle)
    {
      AxiosHelper.get("/map/mapInfo/getListBySystem",params,handle);
    }
    //通过编号获取地图
    static getBySN(params,handle)
    {
      AxiosHelper.get("/map/mapInfo/getBySN",params,handle);
    }
    //删除文件
    static deleteFile(obj,handle)
    {
       // alert(JSON.stringify(obj));
     AxiosHelper.post("/file/delete",obj,handle);
    }
    //下载文件
    static downloadFile(parms,handle)
    {
       AxiosHelper.download("/file/download",parms,handle);
    }

}
export default MapInfoService;