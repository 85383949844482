import Vue from 'vue';
import Loading from 'vue-loading-overlay';
Vue.use(Loading);
class LoadingHelper
{
    //显示加载
    static showLoading()
    {
        return Vue.$loading.show({
            "is-full-page":false,
            "loader":"bars",
            "color":"#99CCFF",
            "opacity": 0.1,
            "width": 64,
            "height": 64
        });
    }
    //关闭加载
    static closeLoading(obj)
    {
        obj.hide();
    }
}
export default LoadingHelper;