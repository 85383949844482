import RegionView from '../view/region/RegionView';
import View_Region_List from '../view/region/ListView';
export default {
    path:'region',
    component:RegionView,
    children:[
        {
            path:'regionInfo/list',
            component:View_Region_List
        }
    ]
}