import Vue from 'vue'
import App from './App.vue'
import route from './route';
import store from './store';
import Router from 'vue-router';
import ElementUI from 'element-ui';
import * as  Echarts from 'echarts';
import 'vue-loading-overlay/dist/vue-loading.css'
import 'element-ui/lib/theme-chalk/index.css'
import  './assets/css/style.css'
Vue.prototype.$echarts=Echarts;
Vue.use(ElementUI)
Vue.config.productionTip = false;
Vue.use(Router)
var context=new Vue({
  router:route,
  store,
  render: h => h(App),
}).$mount('#app')
export default context;