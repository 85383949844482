<template>
    <div>
        <el-container>
            <el-container>
                <el-container>
                    <el-main height="">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="基本信息" name="basicInfo">
                                <el-form :model="projectInfo" ref="projectInfo" :rules="rules" label-width="100px"
                                    size="small">
                                    <el-form-item label="客户:" prop="customerSN">
                                        <el-select v-model="projectInfo.customerSN" filterable placeholder="请选择">
                                            <el-option v-for="item in customerList" :key="item.sn" :label="item.name"
                                                :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="项目编号:">
                                        <el-input v-model.trim="projectInfo.projectSN" placeholder="请输入项目编号"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目名称:" prop="name">
                                        <el-input v-model.trim="projectInfo.name" placeholder="请输入名称"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目经理:" >
                                        <el-input v-model.trim="projectInfo.leader" placeholder="请输入项目经理"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态:">
                                        <el-switch v-model="projectInfo.status" :active-value="1" :inactive-value="0"
                                            active-text="启用" inactive-text="禁用">
                                        </el-switch>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                            <!-- <el-tab-pane label="其他信息" name="second">
                            </el-tab-pane> -->
                        </el-tabs>
                    </el-main>
                    <el-footer height="">
                        <el-button size="mini" type="primary" @click="submit">提交</el-button>
                        <el-button size="mini" @click="close">关闭</el-button>
                    </el-footer>
                </el-container>
            </el-container>
        </el-container>

    </div>
</template>
<script>
    import CustomerInfoService from '../../../service/customer/CustomerInfoService';
    import ProjectInfoService from '../../../service/project/ProjectInfoService';
    export default {
        data() {
            return {
                activeName: 'basicInfo',
                customerList: [],
                groupList: [],
                projectInfo: JSON.parse(sessionStorage.getItem("projectInfo")),
                rules: {
                    customerSN: {
                        required: true,
                        message: '请选择客户',
                        trigger: 'blur'
                    },
                    name: {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                }
            }
        },
        mounted() {
            //初始化
            this.init();
            //获取客户列表
            this.getCustomerList();
        },
        methods: {
            //初始化
            init()
            {
              if(this.projectInfo.id==null)
              {
                  this.projectInfo.status=1;
              }
            },
            //获取客户列表
            getCustomerList() {
                var params = {
                    pageIndex: 1,
                    pageSize: 1000,
                    status: 1
                }
                CustomerInfoService.getList(params, (response) => {
                    this.customerList = response.list;
                })
            },
            submit() {
                this.$refs.projectInfo.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.projectInfo.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                ProjectInfoService.add(this.projectInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                ProjectInfoService.update(this.projectInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>