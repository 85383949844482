import AxiosHelper from '../../util/AxiosHelper';
class ModuleService
{
    //获取列表
   static getList(params,handle)
   {
    AxiosHelper.get("/systemManage/module/getList",params,handle);
   }
   //获取父列表
   static getParentList(params,handle)
   {
     AxiosHelper.get("/systemManage/module/getParentList",params,handle);
   }
   //新增
   static add(obj,handle)
   {
    AxiosHelper.post("/systemManage/module/add",obj,handle);
   }
   //修改
   static update(obj,handle)
   {
    AxiosHelper.post("/systemManage/module/update",obj,handle);
   }
   //删除
   static delete(obj,handle)
   {
      AxiosHelper.post("/systemManage/module/delete",obj,handle);
   }
   //获取模块列表
   static getModuleList(params,handle)
   {
    AxiosHelper.get("/systemManage/module/getModuleList",params,handle);
   }
   //获取图标列表
   static getIconList(params,handle)
   {
    AxiosHelper.get("/systemManage/module/getIconList",params,handle);
   }
    //删除文件
    static deleteFile(obj,handle)
    {
     AxiosHelper.post("/file/delete",obj,handle);
    }
      //下载文件
   static downloadFile(parms,handle)
   {
      AxiosHelper.download("/file/download",parms,handle);
   }
}
export default ModuleService;