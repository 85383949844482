import AxiosHelper from '../../util/AxiosHelper';
class equipmentInfoService{
    //获取列表
   static getList(params,handle)
   {
    AxiosHelper.get("/equipment/equipmentInfo/getList",params,handle);
   }
   //新增
   static add(obj,handle)
   {
     AxiosHelper.post("/equipment/equipmentInfo/add",obj,handle);
   }
   //修改
   static update(obj,handle)
   {
     AxiosHelper.post("/equipment/equipmentInfo/update",obj,handle);
   }
   //删除设备
   static deleteEquipment(obj,handle)
   {
     AxiosHelper.post("/equipment/equipmentInfo/delete",obj,handle);
   }
   //获取总数
   static getTotal(obj,handle)
   {
    AxiosHelper.post("/equipment/equipmentInfo/getTotal",obj,handle);
   }
   //获取文档列表
   static getDocumentList(params,handle)
   {
     AxiosHelper.get("/equipment/equipmentInfo/getDocumentList",params,handle);
   }
   //删除文件
   static deleteFile(obj,handle)
   {
    AxiosHelper.post("/file/delete",obj,handle);
   }
   //下载文件
   static downloadFile(parms,handle)
   {
      AxiosHelper.download("/file/download",parms,handle);
   }
   //获取设备在线率
   static getOnlineRate(params,handle)
   {
     AxiosHelper.get("/equipment/equipmentInfo/getOnlineRate",params,handle);
   }
   //获取磁盘信息列表
   static getDiskInfoList(params,handle)
   {
    AxiosHelper.get("/equipment/equipmentInfo/getDiskInfoList",params,handle);
   }
   //获取数量统计
   static getCountStatistics(params,handle)
   {
    AxiosHelper.get("/equipment/equipmentInfo/getCountStatistics",params,handle);
   }
   //获取分类数量统计
   static getCategoryCountStatistics(params,handle)
   {
    AxiosHelper.get("/equipment/equipmentInfo/getCategoryCountStatistics",params,handle);
   }
}
export default equipmentInfoService;