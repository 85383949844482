<template>
    <div>
    <el-container :direction="vertical">
        <el-container :direction="horizontal">
            <el-container :direction="vertical">
                <el-main>
                    <el-table size="small" :data="list" border stripe header-cell-class-name="tableHeader">
                            <el-table-column prop="totalCount" label="故障总数">
                            </el-table-column>
                            <el-table-column prop="finishCount" label="已处理数">
                            </el-table-column>
                             <el-table-column label="处理率">
                                <template slot-scope="scope">
                                    {{scope.row.finishRate.toFixed(2)}}%
                                </template>
                            </el-table-column>
                        </el-table>
                </el-main>
            </el-container>
        </el-container>
    </el-container>
    
    </div>
</template>

<script>
import AlarmInfoService from '../../service/alarm/AlarmInfoService';
    export default {
        data() {
            return {
                 list: [{
                    totalCount: 0,
                    finishCount: 0,
                    finishRate: 0
                }]
            }
        },
        mounted() {
             //获取完成率
            this.getFinishRate();
        },
        methods: {
              //获取完成率
            getFinishRate() {
                AlarmInfoService.getFinishRate(null, (response) => {
                    this.list[0].totalCount = response.data.totalCount;
                    this.list[0].finishCount = response.data.finishCount;
                    this.list[0].finishRate = response.data.finishRate;
                });
            }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>