import AxiosHelper from '../../util/AxiosHelper';
class DepartmentService
{
    //获取列表
    static getList(params,handle)
    {
        AxiosHelper.get("/systemManage/department/getList",params,handle);
    }
    //新增
    static add(obj,handle)
    {
        AxiosHelper.post("/systemManage/department/add",obj,handle);
    }
    //修改
    static update(obj,handle)
    {
        AxiosHelper.post("/systemManage/department/update",obj,handle);
    }
}
export default DepartmentService;