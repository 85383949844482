import IndexView from '../view/IndexView';
import ContentView from '../route/ContentRoute';
export default
{
    path:'/index',
    name:'index',
    component:IndexView,
    meta: {
        title: '首页'
      },
    children:[
       ContentView
    ]
}