import ReportView from '../view/report/ReportView';
import EquipmentOnlineRateView from '../view/report/EquipmentOnlineRateView';
import AlarmFinishRateView from '../view/report/AlarmFinishRateView';
export default {
    path:'report',
    component:ReportView,
    children:[
        {
            path:'equipmentOnlineRate',
            component:EquipmentOnlineRateView
        },
        {
            path:'alarmFinishRate',
            component:AlarmFinishRateView
        }
    ]
}