<template>
    <div>
        <el-container>
            <el-header height="">
                <el-upload class="upload-demo" ref="upload" :data="params" :action="uploadUrl" :headers="headers" :auto-upload="false"
                    :on-success="uploadSuccess">
                    <el-button slot="trigger" icon="el-icon-search" size="mini" round>选取文件</el-button>
                    <el-button style="margin-left: 10px;" size="mini" icon="el-icon-upload" round @click="submitUpload">
                        上传
                    </el-button>
                </el-upload>
            </el-header>
            <el-main height="">
                <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader" v-loading="loading" element-loading-text="文件下载中"  element-loading-spinner="el-icon-loading"  element-loading-background="rgba(0, 0, 0, 0.1)">
                    <el-table-column type="index" label="序号" width="60px">
                        <template slot-scope="scope">
                            {{scope.$index+1+(pageIndex-1)*pageSize}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileName" label="文件名">
                    </el-table-column>
                    <el-table-column prop="fileExtension" label="类型">
                    </el-table-column>
                    <el-table-column label="操作" width="240px">
                        <template slot-scope="scope">
                            <el-button size="mini" icon="el-icon-close" @click="deleteFile(scope.row)" round>删除
                            </el-button>
                            <el-button size="mini" icon="el-icon-download" @click="downloadFile(scope.row)" round>下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer height="">
                    <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-main>
            <el-row :gutter="20">
                <el-col :span="12" :offset="0">
                    <el-button @click="close">关闭</el-button>
                </el-col>
            </el-row>
        </el-container>
    </div>
</template>

<script>
    import ServerConfig from '../../../config/ServerConfig';
   import SupplierInfoService from '../../../service/supplier/SupplierInfoService';
    export default {
        data() {
            return {
                "loading":false,
                 "headers":{
                    Authorization:"Bearer "+localStorage.getItem("access_token")
                },
                "params": {
                    "src": "supplier",
                    "businessSN": sessionStorage.getItem("supplierSN")
                },
                "uploadUrl": "http://" + ServerConfig.server + ":" + ServerConfig.port + "/file/upload",
                "list": [],
                "recordCount": 0,
                "pageIndex": 1,
                "pageSize": 10
            }
        },
        mounted() {
            //获取文档列表
            this.getDocumentList();
        },
        methods: {
            //获取文档列表
            getDocumentList() {
                var params = {
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize,
                    "supplierSN": sessionStorage.getItem("supplierSN")
                }
                SupplierInfoService.getDocumentList(params, (response) => {
                    this.list = response.list;
                    this.recordCount = response.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //上传
            submitUpload() {
                this.$refs.upload.submit();
            },
            //上传成功
            uploadSuccess(response, file) {
                this.$message({
                    message: response.msg,
                    type: 'success'
                })
                this.getDocumentList();
            },
            //删除文件
            deleteFile(obj) {
                var obj = {
                    "src": "supplier",
                    "businessSN": obj.supplierSN,
                    "filePath": obj.filePath
                }
                this.$confirm("您确定要删除此文件吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    SupplierInfoService.deleteFile(obj, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getDocumentList();
                    })
                }).catch(() => {})

            },
            //下载文件
            downloadFile(obj) {
                var params = {
                    "fileName": obj.fileName+"."+obj.fileExtension,
                    "filePath": obj.filePath,
                    responseType:'blob'
                }
                this.loading=true;
                SupplierInfoService.downloadFile(params,()=>{
                    this.loading=false;
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>