import AxiosHelper from '../../util/AxiosHelper';
class AccountService
{
    //获取列表
    static getList(paras,handle)
    {
        AxiosHelper.get("/customer/account/getList",paras,handle);
    }
    //新增
    static add(paras,handle)
    {
        AxiosHelper.post("/customer/account/add",paras,handle);
    }
    //修改
    static update(paras,handle)
    {
        AxiosHelper.post("/customer/account/update",paras,handle);
    }
    //删除
    static delete(paras,handle)
    {
        AxiosHelper.post("/customer/account/delete",paras,handle);
    }
    //重置密码
    static resetPassword(paras,handle)
    {
        AxiosHelper.post("/customer/account/resetPassword",paras,handle);
    }
    //设置状态
    static setStatus(paras,handle)
    {
        AxiosHelper.post("/customer/account/setStatus",paras,handle);
    }
    
}
export default AccountService;