<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">客户:</label>
                <el-select v-model="customerSN" size="small" style="width:200px" filterable placeholder="请选择">
                    <el-option v-for="item in customerList" :key="item.sn" :label="item.name" :value="item.sn">
                    </el-option>
                </el-select>
                <label for="" style="margin-left:1rem">项目名称:</label>
                <el-input v-model.trim="name" placeholder="请输入项目名称" size="small" clearable style="width:150px">
                </el-input>
                <label for="" style="margin-left:1rem">项目经理:</label>
                <el-input v-model.trim="leader" style="width:150px" placeholder="请输入项目名称" size="small" clearable>
                </el-input>
                <el-button style="margin-left:1rem" size="mini" icon="el-icon-search" round @click="search">查询
                </el-button>
                <el-button size="mini" icon="el-icon-plus" round @click="add">新增</el-button>
            </el-header>
            <el-container>
                <el-container>
                    <el-main height="">
                        <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                            <el-table-column type="index" label="序号" width="80px">
                                <template slot-scope="scope">
                                    {{scope.$index+1+(pageIndex-1)*pageSize}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="customerName" label="客户名称">
                            </el-table-column>
                            <el-table-column prop="name" label="项目名称">
                            </el-table-column>
                            <el-table-column prop="leader" label="项目经理">
                            </el-table-column>
                            <el-table-column label="状态" width="120px">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.status==0">
                                        <div
                                            style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                        </div>
                                    </div>
                                    <div v-if="scope.row.status==1">
                                        <div
                                            style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="120px">
                                <template slot-scope="scope">
                                    <el-dropdown>
                                        <el-button size="small">
                                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item><span @click="update(scope.row)">修改</span>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-main>
                    <el-footer height="">
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-container>
            </el-container>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="40%">
            <NewProjectView ref="NewProjectView" :key="dialogWindowKey" @close="newProjectViewClose"
                @submit="newProjectViewSubmit"></NewProjectView>
        </el-dialog>
    </div>
</template>
<script>
    import CustomerInfoService from '../../../service/customer/CustomerInfoService';
    import ProjectInfoService from '../../../service/project/ProjectInfoService';
    import NewProjectView from '../projectInfo/NewView.vue';
    export default {
        data() {
            return {
                customerList: [
                    {
                    "id": 0,
                    "sn": "",
                    "name": "全部"
                }
                ],
                list: [],
                customerSN: '',
                name: '',
                leader: '',
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowTitle: '',
                dialogWindowVisible: false,
                dialogWindowKey: ''

            }
        },
        mounted() {
            //获取客户列表
            this.getCustomerList();
            //获取列表
            this.getList();
        },
        methods: {
            //获取客户列表
            getCustomerList() {
                var params = {
                    pageIndex: 1,
                    pageSize: 1000,
                    status: 1
                }
                CustomerInfoService.getList(params, (response) => {
                     if (response.code == 0) {
                        response.list.forEach((item)=>{
                            this.customerList.push(item);
                        })
                    }
                })
            },
            //获取列表
            getList() {
                var params = {
                    customerSN: this.customerSN,
                    name: this.name,
                    leader: this.leader,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }
                ProjectInfoService.getList(params, (response) => {
                    this.list = response.list;
                    this.recordCount = response.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //查询
            search() {
                this.pageIndex = 1;
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增项目";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("projectInfo", "{}");
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑项目";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("projectInfo", JSON.stringify(obj));
                this.dialogWindowVisible = true;
            },
            //新增项目视图提交
            newProjectViewSubmit(response) {
                this.$message({
                    message: response.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },
            //新增项目视图关闭
            newProjectViewClose() {
                this.dialogWindowVisible = false
            }
        },
        components: {
            NewProjectView
        }

    }
</script>

<style lang="scss" scoped>

</style>