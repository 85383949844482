<template>
    <div>
        <el-container>
            <el-main height="">
                <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                    <el-table-column type="index" label="序号" width="80px">
                        <template slot-scope="scope">
                            {{scope.$index+1}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="channel" label="磁盘号">
                    </el-table-column>
                    <el-table-column prop="totalSpace" label="容量(G)">
                    </el-table-column>
                    <el-table-column prop="freeSpace" label="剩余空间(G)">
                    </el-table-column>
                    <el-table-column label="状态">
                           <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

            </el-main>
            <el-footer>
                <el-row>
                    <el-col :span="24" align="right">
                        <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                            :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                        </el-pagination>
                    </el-col>
                </el-row>
            </el-footer>
        </el-container>

    </div>
</template>

<script>
    import EquipmentInfoService from '../../../service/equipment/EquipmentInfoService';
    export default {
        data() {
            return {
                equipmentInfo: JSON.parse(sessionStorage.getItem("equipmentInfo")),
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0
            }
        },
        mounted() {
            //获取磁盘信息
            this.getList();
        },
        methods: {
            //获取磁盘信息
            getList() {
                var paras = {
                    ip: this.equipmentInfo.ip,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }
                EquipmentInfoService.getDiskInfoList(paras, (response) => {
                    this.list = response.list;
                    this.recordCount = response.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>