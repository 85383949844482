<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form :model="noticeInterface" ref="noticeInterface" :rules="rules" label-width="100px" size="small">
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="通知方式:" prop="noticeWaySN">
                                <el-select v-model.trim="noticeInterface.noticeWaySN" placeholder="请选择">
                                    <el-option v-for="item in noticeWayList" :key="item.sn" :label="item.name"
                                        :value="item.sn">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="名称:" prop="name">
                                <el-input v-model.trim="noticeInterface.name" placeholder="请输入名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="主机地址:" prop="host">
                                <el-input v-model.trim="noticeInterface.host" placeholder="请输入主机地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="协议类型:" prop="protocolTypeSN">
                                <el-select v-model.trim="noticeInterface.protocolTypeSN"
                                    placeholder="请选择">
                                    <el-option v-for="item in protocolList" :key="item.sn" :label="item.name"
                                        :value="item.sn">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="通讯端口:" prop="port">
                                <el-input type="number" v-model.trim="noticeInterface.port" placeholder="请输入名称">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="客户端ID:">
                                <el-input v-model.trim="noticeInterface.clientId" placeholder="请输入客户端ID"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="用户名:">
                                <el-input v-model.trim="noticeInterface.userId" placeholder="请输入名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="密码:">
                                <el-input type="password" v-model.trim="noticeInterface.password" placeholder="请输入名称">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="应用ID:">
                                <el-input v-model.trim="noticeInterface.appId" placeholder="请输入应用ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="密钥:">
                                <el-input v-model.trim="noticeInterface.secret" placeholder="请输入密钥"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="加密方式:">
                                <el-select  v-model.trim="noticeInterface.secretWaySN"
                                    placeholder="请选择">
                                    <el-option v-for="item in secretWayList" :key="item.sn" :label="item.name"
                                        :value="item.sn">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-col :span="12" :offset="0">
                                <el-form-item label="状态:">
                                    <el-switch v-model="noticeInterface.status" :active-value="1" :inactive-value="0"
                                        active-text="" inactive-text="">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                    <template >
                    <el-row v-show="noticeInterface.secretWay=='53zt1630944902285'" >
                        <el-col :span="24" :offset="0">
                            <el-form-item label="公钥:">
                                <el-input type="textarea" placeholder="请输入公钥" v-model="noticeInterface.publicKey"
                                    maxlength="200" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-show="noticeInterface.secretWay=='53zt1630944902285'" >
                        <el-col :span="24" :offset="0">
                            <el-form-item label="私钥:">
                                <el-input type="textarea" placeholder="请输入私钥" v-model="noticeInterface.privateKey"
                                    maxlength="200" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                     </template>
                </el-form>
            </el-main>
            <el-footer height="">
                <el-button type="primary" @click="submit">提交</el-button>
                <el-button @click="close">关闭</el-button>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import NoticeInterfaceService from '../../../service/notice/NoticeInterfaceService';
    export default {
        data() {
            return {
                noticeWayList: [],
                noticeInterface:JSON.parse(sessionStorage.getItem("noticeInterface")),
                protocolList: [],
                secretWayList:[],
                rules: {
                    noticeWaySN: {
                        required: true,
                        message: '请选择通知方式',
                        trigger: 'blur'
                    },
                    name: {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    },
                    host: {
                        required: true,
                        message: '主机地址不能为空',
                        trigger: 'blur'
                    },
                    protocolTypeSN: {
                        required: true,
                        message: '请选择协议类型',
                        trigger: 'blur'
                    },
                    port: {
                        required: true,
                        message: '请输入端口',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted() {
            //获取通知方式
            this.getNoticeWayList();
            //获取协议类型
            this.getProtocolTypeList();
            //获取加密方式
            this.getSecretWayList();
            //设置文本框样式
            this.setTextareaCss();
        },
        methods: {
            //获取通知方式列表
            getNoticeWayList() {
                var params = {
                    "categorySN": "noticeWay",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.noticeWayList = data.list;
                })
            },
            //获取协议类型列表
            getProtocolTypeList() {
                var params = {
                    "categorySN": "protocolType",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.protocolList = data.list;
                })
            },
              //获取加密方式列表
            getSecretWayList() {
                var params = {
                    "categorySN": "secretWay",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.secretWayList = data.list;
                })
            },
            //提交
            submit() {
                this.$refs.noticeInterface.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.noticeInterface.id == null) {
                                this.add();
                            } else {
                                this.update();
                            }
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
              NoticeInterfaceService.add(this.noticeInterface,(response)=>{
                  this.$emit("submit",response);
              })
            },
            //修改
            update() {
                 NoticeInterfaceService.update(this.noticeInterface,(response)=>{
                  this.$emit("submit",response);
              })
            },
            //关闭
            close() {
                this.$emit("close");
            },
            //设置文本框样式
            setTextareaCss() {
                var obj = document.getElementsByTagName("textarea");
                obj.forEach(element => {
                    element.style.height = "100px";
                });
            }
        }
    }
</script>

<style scoped>
</style>