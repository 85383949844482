<template>
    <div>
        <el-container>
            <el-aside width="200px" class="aside">
                <el-menu class="menu" default-active="" @select="chooseGroup">
                    <span class="group">
                        通知组
                    </span>
                    <el-menu-item :key="item.sn" :index="item.sn" v-for="item in groupList">
                        <span slot="title">{{item.name}}</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header height="">
                    <label for="">名称:</label>
                    <el-input size="small" v-model.trim="name" placeholder="请输入名称" class="inputSearch"></el-input>
                    <el-button size="small" round icon="el-icon-search" @click="search">查询</el-button>
                    <el-button size="small" round icon="el-icon-plus" @click="add">新增</el-button>
                </el-header>
                <el-main height="">
                    <el-table size="small" :data="list" border stripe header-cell-class-name="tableHeader">
                        <el-table-column type="index" label="序号" width="60px">
                            <template slot-scope="scope">
                                {{scope.$index+1+(pageIndex-1)*pageSize}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="noticeGroupName" label="通知组">
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" width="80px">
                        </el-table-column>
                        <el-table-column prop="mobile" label="手机号">
                        </el-table-column>
                        <el-table-column prop="email" label="电子邮件">
                        </el-table-column>
                        <el-table-column prop="userId" label="企业微信号" width="120px">
                        </el-table-column>
                        <el-table-column label="操作" width="240px">
                            <template slot-scope="scope">
                                <el-button size="mini" icon="el-icon-document-copy" @click="remove(scope.row)" round>删除
                                </el-button>
                                <el-button size="mini" round icon="el-icon-edit" @click="update(scope.row)">编辑
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-footer height="">
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-main>
            </el-container>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="30%">
            <NewView ref="NewView" :key="dialogWindowKey" @close="closeHandle" @submit="submitHandle"></NewView>
        </el-dialog>
    </div>
</template>
<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import NewView from '../member/NewView.vue';
    import MemberService from '../../../service/notice/MemberService';
    import SiteInfoService from '../../../service/site/SiteInfoService';
    export default {
        data() {
            return {
                name: '',
                siteSN: '',
                groupSN: '',
                groupName: '',
                filterText: '',
                groupList: [],
                siteList: [],
                list: [],
                groupList: [{
                    "id": "",
                    "sn": "",
                    "name": "--全部--"
                }],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false
            }
        },
        mounted() {
            //获取组列表
            this.getGroupList();
            //获取站点列表
            this.getSitelist();
            //获取列表
            this.getList();
        },
        methods: {
            //获取组列表
            getGroupList() {
                var params = {
                    "categorySN": "noticeGroup",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    data.list.forEach(element => {
                        this.groupList.push(element);
                    });

                })
            },
            //获取站点列表
            getSitelist() {
                var params = {
                    pageIndex: 1,
                    pageSize: 1000
                }
                SiteInfoService.getList(params, (response) => {
                    this.siteList = response.list;
                })
            },
            //过滤node
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            //选中组
            chooseGroup(obj) {
                this.groupSN = obj;
                this.getList();
            },
            //获取列表
            getList() {
                var params = {
                    siteSN:this.siteSN,
                    noticeGroupSN: this.groupSN,
                    name: this.name,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }
                MemberService.getList(params, (response) => {
                    this.list = response.list;
                    this.recordCount = response.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增
            add() {
                if (this.groupSN == '') {
                    this.$message({
                        message: "请选择组",
                        type: 'error'
                    })
                    return;
                }
                this.dialogWindowTitle = "新增成员";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
                sessionStorage.setItem("groupSN", this.groupSN);
                sessionStorage.setItem("member", "{}");
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑成员";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("member", JSON.stringify(obj));
                this.dialogWindowVisible = true;
            },
            //删除
            remove(obj) {
                this.$confirm("您确定要删除吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        sn: obj.sn
                    }
                    MemberService.remove(paras, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.dialogWindowVisible = false;
                        this.getList();
                    })
                }).catch(() => {})
            },
            //查询
            search() {
                this.pageIndex = 1;
                this.getList();
            },
            //关闭handle
            closeHandle() {
                this.dialogWindowVisible = false;
            },
            //提交handle
            submitHandle(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            }
        },
        components: {
            NewView
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        }
    }
</script>

<style scoped>
    .aside {
        border-right: solid 1px #F2F6FC;
    }

    .menu {
        padding-right: 1px;
        margin: 0px;
        height: 92vh;
        min-width: 3vw;

    }

    .group {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        background-color: lightblue;
        height: 30px;
        line-height: 30px;

    }
</style>