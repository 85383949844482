<template>
    <div>
        <el-container>
            <el-header height="">
                <el-button @click="add" size="mini" icon="el-icon-plus" round>新增</el-button>
            </el-header>
            <el-main height="">
                <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                    <el-table-column type="index" label="序号" width="80px">
                        <template slot-scope="scope">
                            {{(scope.$index+1)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <el-table-column label="状态" width="120px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==1">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="240px">
                        <template slot-scope="scope">
                            <el-button size="mini" icon="el-icon-edit" @click="update(scope.row)" round>编辑</el-button>
                             <el-button size="mini" icon="el-icon-s-check" @click="right(scope.row)" round>功能授权</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer height="">
                    <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewRoleView ref="NewRoleView" :key="dialogWindowKey" @close="newRoleViewClose" @submit="newRoleViewSubmit">
            </NewRoleView>
        </el-dialog>
        <!--授权视图 -->
         <el-dialog :title="dialogWindowTitle" :visible.sync="rightViewVisible" width="50%">
            <RightView ref="RightView" :key="dialogWindowKey" @close="rightViewClose" @submit="rightViewSubmit">
            </RightView>
        </el-dialog>

    </div>
</template>

<script>
    import RoleService from '../../../service/systemManage/RoleService';
    import NewRoleView from '../../../view/systemManage/role/NewView';
    import RightView from '../../../view/systemManage/role/RightView';
    export default {
        data() {
            return {
                "name": '',
                "list": [],
                "pageIndex": 1,
                "pageSize": 10,
                "recordCount": 0,
                "dialogWindowKey": '', //对话框窗口key
                "dialogWindowTitle": '', //对话框窗口标题
                "dialogWindowVisible": false,
                "rightViewVisible":false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var params = {
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                RoleService.getList(params, (data) => {
                    this.list = data.list;
                     this.recordCount=data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增角色";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "修改角色";
                this.dialogWindowKey = (new Date()).getTime();
                this.$nextTick(() => {
                    this.$refs.NewRoleView.role = Object.assign({}, obj);
                });
                this.dialogWindowVisible = true;
            },
            //授权
            right(obj)
            {
                this.dialogWindowTitle = "功能授权";
                this.dialogWindowKey = (new Date()).getTime();
                localStorage.setItem("roleSN",obj.sn);
                this.rightViewVisible=true;
            },
            //查询
            search() {
                this.getList();
            },
            //新增角色视图关闭
            newRoleViewClose() {
                this.dialogWindowVisible = false;
            },
            //授权视图关闭
            rightViewClose()
            {
               this.rightViewVisible=false;
            },
            //新增角色视图提交
            newRoleViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },
            //授权视图提交
            rightViewSubmit()
            {
                this.rightViewVisible=false;
            }
        },
        components: {
            NewRoleView,
            RightView
        }


    }
</script>

<style lang="scss" scoped>

</style>