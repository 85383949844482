import AxiosHelper from '../../util/AxiosHelper';
class MemberService
{
    //新增
   static add(paras,handle)
   {
       AxiosHelper.post("/notice/member/add",paras,handle);
   }
   //修改
   static update(paras,handle)
   {
       AxiosHelper.post("/notice/member/update",paras,handle);
   }
   //删除
   static remove(paras,handle)
   {
    AxiosHelper.post("/notice/member/remove",paras,handle);
   }
   //获取列表
   static getList(paras,handle)
   {
       AxiosHelper.get("/notice/member/getList",paras,handle);
   }
}
export default MemberService;