/*
 * @Descripttion: 
 * @Author: zhujian
 * @Date: 2020-08-02 18:39:06
 * @LastEditors: zhujian
 * @LastEditTime: 2020-12-19 10:44:12
 */
import axios from 'axios';
import serverConfig from './ServerConfig';
import context from '../main'
import {
    Message
} from 'element-ui';
axios.withCredentials = true;
// axios.defaults.baseURL = "http://" + serverConfig.server + ":" + serverConfig.port;
axios.defaults.baseURL = "http://" + serverConfig.server;
//axios.defaults.timeout = 5000;
//请求拦截器
axios.interceptors.request.use(config => {
    if (context.$route.name != "login") {
        //判断是否存在token
        if (localStorage["access_token"] == "" || localStorage["access_token"] == null) {
            //跳转登陆页
            context.$router.replace("/login");
        } else {
            //判断token是否过期
            if ((new Date(localStorage.getItem("token_expiration"))).getTime() < (new Date()).getTime()) {
                localStorage.removeItem("access_token");
                localStorage.removeItem("token_expiration");
                localStorage.removeItem("departmentSN");
            }
            else
            {
            //将token加到header
            config.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
            }
        }
    } 
    return config;
}, error => {
    return Promise.reject(error);
});
//返回拦截器
axios.interceptors.response.use(response => {
	console.log("response",response)
    var data = response.data;
    if (data.type == "application/octet-stream") {
        return response;
    }
    if (data.code != 0) {
        Message({
            message: data.msg,
            type: 'error'
        })
    } else {
        return response;
    }
}, error => {
    return Promise.reject(error);
});
export default axios;