import AxiosHelper from '../../util/AxiosHelper';
class ProjectInfoService
{
    //新增
   static add(paras,handle)
   {
     AxiosHelper.post("/project/projectInfo/add",paras,handle);
   }
   //修改
   static update(paras,handle)
   {
    AxiosHelper.post("/project/projectInfo/update",paras,handle);
   }
   //删除
   static delete(paras,handle)
   {
    AxiosHelper.post("/project/projectInfo/delete",paras,handle);
   }
   //获取列表
   static getList(paras,handle)
   {
    AxiosHelper.get("/project/projectInfo/getList",paras,handle);
   }
}
export default ProjectInfoService;