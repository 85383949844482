// export default
// {
//     //服务地址
//     "server":"192.168.2.142",
//     //服务端口
//     "port":8000,
//     //文件服务器地址
//     "fileServer":"http://192.168.2.142:8888/"
// }
// var req = new XMLHttpRequest();
// req.open('GET', document.location, false);
// req.send(null);
// var responseHeader=req.getAllResponseHeaders();
// var headerList=responseHeader.split('\r\n');
// var server="";
// for(var i=0;i<headerList.length-1;i++)
// {
//     var item=headerList[i];
//     var map=item.split(':');
//     var key=map[0].trim();
//     var value=map[1].trim();
//    if(key=="server")
//    {
//       server=value.split(',')[1].trim();
//       break;
//    }
// }
export default
{
    //服务地址
    "server":"asm.reachwisdom.com/data/",
    //"server":server,
    //服务端口
    "port":'80',
    //文件服务器地址
     "fileServer":"https://asm.reachwisdom.com/data/"
    //"fileServer":"http://"+server+":8888/"
}