import CustomerView from '../view/customer/CustomerView';
import View_Customer_CustomerInfo from '../view/customer/customerInfo/ListView';

export default
{
    component:CustomerView,
    path:'customer',
    children:[
        {
            component:View_Customer_CustomerInfo,
            path:'customerInfo'
        }
    ]
}