<template>
	<div>
		<el-container>
			<el-header height="">
				<label for="">客户:</label>
				<el-select size="small" filterable v-model="customerSN" placeholder="请选择"
					style="width:150px;margin-right:1em">
					<el-option v-for="item in customerList" :key="item.id" :label="item.name" :value="item.sn">
					</el-option>
				</el-select>
				<label for="">工单类型:</label>
				<el-select size="small" v-model="typeSN" placeholder="请选择" style="width:120px;margin-right:1em">
					<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.sn">
					</el-option>
				</el-select>
				<label for="">设备类型:</label>
				<el-select size="small" filterable="" v-model="equipmentTypeSN" placeholder="请选择"
					style="width:120px;margin-right:1em">
					<el-option v-for="item in equipmentTypeList" :key="item.id" :label="item.name" :value="item.sn">
					</el-option>
				</el-select>
				<label for="">故障类型:</label>
				<el-select size="small" filterable="" v-model="faultTypeSN" placeholder="请选择"
					style="width:120px;margin-right:1em">
					<el-option v-for="item in faultTypeList" :key="item.id" :label="item.name" :value="item.sn">
					</el-option>
				</el-select>
				<label for="">状态:</label>
				<el-select size="mini" v-model="statusSN" placeholder="请选择" style="width:100px;margin-right:1em">
					<el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.sn">
					</el-option>
				</el-select>
				<el-button size="mini" round icon="el-icon-search" @click="search">查询</el-button>
				<el-button size="small" icon="el-icon-plus" round @click="add">新增</el-button>
				<el-button size="small" icon="el-icon-download" round @click="exportToExcel">导出</el-button>
			</el-header>
			<el-main height="">
				<el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
					<el-table-column type="index" label="序号" width="60px">
						<template slot-scope="scope">
							{{scope.$index+1+(pageIndex-1)*pageSize}}
						</template>
					</el-table-column>
					<el-table-column prop="customerName" label="客户">
					</el-table-column>
					<el-table-column prop="typeName" label="工单类型" width="100px">
					</el-table-column>
					<el-table-column prop="equipmentTypeName" label="设备类型">
					</el-table-column>
					<el-table-column prop="faultTypeName" label="故障类型" width="120px">
					</el-table-column>
					<el-table-column label="保修状态" width="120px">
						<template slot-scope="scope">
							<span v-if="scope.row.repairStatusSN=='jxc91647480335063'"
								style="color:green">{{scope.row.repairStatusName}}</span>
							<span v-else style="color:orange">{{scope.row.repairStatusName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="userName" label="处理人" width="80px">
					</el-table-column>
					<el-table-column prop="createTime" label="下单时间" width="160px">
					</el-table-column>
					<el-table-column label="状态" width="80px">
						<template slot-scope="scope">
							<!-- 已下单 -->
							<span style="color:blue"
								v-if="scope.row.statusSN=='kV931646200624539'">{{scope.row.statusName}}</span>
							<!-- 已下发 -->
							<span style="color:red"
								v-if="scope.row.statusSN=='5gJl1646297348281'">{{scope.row.statusName}}</span>
							<!-- 已接单 -->
							<span style="color:purple"
								v-if="scope.row.statusSN=='bVvq1646200665663'">{{scope.row.statusName}}</span>
							<!-- 已完成 -->
							<span style="color:burlywood"
								v-if="scope.row.statusSN=='KByl1646200696391'">{{scope.row.statusName}}</span>
							<!-- 已验收-->
							<span style="color:green"
								v-if="scope.row.statusSN=='Bgjm1646380441503'">{{scope.row.statusName}}</span>

						</template>
					</el-table-column>
					<el-table-column label="操作" width="120px">
						<template slot-scope="scope">
							<el-dropdown>
								<el-button size="small">
									操作<i class="el-icon-arrow-down el-icon--right"></i>
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item><span @click="detail(scope.row)">详情</span></el-dropdown-item>
									<el-dropdown-item
										divided=true><span @click="update(scope.row)">修改</span></el-dropdown-item>
									<el-dropdown-item v-if="scope.row.statusSN=='kV931646200624539'" divided=true><span
											@click="remove(scope.row)">删除</span></el-dropdown-item>
									<el-dropdown-item v-if="scope.row.statusSN=='kV931646200624539'" divided=true><span
											@click="pushOrder(scope.row)">下发工单</span></el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<el-footer height="">
					<el-row>
						<el-col :span="24" align="right">
							<el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
								:total="recordCount" :page-size="pageSize"  :current-page="pageIndex" @current-change="pageChanged">
							</el-pagination>
						</el-col>
					</el-row>
				</el-footer>
			</el-main>
		</el-container>
		<el-dialog :title="newViewTitle" :visible.sync="newViewVisible" width="50%">
			<NewView ref="NewView" :key="newViewKey" @close="newViewClose" @submit="newViewSubmit">
			</NewView>
		</el-dialog>
		<el-dialog :title="pushOrderViewTitle" :visible.sync="pushOrderViewVisible" width="30%">
			<PushOrderView ref="PushOrderView" :key="pushOrderViewKey" @close="pushOrderViewClose"
				@submit="pushOrderViewSubmit">
			</PushOrderView>
		</el-dialog>
	</div>
</template>

<script>
	import DictionaryService from '../../service/systemManage/DictionaryService';
	import CustomerInfoService from '../../service/customer/CustomerInfoService';
	import WorkOrderService from '../../service/WorkOrder/WorkOrderService';
	import NewView from './NewView.vue';
	import PushOrderView from './PushOrderView.vue';
	export default {
		data() {
			return {
				customerSN: '',
				"customerList": [{
					"id": 0,
					"sn": "",
					"name": "全部"
				}],
				"typeSN": '',
				"typeList": [{
					"id": 0,
					"sn": "",
					"name": "全部"
				}],
				"equipmentTypeSN": '',
				"equipmentTypeList": [{
					"id": 0,
					"sn": "",
					"name": "全部"
				}],
				"faultTypeSN": '',
				"faultTypeList": [{
					"id": 0,
					"sn": "",
					"name": "全部"
				}],
				"statusSN": '',
				"statusList": [{
					"id": 0,
					"sn": "",
					"name": "全部"
				}],
				"list": [],
				"pageIndex": 1,
				"pageSize": 10,
				"recordCount": 0,
				newViewTitle: '',
				newViewKey: '',
				newViewVisible: false,
				pushOrderViewTitle: '',
				pushOrderViewKey: '',
				pushOrderViewVisible: false,
			}
		},
		mounted() {
			//获取客户列表
			this.getCustomerInfoList();
			//获取工单类型
			this.getWorkOrderTypeList();
			//获取设备类型
			this.getEquipmentTypeList();
			//获取故障类型
			this.getFaultTypeList();
			//获取状态列表
			this.getStatusList();
			//获取列表
			this.getList();
		},
		methods: {
			//获取客户列表
			getCustomerInfoList() {
				var params = {
					"pageIndex": 1,
					"pageSize": 1000,
					"status": 1
				}
				CustomerInfoService.getList(params, (response) => {
					if (response.code == 0) {
						response.list.forEach((item) => {
							this.customerList.push(item);
						})
					}
				})
			},
			//获取工单类型列表
			getWorkOrderTypeList() {
				var paras = {
					pageIndex: 1,
					pageSize: 1000,
					categorySN: "workOrderType",
					"status": 1
				}
				DictionaryService.getItemList(paras, (response) => {
					if (response.code == 0) {
						response.list.forEach((item) => {
							this.typeList.push(item);
						})
					}
				})
			},
			//获取设备类型列表
			getEquipmentTypeList() {
				var params = {
					"pageIndex": 1,
					"pageSize": 1000,
					"categorySN": "equipmentType",
					"status": 1
				}
				DictionaryService.getItemList(params, (response) => {
					if (response.code == 0) {
						response.list.forEach((item) => {
							this.equipmentTypeList.push(item);
						})
					}
				})
			},
			//获取故障类型列表
			getFaultTypeList() {
				var params = {
					"pageIndex": 1,
					"pageSize": 1000,
					"categorySN": "faultType",
					"status": 1
				}
				DictionaryService.getItemList(params, (response) => {
					if (response.code == 0) {
						response.list.forEach((item) => {
							this.faultTypeList.push(item);
						})
					}
				})
			},

			//获取状态列表
			getStatusList() {
				var params = {
					"pageIndex": 1,
					"pageSize": 1000,
					"categorySN": "workOrderStatus",
					"status": 1
				};
				DictionaryService.getItemList(params, (data) => {
					data.list.forEach(element => {
						this.statusList.push(element);
					});

				})
			},
			//获取工单类型列表
			getTaskTypeList() {
				var params = {
					"pageIndex": 1,
					"pageSize": 1000,
					"categorySN": "taskType",
					"status": 1
				};
				DictionaryService.getItemList(params, (data) => {
					data.list.forEach(element => {
						this.typeList.push(element);
					});
				})
			},
			//获取列表
			getList() {
				var params = {
					"pageIndex": this.pageIndex,
					"pageSize": this.pageSize,
					customerSN: this.customerSN,
					typeSN: this.typeSN,
					equipmentTypeSN: this.equipmentTypeSN,
					faultTypeSN: this.faultTypeSN,
					statusSN: this.statusSN,
					"statusSN": this.statusSN
				}
				WorkOrderService.getList(params, (data) => {
					this.list = data.list;
					this.recordCount = data.recordCount;
				})
			},
			//页码改变
			pageChanged(pageIndex) {
				this.pageIndex = pageIndex;
				this.getList();
			},
			//新增
			add() {
				this.newViewTitle = "新增工单";
				this.newViewKey = (new Date()).getTime();
				localStorage.setItem("action", "add");
				localStorage.setItem("workOrderInfo", JSON.stringify({}));
				this.newViewVisible = true;
			},
			//修改
			update(obj) {
				this.newViewTitle = "修改工单";
				this.newViewKey = (new Date()).getTime();
				localStorage.setItem("action", "update");
				localStorage.setItem("workOrderInfo", JSON.stringify(obj));
				this.newViewVisible = true;
			},
			//删除
			remove(obj) {
				this.$confirm("您确定要删除此工单吗?", "确定", {
					confirmButtonText: "确定",
					cancelButtonText: "取消"
				}).then(() => {
					var paras = {
						sn: obj.sn
					}
					WorkOrderService.delete(paras, (response) => {
						this.$message({
							showClose: true,
							message: response.msg,
							type: 'success'
						});
						this.getList();
					})
				}).catch(() => {})
			},
			//详情
			detail(obj) {
				localStorage.setItem("workOrderInfo", JSON.stringify(obj));
				localStorage.setItem("action", "detail");
				this.newViewTitle = "工单详情";
				this.newViewKey = (new Date()).getTime();
				this.newViewVisible = true;
			},
			//下发工单
			pushOrder(obj) {
				this.pushOrderViewTitle = "下发工单";
				this.pushOrderViewKey = (new Date()).getTime();
				sessionStorage.setItem("workOrderSN", obj.sn);
				this.pushOrderViewVisible = true;
			},
			pushOrderViewClose() {
				this.pushOrderViewVisible = false;
			},
			pushOrderViewSubmit(data) {
				this.$message({
					message: data.msg,
					type: 'success'
				})
				this.pushOrderViewVisible = false;
				this.getList();
			},
			//接单
			receive(obj) {
				this.$confirm("您确定要接单吗?", "确定", {
					confirmButtonText: "确定",
					cancelButtonText: "取消"
				}).then(() => {
					TaskOrderService.receive(obj, (data) => {
						this.$message({
							message: data.msg,
							type: 'success'
						})
						this.getList();
					})
				}).catch(() => {})
			},
			//工单完成
			finish(obj) {
				this.taskFinishViewVisible = true;
				this.$nextTick(() => {
					this.$refs.TaskFinishView.taskOrderInfo = Object.assign({}, obj);
				});
			},
			//详情页关闭
			detailViewClose() {
				this.detailViewVisible = false;
			},
			//查询
			search() {
				this.pageIndex = 1
				this.getList();
			},
			// 导出
			exportToExcel() {
				this.$confirm("您确定要导出吗?", "确定", {
					confirmButtonText: "确定",
					cancelButtonText: "取消"
				}).then(() => {
					var params = {
						customerSN: this.customerSN,
						typeSN: this.typeSN,
						equipmentTypeSN: this.equipmentTypeSN,
						faultTypeSN: this.faultTypeSN,
						statusSN: this.statusSN,
					}
					WorkOrderService.exportToExcel(params, null);
				}).catch(() => {})
			},
			//新增窗口提交
			newViewSubmit(data) {
				this.$message({
					message: data.msg,
					type: 'success'
				})
				this.newViewVisible = false;
				this.getList();
			},
			//新增窗口关闭
			newViewClose() {
				this.newViewVisible = false;
			}
		},
		components: {
			NewView,
			PushOrderView
		}


	}
</script>

<style scoped>
	.test {
		color: burlywood;
	}
</style>