<template>
    <div>
        <el-container :direction="vertical">
            <el-container :direction="vertical">
                <el-main height="">
                    <el-form :model="expressInfo" ref="expressInfo" :rules="rules" label-width="80px" :inline="false"
                        size="normal">
                        <el-form-item label="快递公司" prop="expressCompanySN">
                            <el-select v-model="expressInfo.expressCompanySN" size="small" filterable placeholder="请选择">
                                <el-option v-for="item in expressCompanyList" :key="item.sn" :label="item.name"
                                    :value="item.sn">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="快递单号" prop="expressSN">
                            <el-input v-model.trim="expressInfo.expressSN" placeholder="请输入快递单号" size="small" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="供应商" prop="supplierSN">
                            <el-select v-model="expressInfo.supplierSN" size="small" filterable placeholder="请选择" @change="supplierChanged">
                                <el-option v-for="item in supplierList" :key="item.sn" :label="item.name"
                                    :value="item.sn">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收货地址" prop="address">
                            <el-input v-model.trim="expressInfo.address" placeholder="请输入收获地址" size="small" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="收货人" prop="contacts">
                            <el-input v-model.trim="expressInfo.contacts" placeholder="请输入收货人" size="small" clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="tel">
                            <el-input v-model.trim="expressInfo.tel" placeholder="请输入联系电话" size="small" clearable></el-input>
                        </el-form-item>
                    </el-form>

                </el-main>
                <el-footer height="">
                    <el-button size="mini" type="primary" @click="submit">提交</el-button>
                    <el-button size="mini" @click="close">关闭</el-button>
                </el-footer>
            </el-container>
        </el-container>
        </el-container>

    </div>
</template>

<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import SupplierInfoService from '../../../service/supplier/SupplierInfoService';
import SupplierRepairService from '../../../service/repair/SupplierRepairService';
    export default {
        data() {
            return {
                repairSN:sessionStorage.getItem("repairSN"),
                expressInfo: {
                   contacts:'',
                   address:'',
                   tel:''
                },
                supplierList: [],
                expressCompanyList: [],
                rules: {
                    expressCompanySN: {
                        required: true,
                        message: '请选择快递公司',
                        trigger: 'blur'
                    },
                    expressSN: {
                        required: true,
                        message: '请输入快递单号',
                        trigger: 'blur'
                    },
                    supplierSN: {
                        required: true,
                        message: '请选择供应商',
                        trigger: 'blur'
                    },
                    address: {
                        required: true,
                        message: '请输入收货地址',
                        trigger: 'blur'
                    },
                    contacts: {
                        required: true,
                        message: '请输入联系人',
                        trigger: 'blur'
                    },
                    tel: {
                        required: true,
                        message: '请输入联系电话',
                        trigger: 'blur'
                    }
                }
            }
        },
        mounted() {
            //获取快递公司列表
            this.getExpressCompanyList();
            //获取供应商列表
            this.getSupplierList();
        },
        methods: {
            //获取快递公司列表
            getExpressCompanyList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "expressCompany",
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    if (response.code == 0) {
                        response.list.forEach((item) => {
                            this.expressCompanyList.push(item);
                        })
                    }
                })
            },
            //获取供应商列表
            getSupplierList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                };
                SupplierInfoService.getList(params,(response)=>{
                    this.supplierList=response.list;
                });
            },
            //供应商改变
            supplierChanged(value)
            {
              //获取供应商详情
              this.getSupplierDetail(value);
            },
            //获取供应商详情
            getSupplierDetail(sn)
            {
               var index=0;
               for(var i=0;i<this.supplierList.length;i++)
               {
                   if(this.supplierList[i].sn==sn)
                   {
                       index=i;
                       break;
                   }
               }
               var supplier=this.supplierList[index];
               this.expressInfo.contacts=JSON.parse(JSON.stringify(supplier)).contacts;
               this.expressInfo.address=JSON.parse(JSON.stringify(supplier)).address;
               this.expressInfo.tel=JSON.parse(JSON.stringify(supplier)).tel;
            },
            //提交
            submit() {
                this.$refs.expressInfo.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            this.expressInfo.repairSN=this.repairSN;
                          SupplierRepairService.addExpress(this.expressInfo,(response)=>{
                               this.$emit("submit", response);
                          })
                        }).catch(() => {})

                    }
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>