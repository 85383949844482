<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form :model="mapInfo" ref="mapInfo" :rules="rules" label-width="100px" size="small">
                    <el-form-item label="类型:" prop="mapTypeSN">
                        <el-select v-model="mapInfo.mapTypeSN" placeholder="请选择">
                            <el-option v-for="item in mapTypeList" :key="item.id" :label="item.name" :value="item.sn">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="名称:" prop="name">
                        <el-input v-model.trim="mapInfo.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="宽度:" prop="width">
                        <el-input type="number" v-model.trim="mapInfo.width" placeholder="请输入宽度">
                            <template slot="append">像素</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="高度:" prop="height">
                        <el-input type="number" v-model.trim="mapInfo.height" placeholder="请输入高度">
                            <template slot="append">像素</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submit">提交</el-button>
                        <el-button @click="close">关闭</el-button>
                    </el-form-item>
                </el-form>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import MapInfoService from '../../../service/mapManage/MapInfoService';
    export default {
        data() {
            return {
                "mapTypeList": [],
                "regionList": [],
                "mapInfo": {
                },
                rules: {
                    mapTypeSN: {
                        required: true,
                        message: '地图类型不能为空',
                        trigger: 'blur'
                    },
                    name: {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    },
                    width: {
                        required: true,
                        message: '宽度不能为空',
                        trigger: 'blur'
                    },
                    height: {
                        required: true,
                        message: '高度不能为空',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted() {
            //获取地图类型列表
            this.getMapTypeList();
            //获取区域列表
            this.getRegionList();
        },
        methods: {
            //获取地图类型列表
            getMapTypeList() {
                var params = {
                    "categorySN": "mapType",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    data.list.forEach(element => {
                        this.mapTypeList.push(element);
                    });
                })
            },
            //提交
            submit() {
                this.$refs.mapInfo.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.mapInfo.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                MapInfoService.add(this.mapInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                MapInfoService.update(this.mapInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>