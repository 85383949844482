<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form :model="equipmentInfo" ref="equipmentInfo" label-width="100px" size="small">
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="类型:">
                                {{equipmentInfo.equipmentTypeName}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="供应商:">
                                {{equipmentInfo.supplierName}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="名称:" prop="name">
                                {{equipmentInfo.name}}
                            </el-form-item>

                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="规格/型号:">
                                {{equipmentInfo.style}}
                            </el-form-item>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="购买日期:">
                                {{equipmentInfo.buyDate}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="过保日期:">
                                {{equipmentInfo.warranty}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="MAC地址:">
                                {{equipmentInfo.mac}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="访问地址:">
                                {{equipmentInfo.url}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="IP地址:">
                                {{equipmentInfo.ip}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="端口:">
                                {{equipmentInfo.port}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="用户名:">
                                {{equipmentInfo.userName}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="密码:">
                                {{equipmentInfo.password}}
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="备注:">
                                {{equipmentInfo.remark}}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="是否在用:">
                                 <span v-show="equipmentInfo.status=='1'">是</span>
                                 <span v-show="equipmentInfo.status=='0'">否</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item>
                        <el-button @click="close">关闭</el-button>
                    </el-form-item>
                </el-form>
            </el-main>
        </el-container>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                equipmentInfo: {}
            }
        },
        mounted() {

        },
        methods: {
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style scope>
</style>