<template>
    <div>
        <el-container>
            <el-container>
                <el-main>
                    <el-form :model="noticeTemplate" ref="noticeTemplate" :rules="rules" label-width="80px"
                        :inline="false" size="normal">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model.trim="noticeTemplate.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                        <el-form-item label="内容" prop="content">
                            <el-input type="textarea" :rows="4" placeholder="请输入内容"
                                v-model.trim="noticeTemplate.content">
                            </el-input>
                            <el-collapse>
                                <el-collapse-item title="参数说明">
                                    <el-row class="description">
                                        <el-col :span="12" :offset="0">
                                            客户名称:customerName
                                        </el-col>
                                        <el-col :span="12" :offset="0">
                                            地址:address
                                        </el-col>
                                    </el-row>
                                    <el-row class="description">
                                        <el-col :span="12" :offset="0">
                                            联系人:contacts
                                        </el-col>
                                        <el-col :span="12" :offset="0">
                                            手机号码:mobile
                                        </el-col>
                                    </el-row>
                                    <el-row class="description">
                                        <el-col :span="12" :offset="0">
                                            工单类型:typeName
                                        </el-col>
                                        <el-col :span="12" :offset="0">
                                            设备类型:equipmentTypeName
                                        </el-col>

                                    </el-row>
                                    <el-row class="description">
                                        <el-col :span="12" :offset="0">
                                            故障类型:faultTypeName
                                        </el-col>
                                        <el-col :span="12" :offset="0">
                                            故障描述:description
                                        </el-col>

                                    </el-row>
                                     <el-row class="description">
                                        <el-col :span="12" :offset="0">
                                            保修状态:repairStatusName
                                        </el-col>
                                        <el-col :span="12" :offset="0">
                                            紧急程度:levelName
                                        </el-col>

                                    </el-row>
                                    <el-row class="description">
                                        <el-col :span="12" :offset="0">
                                            备注:remark
                                        </el-col>
                                        <el-col :span="12" :offset="0">
                                            下单时间:creteTime
                                        </el-col>
                                    </el-row>

                                </el-collapse-item>
                            </el-collapse>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-switch v-model="noticeTemplate.status" :active-value="1" :inactive-value="0"
                                active-text="启用" inactive-text="禁用">
                            </el-switch>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submit">提交</el-button>
                            <el-button @click="close">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import NoticeTemplateService from '../../../service/notice/NoticeTemplateService';
    export default {
        data() {
            return {
                noticeTemplate: JSON.parse(sessionStorage.getItem("noticeTemplate")),
                rules: {
                    name: {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    },
                    content: {
                        required: true,
                        message: '内容不能为空',
                        trigger: 'blur'
                    },
                }
            }
        },
        mounted() {

        },
        methods: {
            //提交
            submit() {
                this.$refs.noticeTemplate.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.noticeTemplate.id == null) {
                                this.add();
                            } else {
                                this.update();
                            }
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                NoticeTemplateService.add(this.noticeTemplate, (response) => {
                    this.$emit("submit", response);
                })
            },
            //修改
            update() {
                NoticeTemplateService.update(this.noticeTemplate, (response) => {
                    this.$emit("submit", response);
                })
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style scoped>
    .description {
        color: grey;
    }
</style>