<template>
    <div>
        <el-container>
            <el-aside width="200px" class="aside">
                <el-menu class="menu" default-active="" @select="chooseCategory">
                    <span class="category">
                        字典分类
                    </span>
                    <el-menu-item :key="item.sn" :index="item.sn" v-for="item in categoryList">
                        <span slot="title">{{item.name}}</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header height="">
                    <label for="">名称:</label>
                    <el-input size="mini" v-model.trim="name" placeholder="请输入名称" class="inputSearch"></el-input>
                    <el-button size="mini" round icon="el-icon-search" @click="search">查询</el-button>
                    <el-button size="mini" round icon="el-icon-plus" @click="add">新增</el-button>
                </el-header>
                <el-main height="">
                    <el-table size="mini" :data="list" border  header-cell-class-name="tableHeader">
                        <el-table-column type="index" label="序号" width="80px">
                            <template slot-scope="scope">
                                {{scope.$index+1+(pageIndex-1)*pageSize}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                         <el-table-column prop="flag" label="标识">
                        </el-table-column>
                        <el-table-column prop="seq" label="排序号" width="100px">
                        </el-table-column>
                        <el-table-column label="状态" width="80px">
                            <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                              <div style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;"></div>
                            </div>
                            <div v-if="scope.row.status==1">
                              <div style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;"></div>
                            </div>
                            <!-- <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" disabled /> -->
                        </template>
                        </el-table-column>
                        <el-table-column label="操作" width="200px">
                            <template slot-scope="scope">
                                <el-button size="mini" round icon="el-icon-edit" @click="update(scope.row)">编辑
                                </el-button>
                                <el-button size="mini" icon="el-icon-document-copy" @click="remove(scope.row)" round>删除
                                </el-button>
                                <!-- <el-button size="mini" icon="el-icon-files" @click="showIconView(scope.row)" round>图标
                                </el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-footer height="">
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-main>
            </el-container>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewItemView ref="NewItemView" :key="dialogWindowKey" @close="close" @submit="submit"></NewItemView>
        </el-dialog>
        <!-- 图标视图 -->
        <el-dialog :title="dialogWindowTitle" :visible.sync="iconViewVisible" width="50%">
            <IconView ref="IconView" :key="dialogWindowKey" @close="iconViewClose">
            </IconView>
        </el-dialog>
    </div>
</template>
<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import NewItemView from '../../../view/systemManage/dictionary/NewItemView'
    import IconView from './IconView.vue';
    export default {
        data() {
            return {
                name: '',
                categorySN: '',
                categoryName: '',
                filterText: '',
                list: [],
                categoryList: [{
                    "id": "",
                    "sn": "",
                    "name": "--全部--"
                }],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false,
                iconViewVisible: false
            }
        },
        mounted() {
            //获取分类列表
            this.getCategoryList();
            //获取列表
            this.getList();
        },
        methods: {
            //获取分类列表
            getCategoryList() {
                var params = {
                    "name": this.name,
                    "status": 1,
                    "pageIndex": 1,
                    "pageSize": 100
                }
                DictionaryService.getCategoryList(params, (data) => {
                    data.list.forEach(element => {
                        this.categoryList.push(element);
                    });
                })
            },
            //过滤node
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            //选中分类
            chooseCategory(obj) {
                this.categorySN = obj;
                this.pageIndex=1;
                this.getList();
            },
            //获取列表
            getList() {
                //获取项列表
                var params = {
                    "categorySN": this.categorySN,
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                DictionaryService.getItemList(params, (data) => {
                    this.list = data.list;
                    this.recordCount = data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增
            add() {
                if (this.categorySN == '') {
                    this.$message({
                        message: "请选择字典分类",
                        type: 'error'
                    })
                    return;
                }
                this.dialogWindowTitle = "新增项";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
                sessionStorage.setItem("categorySN", this.categorySN);
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑项";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
                this.$nextTick(() => {
                    this.$refs.NewItemView.item = Object.assign({}, obj);
                });
            },
            //删除
            remove(obj) {
                this.$confirm("您确定要删除此信息吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        sn: obj.sn
                    }
                    DictionaryService.removeItem(paras, (response) => {
                        this.$message({
                            showClose: true,
                            message: response.msg,
                            type: 'success'
                        });
                        this.getList();
                    });
                }).catch(() => {})
            },
            //显示图标视图
            showIconView(obj) {
                this.dialogWindowTitle = "图标管理";
                sessionStorage.setItem("sn", obj.sn);
                this.dialogWindowKey = (new Date()).getTime();
                this.iconViewVisible = true;
            },
            //图标视图关闭
            iconViewClose() {
                this.iconViewVisible = false;
            },
            //查询
            search() {
                this.pageIndex = 1;
                this.getList();
            },
            close() {
                this.dialogWindowVisible = false;
            },
            submit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            }
        },
        components: {
            NewItemView,
            IconView
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        }
    }
</script>

<style scoped>
    /* .aside {
        border-right: solid 1px #F2F6FC;
    } */

    .menu {
        margin: 0px;
        height: 84vh;
        min-width: 3vw;
        border-radius: 1vh;
    }

    .category {
        border-top-left-radius: 1vh;
          border-top-right-radius: 1vh;
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #0099CC;
        height: 40px;
        color: white;
        line-height: 40px;

    }
</style>