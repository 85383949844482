<!--
 * @Descripttion: 
 * @Author: zhujian
 * @Date: 2020-08-29 09:33:06
 * @LastEditors: zhujian
 * @LastEditTime: 2020-09-01 16:20:20
-->
<template>
    <div>
        <el-container class="container">
            <el-main class="main">
                <el-row>
                    <el-col :span="12" :offset="0" class="chartCol" id="equipmentStatusCountChart">

                    </el-col>
                    <el-col :span="12" :offset="0" class="chartCol" id="categoryEquipmentCountChart">

                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" :offset="0" class="chartCol" id="todayAlarmCountChart">

                    </el-col>
                    <el-col :span="12" :offset="0" class="chartCol">
                        <div style="width:70%;height:85%;" id="monthAlarmTotalChart">

                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import EquipmentInfoService from '../../service/equipment/EquipmentInfoService';
    import AlarmInfoService from '../../service/alarm/AlarmInfoService';
    export default {
        name: '',
        data() {
            return {}
        },
        mounted() {
            //获取设备数量统计
            this.getEquipmentCountStatistics();
             //获取分类设备数量统计
             this.getCatgoryEquipmentCountStatistics();
            //获取当日告警统计
            this.getTodayCategoryCountStatistics();
            // //获取月告警统计
             this.getMonthCategoryCountStatistics();
            //加载月告警统计曲线
            // this.loadMonthAlarmTotalChart();

        },
        methods: {
            //获取设备数量统计
            getEquipmentCountStatistics() {
                EquipmentInfoService.getCountStatistics(null, (response) => {
                    //加载设备数量图表
                    this.loadEquipmenCountChart(response.obj);
                })
            },
            //获取分类设备数量统计
            getCatgoryEquipmentCountStatistics() {
                EquipmentInfoService.getCategoryCountStatistics(null, (response) => {
                    //加载分类设备数量图表
                    this.loadCategoryEquipmentCountChart(response.list);
                })
            },
            //加载设备数量图表
            loadEquipmenCountChart(obj) {
                var myChart = this.$echarts.init(document.getElementById('equipmentStatusCountChart'));
                var option;
                option = {
                    title: {
                        text: '设备数量',
                        left: 'center',
                        top: 20
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: ['设备总数', '在线设备数', '离线设备数']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: [obj.totalCount, obj.totalCount_Online, obj.totalCount_Offline],
                        type: 'bar',
                        barWidth: 15,
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius: false,
                                color: function (params) {
                                    var colorList = ['rgb(42,170,227)', '#99CC99',
                                        'red'
                                    ];
                                    return colorList[params.dataIndex];
                                }
                            }
                        }
                    }]
                };

                myChart.setOption(option);
            },
            //设备分类设备数
            loadCategoryEquipmentCountChart(obj) {
                var data = new Array();
                obj.forEach(element => {
                    data.push({
                        name: element.categoryName,
                        value: element.count
                    });
                });
                var myChart = this.$echarts.init(document.getElementById('categoryEquipmentCountChart'));
                var option;
                option = {
                    title: {
                        text: '设备分类数量',
                        left: 'center',
                        top: 20
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '0px'
                    },
                    series: [{
                        name: '设备类型',
                        type: 'pie',
                        radius: '60%',
                        data: data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        label: {
                            formatter: function (param) {
                                return param.data.value;
                            }
                        }
                    }]
                };
                myChart.setOption(option);
            },
            //获取当日分类数量统计
            getTodayCategoryCountStatistics() {
                AlarmInfoService.getTodayCategoryCountStatistics(null, (response) => {
                    this.loadTodayAlarmCountChart(response.list);
                })
            },
            //加载当日报警数量
            loadTodayAlarmCountChart(obj) {
                var xAxisData = new Array();
                var seriesData = new Array();
                obj.forEach(element => {
                    xAxisData.push(element.typeName);
                    seriesData.push(element.count);
                });
                var myChart = this.$echarts.init(document.getElementById('todayAlarmCountChart'));
                var option;
                option = {
                    title: {
                        text: '今日故障',
                        left: 'center',
                        top: 20
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: seriesData,
                        type: 'bar',
                        barWidth: 15,
                        barCategoryGap: '80%',
                        showBackground: false,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        itemStyle: {
                            normal: {
                                barBorderRadius: false,
                                color: '#FF9966',
                            }
                        }
                    }]
                };
                myChart.setOption(option);
            },
            //获取当日分类数量统计
            getMonthCategoryCountStatistics() {
                AlarmInfoService.getMonthCategoryCountStatistics(null, (response) => {
                    this.loadMonthAlarmTotalChart(response.list);
                })
            },
            //加载一个月内故障统计
            loadMonthAlarmTotalChart(obj) {
                var xAxisData = new Array();
                var seriesData=new Array();
                obj.forEach(element => {
                    xAxisData.push(element.createDate);
                     seriesData.push(element.count);
                });
                var myChart = this.$echarts.init(document.getElementById('monthAlarmTotalChart'));
                var option;
                option = {
                      title: {
                        text: '一月内故障统计',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data:xAxisData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: seriesData,
                        type: 'line',
                        smooth: true
                    }]
                };
                myChart.setOption(option);
            }
        }
    }
</script>

<style scoped>
    .container {
        height: 91vh;
        margin-top: -2.5vh;
    }

    .main {
        background-image: url("../../assets/image/window_default.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow: hidden;
    }

    .chartCol {
        height: 45.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
    }
</style>