<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">名称:</label>
                <el-input size="small" v-model.trim="name" placeholder="请输入名称" class="inputSearch"></el-input>
                <el-button size="small" icon="el-icon-search" round @click="search">查询</el-button>
                <el-button size="small" icon="el-icon-plus" round @click="add">新增</el-button>
            </el-header>
            <el-main height="">
                <el-table size="small" :data="list" border stripe header-cell-class-name="tableHeader">
                    <el-table-column type="index" label="序号" width="80px">
                        <template slot-scope="scope">
                            {{scope.$index+1+(pageIndex-1)*pageSize}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <el-table-column prop="address" label="地址">
                    </el-table-column>
                    <el-table-column prop="contacts" label="联系人">
                    </el-table-column>
                    <el-table-column prop="mobile" label="手机号">
                    </el-table-column>
                    <el-table-column label="状态" width="120px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120px">
                        <template slot-scope="scope">
                            <el-dropdown>
                                <el-button size="small">
                                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item><span @click="update(scope.row)">修改</span></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer height="">
                    <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewCustomerView ref="NewCustomerView" :key="dialogWindowKey" @close="newCustomerViewClose"
                @submit="newCustomerViewSubmit"></NewCustomerView>
        </el-dialog>
        <!--账号管理视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="accountViewVisible" width="60%">
            <AccountView ref="accountView" :key="dialogWindowKey" @close="accountViewClose" @submit="accountViewSubmit">
            </AccountView>
        </el-dialog>
    </div>
</template>
<script>
    import CustomerInfoService from '../../../service/customer/CustomerInfoService';
    //新增客户视图
    import NewCustomerView from '../../../view/customer/customerInfo/NewView.vue';
    //账号管理视图
    import AccountView from './AccountView.vue';
    export default {
        data() {
            return {
                name: '',
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false,
                accountViewVisible: false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var params = {
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                CustomerInfoService.getList(params, (data) => {
                    this.list = data.list;
                    this.recordCount = data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增客户";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("customerInfo", '{"status":1}');
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑客户";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("customerInfo", JSON.stringify(obj));
                this.dialogWindowVisible = true;
            },
            //查询
            search() {
                this.getList();
            },
            //新增客户视图关闭
            newCustomerViewClose() {
                this.dialogWindowVisible = false;
            },
            //新增供应商视图提交
            newCustomerViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },
            //账号管理
            accountManage(obj) {
                this.dialogWindowTitle = "账号管理";
                this.dialogWindowKey = (new Date()).getTime();
                localStorage.setItem("customerInfo", JSON.stringify(obj));
                this.accountViewVisible = true;
            },
            //账号管理提交
            accountViewSubmit(data) {

            },
            //账号管理关闭并
            accountViewClose() {
                this.accountViewVisible = false;
            }
        },
        components: {
            NewCustomerView,
            AccountView
        }


    }
</script>

<style lang="scss" scoped>

</style>