<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">客户:</label>
                <el-select v-model="customerSN" size="small" style="width:168px" filterable placeholder="请选择"
                    @change="getProjectList()">
                    <el-option v-for="item in customerList" :key="item.sn" :label="item.name" :value="item.sn">
                    </el-option>
                </el-select>
                <label for="" style="margin-left:1rem">项目:</label>
                <el-select v-model="projectSN" size="small" style="width:180px" filterable placeholder="请选择">
                    <el-option v-for="item in projectList" :key="item.sn" :label="item.name" :value="item.sn">
                    </el-option>
                </el-select>
                <label for="" style="margin-left:1rem">设备名称:</label>
                <el-input v-model="equipmentName" placeholder="请输入设备名称" size="small" clearable style="width:120px">
                </el-input>
                <label for="" style="margin-left:1rem">报修人:</label>
                <el-input v-model="requester" placeholder="请输入报修人" size="small" clearable style="width:150px">
                </el-input>
                <label for="" style="margin-left:1rem">状态:</label>
                <el-select v-model="statusSN" size="small" style="width:100px" placeholder="请选择">
                    <el-option v-for="item in statusList" :key="item.sn" :label="item.name" :value="item.sn">
                    </el-option>
                </el-select>

                <el-button style="margin-left:1rem" size="mini" icon="el-icon-search" round @click="search">查询
                </el-button>
                <el-button size="mini" icon="el-icon-plus" round @click="add">新增</el-button>
            </el-header>
            <el-container>
                <el-container>
                    <el-main height="">
                        <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                            <el-table-column type="index" label="序号" width="80px">
                                <template slot-scope="scope">
                                    {{scope.$index+1+(pageIndex-1)*pageSize}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="customerName" label="客户">
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目">
                            </el-table-column>
                            <el-table-column prop="faultTypeName" label="故障类型">
                            </el-table-column>
                            <el-table-column prop="equipmentName" label="设备名称">
                            </el-table-column>
                            <el-table-column prop="requester" label="报修人">
                            </el-table-column>
                            <el-table-column label="状态" width="120px">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.statusSN=='dZhH1653917920115'"
                                        style="color:blue">{{scope.row.statusName}}</span>
                                    <span v-if="scope.row.statusSN=='rEJ11653963575459'"
                                        style="color:orange">{{scope.row.statusName}}</span>
                                         <span v-if="scope.row.statusSN=='yeEQ1653988344744'"
                                        style="color:green">{{scope.row.statusName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="时间">
                            </el-table-column>
                            <el-table-column label="操作" width="120px">
                                <template slot-scope="scope">
                                    <el-dropdown>
                                        <el-button size="small">
                                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item><span @click="detail(scope.row)">详情</span>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="scope.row.statusSN=='dZhH1653917920115'"><span
                                                    @click="update(scope.row)">修改</span>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="scope.row.statusSN=='dZhH1653917920115'"><span
                                                    @click="remove(scope.row)">删除</span>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="scope.row.statusSN=='dZhH1653917920115'"><span
                                                    @click="express(scope.row)">发货登记</span>
                                            </el-dropdown-item>
                                              <el-dropdown-item v-if="scope.row.statusSN=='rEJ11653963575459'"><span
                                                    @click="finish(scope.row)">完成</span>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-main>
                    <el-footer height="">
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-container>
            </el-container>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="40%">
            <NewView ref="NewView" :key="dialogWindowKey" @close="newViewClose" @submit="newViewSubmit"></NewView>
        </el-dialog>
        <!--发货视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="expressViewVisible" width="40%">
            <ExpressView ref="ExpressView" :key="dialogWindowKey" @close="expressViewClose" @submit="expressViewSubmit">
            </ExpressView>
        </el-dialog>
    </div>
</template>
<script>
    import CustomerInfoService from '../../../service/customer/CustomerInfoService';
    import ProjectInfoService from '../../../service/project/ProjectInfoService';
    import SupplierRepairService from '../../../service/repair/SupplierRepairService';
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import NewView from './NewView.vue';
    import ExpressView from './ExpressView.vue';
    export default {
        data() {
            return {
                customerList: [{
                    "id": 0,
                    "sn": "",
                    "name": "全部"
                }],
                projectList: [{
                    "id": 0,
                    "sn": "",
                    "name": "全部"
                }],
                statusList: [{
                    "id": 0,
                    "sn": "",
                    "name": "全部"
                }],
                list: [],
                customerSN: '',
                projectSN: '',
                requester: '',
                equipmentName: '',
                statusSN: '',
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowTitle: '',
                dialogWindowVisible: false,
                dialogWindowKey: '',
                expressViewVisible: false

            }
        },
        mounted() {
            //获取客户列表
            this.getCustomerList();
            //获取状态列表
            this.getStatusList();
            //获取列表
            this.getList();
        },
        methods: {
            //获取客户列表
            getCustomerList() {
                var params = {
                    customerSN: this.customerSN,
                    projectSN: this.projectSN,
                    equipmentName: this.equipmentName,
                    requester: this.requester,
                    statusSN: this.statusSN,
                    pageIndex: 1,
                    pageSize: 1000,
                    status: 1
                }
                CustomerInfoService.getList(params, (response) => {
                    if (response.code == 0) {
                        response.list.forEach((item) => {
                            this.customerList.push(item);
                        })
                    }
                })
            },
            //获取项目列表
            getProjectList() {
                this.projectSN = '';
                if (this.projectList.length > 1) {
                    this.projectList.splice(1, this.projectList.length - 1);
                }
                if (this.customerSN == '') {
                    this.projectSN = '';
                } else {
                    var params = {
                        pageIndex: 1,
                        pageSize: 1000,
                        customerSN: this.customerSN
                    }
                    ProjectInfoService.getList(params, (response) => {
                        if (response.list.length > 0) {
                            response.list.forEach((item) => {
                                this.projectList.push(item);
                            })
                        } else {
                            this.projectSN = '';
                        }
                    })
                }
            },
            //获取状态列表
            getStatusList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "supplierRepairStatus",
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    if (response.code == 0) {
                        response.list.forEach((item) => {
                            this.statusList.push(item);
                        })
                    }
                })
            },
            //获取列表
            getList() {
                var params = {
                    customerSN: this.customerSN,
                    projectSN: this.projectSN,
                    equipmentName: this.equipmentName,
                    requester: this.requester,
                    statusSN: this.statusSN,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }
                SupplierRepairService.getList(params, (response) => {
                    this.list = response.list;
                    this.recordCount = response.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //查询
            search() {
                this.pageIndex = 1;
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增厂家返修";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("supplierRepair", "{}");
                sessionStorage.setItem("submitFlag", 1);
                sessionStorage.setItem("action", 'add');
                this.dialogWindowVisible = true;
            },
            //删除
            remove(obj) {
                this.$confirm("您确定要删除此数据吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var params = {
                        sn: obj.sn
                    }
                    SupplierRepairService.delete(params, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    })
                }).catch(() => {})

            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "修改厂家返修";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("supplierRepair", JSON.stringify(obj));
                sessionStorage.setItem("submitFlag", 1);
                sessionStorage.setItem("action", 'update');
                this.dialogWindowVisible = true;
            },
            //修改
            detail(obj) {
                this.dialogWindowTitle = "厂家返修详情";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("supplierRepair", JSON.stringify(obj));
                sessionStorage.setItem("submitFlag", 0);
                sessionStorage.setItem("action", 'detail');
                this.dialogWindowVisible = true;
            },
            //新增视图提交
            newViewSubmit(response) {
                this.$message({
                    message: response.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },
            //新增视图关闭
            newViewClose() {
                this.dialogWindowVisible = false
            },
            //发货登记
            express(obj) {
                this.dialogWindowTitle = "发货登记";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("repairSN", obj.sn);
                this.expressViewVisible = true;
            },
            //发货视图提交
            expressViewSubmit(response) {
                this.$message({
                    message: response.msg,
                    type: 'success'
                })
                this.expressViewVisible = false;
                this.getList();
            },
            //发货视图关闭
            expressViewClose() {
                this.expressViewVisible = false;
            },
            //完成
            finish(obj)
            {
                 this.$confirm("您确定完成吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var params = {
                        sn: obj.sn
                    }
                    SupplierRepairService.finish(params, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    })
                }).catch(() => {})
            }
        },
        components: {
            NewView,
            ExpressView
        }

    }
</script>

<style lang="scss" scoped>

</style>