//系统管理路由
import SystemManageView from '../view/systemManage/SystemManageView';
//角色列表视图
import View_Role_List from '../view/systemManage/role/ListView';
//新增角色视图
import View_Role_New from '../view/systemManage/role/NewView';
//部门列表视图
import View_Department_List from '../view/systemManage/department/ListView';
//新增部门视图
import View_Department_New from '../view/systemManage/department/NewView';
//字典分类视图列表
import View_Category_List from '../view/systemManage/dictionary/CategoryListView';
//新增字典分类视图
import View_Category_New from '../view/systemManage/dictionary/NewCategoryView';
//字典项视图列表
import View_Item_List from '../view/systemManage/dictionary/ItemListView';
//新增字典项视图
import View_Item_New from '../view/systemManage/dictionary/NewItemView';
//用户列表视图
import View_User_List from '../view/systemManage/user/ListView';
//新增用户视图
import View_User_New from '../view/systemManage/user/NewView';
//模块列表视图
import View_Module_List from '../view/systemManage/module/ListView';
//新增模块视图
import View_Module_New from '../view/systemManage/module/NewView';
//缓存管理视图
import View_CacheManage from '../view/systemManage/cache/CacheManageView';
export default {
    path: 'systemManage',
    name:"systemManage",
    component: SystemManageView,
    children: [
        //角色
        {
            path: 'role/list',
            name:"roleList",
            component: View_Role_List
        },
        {
            path: 'role/new',
            component: View_Role_New
        },
        //部门
        {
            path: 'department/list',
            component: View_Department_List
        },
        {
            path: 'department/new',
            component: View_Department_New
        },
        //用户
        {
            path: 'user/list',
            name:"userList",
            component: View_User_List
        },
        {
            path: 'user/new',
            component: View_User_New
        },
        //模块
        {
            path: 'module/list',
            component: View_Module_List
        },
        {
            path: 'module/new',
            component: View_Module_New
        },
        //字典分类列表
        {
            path: 'dictionary/category/list',
            component: View_Category_List
        },
        //新增字典分类
        {
            path: 'dictionary/category/new',
            component: View_Category_New
        },
        //字典项列表
        {
            path: 'dictionary/item/list',
            component: View_Item_List
        },
        //新增字典项列表
        {
            path: 'dictionary/item/new',
            component: View_Item_New
        },
        //缓存管理
        {
            path:'cache/cacheManage',
            component:View_CacheManage
        }

    ]

}