import AxiosHelper from '../../util/AxiosHelper';
//角色服务
class RoleService
{
   //获取列表
   static getList(params,handle)
   {
      AxiosHelper.get("/systemManage/role/getList",params,handle);
   }
   //新增
   static add(obj,handle)
   {
      AxiosHelper.post("/systemManage/role/add",obj,handle);
   }
   //修改
   static update(obj,handle)
   {
      AxiosHelper.post("/systemManage/role/update",obj,handle);
   }
   //设置模块权限
   static setModuleRight(obj,handle)
   {
    AxiosHelper.post("/systemManage/role/setModuleRight",obj,handle);
   }
   //获取模块权限
   static getModuleRight(params,handle)
   {
      AxiosHelper.get("/systemManage/role/getModuleRight",params,handle);
   }
}
export default RoleService;