<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">名称:</label>
                  <el-input size="mini"  v-model.trim="name" placeholder="请输入名称"  class="inputSearch" ></el-input>
                <el-button size="mini" round icon="el-icon-search"  @click="search">查询</el-button>
                <el-button size="mini" round icon="el-icon-plus"  @click="add">新增</el-button>
            </el-header>
            <el-main height="">
                <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                    <el-table-column type="index" label="序号" width="80px">
                        <template slot-scope="scope">
                            {{(scope.$index+1)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <el-table-column label="状态" width="120px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==1">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120px">
                        <template slot-scope="scope">
                            <el-button size="mini" round icon="el-icon-edit" @click="update(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer height="">
                    <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewDepartmentView ref="NewDepartmentView" :key="dialogWindowKey" @close="newDepartmentViewClose" @submit="newDepartmentViewSubmit">
            </NewDepartmentView>
        </el-dialog>
    </div>
</template>

<script>
    import DepartmentService from '../../../service/systemManage/DepartmentService';
    import NewDepartmentView from '../../../view/systemManage/department/NewView';
    export default {
        data() {
            return {
                name: '',
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var params = {
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                DepartmentService.getList(params, (data) => {
                    this.list = data.list;
                     this.recordCount=data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增部门";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "修改部门";
                this.dialogWindowKey = (new Date()).getTime();
                this.$nextTick(() => {
                    this.$refs.NewDepartmentView.department = Object.assign({}, obj);
                });
                this.dialogWindowVisible = true;
            },
            //查询
            search() {
                this.getList();
            },
            //新增部门视图关闭
            newDepartmentViewClose() {
                this.dialogWindowVisible = false;
            },
            //新增角色视图提交
            newDepartmentViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            }
        },
        components: {
            NewDepartmentView
        }


    }
</script>

<style lang="scss" scoped>

</style>