<template>
    <div>
        <el-form :model="passwordInfo" ref="passwordInfo" :rules="rules" label-width="80px" :inline="false"
            size="normal">
            <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model.trim="passwordInfo.newPassword" placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="surePassword">
                <el-input type="password" v-model.trim="passwordInfo.surePassword" placeholder="请输入确认密码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
                <el-button @click="close">关闭</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
import UserService from '../service/systemManage/UserService';
    export default {
        data() {
            return {
                passwordInfo: {
                    "userId": localStorage.getItem("userId"),
                    "newPassword": "",
                    "surePassword": ""
                },
                rules: {
                    newPassword: {
                        required: true,
                        message: '新密码不能为空',
                        trigger: 'blur'
                    },
                    surePassword: {
                        required: true,
                        message: '确认密码不能为空',
                        trigger: 'blur'
                    }
                }
            }
        },
        mounted() {

        },
        methods: {
            //提交
            submit() {
                this.$refs.passwordInfo.validate((valid) => {
                    if (this.passwordInfo.surePassword != this.passwordInfo.newPassword) {
                        this.$message({
                            message: '两次密码输入不一致',
                            type: 'warning'
                        })
                        return;
                    }
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                             UserService.resetPassword(this.passwordInfo, (response) => {
                                this.$message({
                                    message: response.msg,
                                    type: 'success'
                                });
                                 this.$emit("close");
                            })
                        }).catch(() => {})

                    }
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>