<template>
    <div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="故障处理权限" name="faultTypeRight">
                <TreeTransfer :title="title" :from_data='faultTypeList' :to_data='choosedFaultTypeList'
                    :defaultCheckedKeys='choosedFaultTypeList' :defaultProps="{label:'name'}" node_key="sn"
                    @add-btn='add' @remove-btn='remove' :mode='mode' openAll>
                </TreeTransfer>
            </el-tab-pane>
        </el-tabs>
        <el-row style="padding-top:1em">
            <el-col :span="24">
                <el-button type="primary" size="mini" @click="submit">提交</el-button>
                <el-button size="mini" @click="close">关闭</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import TreeTransfer from 'el-tree-transfer';
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import UserService from '../../../service/systemManage/UserService';
    import AxiosHelper from '../../../util/AxiosHelper';
    export default {
        data() {
            return {
                "userId": sessionStorage.getItem("userId"),
                "mode": "transfer",
                "activeName": "faultTypeRight",
                "title": ["故障类型", "已选类型"],
                "faultTypeList": [],
                "choosedFaultTypeList": []
            }
        },
        mounted() {
            //获取故障类型列表
            this.getFaultTypeList();
            //获取用户故障类型权限
            this.getFaultTypeRight();
        },
        methods: {
            //获取故障类型列表
            getFaultTypeList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 10,
                    "categorySN": "faultType",
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    this.faultTypeList = response.list;
                })
            },
            //获取故障类型权限
            getFaultTypeRight() {
                var params = {
                    "userId": this.userId
                }
                UserService.getFaultTypeRight(params, (response) => {
                    this.choosedFaultTypeList = response.list;
                })
            },
            //添加
            add(fromData, toData, obj) {


            },
            //移除
            remove(fromData, toData, obj) {

            },
            //提交
            submit() {
                // if (this.choosedFaultTypeList.length == 0) {
                //     this.$message({
                //         message: "请添加故障类型",
                //         type: 'warning'
                //     });
                //     return;
                // }
                var obj = {
                    "userId": this.userId,
                    "faultTypeList": this.choosedFaultTypeList
                }
                UserService.setFaultTypeRight(obj, (response) => {
                    this.$message({
                        message: response.msg,
                        type: 'success'
                    });
                })
            },
            //关闭
            close() {
                this.$emit("close");
            }
        },
        components: {
            TreeTransfer
        }
    }
</script>

<style lang="scss" scoped>

</style>