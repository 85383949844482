<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">名称:</label>
                  <el-input size="mini"  v-model.trim="name" placeholder="请输入名称"  class="inputSearch" ></el-input>
                <el-button size="mini" round icon="el-icon-search"  @click="search">查询</el-button>
                <el-button size="mini" round icon="el-icon-plus"  @click="add">新增</el-button>
            </el-header>
           <el-main height="">
               <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                       <el-table-column type="index" label="序号" width="80px">
                           <template slot-scope="scope" >
                               {{scope.$index+1+(pageIndex-1)*pageSize}}
                           </template>
                        </el-table-column>
                          <el-table-column prop="name" label="名称" >
                        </el-table-column>
                          <el-table-column prop="sn" label="编号" >
                              </el-table-column>
                          <el-table-column prop="seq" label="排序号" >
                        </el-table-column>
                        <el-table-column label="状态" width="120px">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==1">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="240px">
                            <template slot-scope="scope">
                                  <el-button size="mini" round icon="el-icon-edit" @click="update(scope.row)">编辑</el-button>
                             <el-button size="mini" icon="el-icon-document-copy"
                                        @click="remove(scope.row)" round>删除
                                    </el-button>
                            </template>
                        </el-table-column>
               </el-table>
               <el-footer height="">
                   <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next" :total="recordCount"
                                :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
               </el-footer>
           </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewCategoryView ref="NewCategoryView" :key="dialogWindowKey" @close="close" @submit="submit"></NewCategoryView>
        </el-dialog>
    </div>
</template>

<script>
import DictionaryService from '../../../service/systemManage/DictionaryService';
import NewCategoryView from '../../../view/systemManage/dictionary/NewCategoryView'
    export default {
        data()
        {
           return{
               name:'',
               list:[],
               pageIndex:1,
               pageSize:10,
               recordCount:0,
               dialogWindowKey:'',//对话框窗口key
               dialogWindowTitle:'',//对话框窗口标题
               dialogWindowVisible:false,
               itemDialogWindowVisible:false

           }
        },
        mounted()
        {
           //获取列表
           this.getList();
        },
        methods:{
            //获取列表
            getList()
            {
               var params={
                   "name":this.name,
                   "pageIndex":this.pageIndex,
                   "pageSize":this.pageSize
               }
               DictionaryService.getCategoryList(params,(data)=>{
                  this.list=data.list;
                  this.recordCount=data.recordCount;
               })
            },
            //页码改变
            pageChanged(pageIndex)
            {
               this.pageIndex=pageIndex;
               this.getList();
            },
            //新增
            add()
            {
              this.dialogWindowTitle="新增分类";
              this.dialogWindowKey=(new Date()).getTime();
              this.dialogWindowVisible=true;
            },
            //修改
            update(obj)
            {
                this.dialogWindowTitle="编辑分类";
                this.dialogWindowKey=(new Date()).getTime();
                this.dialogWindowVisible=true;
                 this.$nextTick(()=>{
                    this.$refs.NewCategoryView.category=Object.assign({},obj);
                });
            },
            //查询
            search()
            {
               this.getList();
            },
            //关闭
            close()
            {
               this.dialogWindowVisible=false;
            },
            //提交
            submit(data)
            {
                this.$message({
                   message:data.msg,
                   type:'success'
                })
                this.dialogWindowVisible=false;
               this.getList();
            },
               //删除
            remove(obj) {
               this.$confirm("您确定要删除此信息吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        sn: obj.sn
                    }
                    DictionaryService.removeCategory(paras,(response)=>{
                          this.$message({
                            showClose: true,
                            message: response.msg,
                            type: 'success'
                        });
                        this.getList();
                    });
                }).catch(() => {})
            },
        },
        components:{
            NewCategoryView
        }

        
    }
</script>

<style lang="scss" scoped>

</style>