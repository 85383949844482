<template>
    <div>
        <el-container>
            <el-container>
                <el-header>
                    <el-button size="small" icon="el-icon-plus" round @click="add">新增</el-button>
                </el-header>
                <el-main>
                    <el-table size="small" :data="list" border stripe header-cell-class-name="tableHeader">
                        <el-table-column type="index" label="序号" width="80px">
                            <template slot-scope="scope">
                                {{scope.$index+1+(pageIndex-1)*pageSize}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="noticeWayName" label="通知方式">
                        </el-table-column>
                        <el-table-column prop="name" label="接口名称">
                        </el-table-column>
                        <el-table-column label="启用状态" width="100px">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" disabled/>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="220px">
                            <template slot-scope="scope">
                                <el-button size="mini" icon="el-icon-document-copy" @click="remove(scope.row)" round>删除
                                </el-button>
                                <el-button size="mini" icon="el-icon-edit" @click="update(scope.row)" round>编辑
                                </el-button>
                                <!-- <el-button size="mini" icon="el-icon-files" @click="document(scope.row)" round>资料库 -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-footer>
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-main>
            </el-container>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewView ref="NewView" :key="dialogWindowKey" @close="newViewClose" @submit="newViewSubmit"></NewView>
        </el-dialog>
    </div>
</template>
<script>
    import NoticeInterfaceService from '../../../service/notice/NoticeInterfaceService';
    import NewView from './NewView.vue';
    export default {
        data() {
            return {
                name: '',
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var paras = {
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                NoticeInterfaceService.getList(paras, (response) => {
                    this.list = response.list;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增接口";
                this.dialogWindowKey = (new Date()).getTime();
                  sessionStorage.setItem("noticeInterface","{}");
                this.dialogWindowVisible = true;
            },
              //修改
            update(obj) {
                this.dialogWindowTitle = "编辑接口";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("noticeInterface",JSON.stringify(obj));
                this.dialogWindowVisible = true;
            },
            //删除
            remove(obj) {
                this.$confirm("您确定要删除此信息吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        sn: obj.sn
                    }
                    NoticeInterfaceService.delete(paras, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    })
                }).catch(() => {})
            },
            //新增视图提交
            newViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },
            //新增视图关闭
            newViewClose() {
                this.dialogWindowVisible = false;
            },

            //查询
            search() {
                this.getList();
            },
            // //修改状态
            // updateStatus(obj)
            // {
            //     var paras={
            //         sn:obj.sn,
            //         noticeWaySN:obj.noticeWaySN,
            //         status:obj.status
            //     }
            //   NoticeInterfaceService.updateStatus(paras,(response)=>{
            //       this.$message({
            //         message: response.msg,
            //         type: 'success'
            //     })
            //   })
            // }
        },
        components: {
            NewView
        }
    }
</script>
<style scoped>
</style>