import AxiosHelper from '../../util/AxiosHelper';
class SiteInfoService{
    //新增
   static add(params,handle)
   {
     AxiosHelper.post("/site/siteInfo/add",params,handle);
   }
   //修改
   static update(params,handle)
   {
    AxiosHelper.post("/site/siteInfo/update",params,handle);
   }
   //获取列表
   static getList(params,handle)
   {
    AxiosHelper.get("/site/siteInfo/getList",params,handle);
   }
   //删除
   static remove(params,handle)
   {
    AxiosHelper.post("/site/siteInfo/remove",params,handle);
   }
}
export default SiteInfoService