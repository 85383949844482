import AxiosHelper from '../../util/AxiosHelper';
//数据字典服务服务
class DictionaryService
{
    //获取分类列表
    static getCategoryList(params,handle)
    {
        AxiosHelper.get("/dictionary/category/getList",params,handle);
    }
    //新增分类
    static  addCategory(obj,handle)
    {
      AxiosHelper.post("/dictionary/category/add",obj,handle);
    }
    //修改分类
    static updateCategory(obj,handle)
    {
        AxiosHelper.post("/dictionary/category/update",obj,handle);
    }
    //删除分类
    static removeCategory(obj,handle)
    {
        AxiosHelper.post("/dictionary/category/remove",obj,handle);
    }
    //获取项列表
    static getItemList(params,handle)
    {
        AxiosHelper.get("/dictionary/item/getList",params,handle);
    }
    //新增项
    static  addItem(obj,handle)
    {
      AxiosHelper.post("/dictionary/item/add",obj,handle);
    }
    //修改项
    static updateItem(obj,handle)
    {
        AxiosHelper.post("/dictionary/item/update",obj,handle);
    }
    //删除项
    static removeItem(obj,handle)
    {
        AxiosHelper.post("/dictionary/item/remove",obj,handle);
    }
    //获取图标列表
   static getIconList(params,handle)
   {
    AxiosHelper.get("/dictionary/item/getIconList",params,handle);
   }
    //删除文件
    static deleteFile(obj,handle)
    {
     AxiosHelper.post("/file/delete",obj,handle);
    }
      //下载文件
   static downloadFile(parms,handle)
   {
      AxiosHelper.download("/file/download",parms,handle);
   }

}
export default DictionaryService;