import ProjectView from '../view/project/ProjectView';
import ProjectInfoView from '../view/project/projectInfo/ListView';
export default {
    path:'project',
    component:ProjectView,
    children:[
        {
            path:'projectInfo',
            component:ProjectInfoView
        }
    ]
}