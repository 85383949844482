import AxiosHelper from '../../util/AxiosHelper';
class CacheSrvice {
    //数据字典
    static refreshCache_Dictionary(handle) {
        AxiosHelper.post("/dictionary/item/refreshCache", null, handle);
    }
    //部门
    static refreshCache_Department(handle) {
        AxiosHelper.post("/systemManage/department/refreshCache", null, handle);
    }
    //角色
    static refreshCache_Role(handle) {
        AxiosHelper.post("/systemManage/role/refreshCache", null, handle);
    }
    //供应商
    static refreshCache_Supplier(handle) {
        AxiosHelper.post("/supplier/supplierInfo/refreshCache", null, handle);
    }
    //用户
    static refreshCache_User(handle) {
        AxiosHelper.post("/systemManage/user/refreshCache", null, handle);
    }

    //维修申请
    static refreshCache_RepairApply(handle) {
        AxiosHelper.post("/repair/repairApply/refreshCache", null, handle);
    }
    //设备信息
    static refreshCache_EquipmentInfo(handle) {
        AxiosHelper.post("/equipment/equipmentInfo/refreshCache", null, handle);
    }
     //区域信息
     static refreshCache_RegionInfo(handle) {
        AxiosHelper.post("/region/regionInfo/refreshCache", null, handle);
    }
     //客户信息
     static refreshCache_CustomerInfo(handle) {
        AxiosHelper.post("/customer/customerInfo/refreshCache", null, handle);
    }
}
export default CacheSrvice;