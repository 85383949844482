import VueRouter from "vue-router";
//用户登陆路由
import LoginRoute from './route/LoginRoute'
//首页路由
import IndexRoute from './route/IndexRoute';
const routes=[
    {
       path:'/',
       redirect:'/index'
    },
    //登陆路由
    LoginRoute,
    //首页路由
    IndexRoute
]
var route=new VueRouter({
   base:'/',
   mode:'hash',
   routes
});

export default route;