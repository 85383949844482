<template>
    <div>
        <el-container>
            <el-container>
                <el-main>
                    <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                        <el-table-column type="index" label="序号" width="80px">
                            <template slot-scope="scope">
                                {{scope.$index+1+(pageIndex-1)*pageSize}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="typeName" label="故障类型">
                        </el-table-column>
                        <!-- <el-table-column prop="equipmentName" label="设备名称">
                        </el-table-column> -->
                        <el-table-column prop="time" label="时间">
                        </el-table-column>
                          <el-table-column prop="remark" label="备注">
                        </el-table-column>
                        <el-table-column label="处理状态" width="100px">
                            <template slot-scope="scope">
                                <span style="color:green" v-if="scope.row.status==1">已恢复</span>
                                <span style="color:red" v-if="scope.row.status==0">未恢复</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-footer>
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import AlarmInfoService from '../../../service/alarm/AlarmInfoService';
    export default {
        data() {
            return {
                equipmentSN:sessionStorage.getItem("equipmentSN"),
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var paras = {
                    equipmentSN:this.equipmentSN,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }
                AlarmInfoService.getList(paras, (response) => {
                    this.list = response.list;
                    this.recordCount = response.recordCount;
                })
            },
             //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
        },
    }
</script>

<style scoped>

</style>