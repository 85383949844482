import AxiosHelper from '../../util/AxiosHelper';
class NoticeInterfaceService {
    //新增
    static add(paras, handle) {
        AxiosHelper.post("/notice/noticeInterface/add", paras, handle);
    }
    //修改
    static update(paras, handle) {
        AxiosHelper.post("/notice/noticeInterface/update", paras, handle);
    }
    //修改状态
    static updateStatus(paras,handle)
    {
        AxiosHelper.post("/norice/noticeInterface/updateStatus", paras, handle);
    }
    //删除
    static delete(paras, handle) {
        AxiosHelper.post("/notice/noticeInterface/delete", paras, handle);
    }
    //获取列表
    static getList(paras, handle) {
        AxiosHelper.get("/notice/noticeInterface/getList", paras, handle);
    }
}
export default NoticeInterfaceService;