<template>
    <div>
     <TreeTransfer :title="title" :from_data='moduleList' :to_data='choosedModuleList'
            :defaultCheckedKeys='choosedModuleList' :defaultProps="props" node_key="sn" pid="parentSN" @add-btn='add' @remove-btn='remove' :mode='mode' 
            openAll>
        </TreeTransfer>
        <el-row style="padding-top:1em">
            <el-col :span="24">
                <el-button type="primary" size="mini" @click="submit">提交</el-button>
                <el-button size="mini" @click="close">关闭</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
   import TreeTransfer from 'el-tree-transfer';
   import ModuleService from '../../../service/systemManage/ModuleService';
   import RoleService from '../../../service/systemManage/RoleService';
    export default {
        data() {
            return {
                roleSN:localStorage.getItem("roleSN"),
                title:["模块列表","已选模块"],
                 mode: "transfer",
                 moduleList: [],
                choosedModuleList: [],
                choosedModuleSNList:[],
                props: {
                    children: 'children',
                    label: 'name'
                },
            }
        },
        mounted()
        {
         //获取模块列表
           this.getModuleList();
         //获取模块权限
         this.getModuleRight();
        },
        methods:
        {
            //获取模块列表
             getModuleList()
             {
                ModuleService.getModuleList(null,(response)=>{
                    this.moduleList=response.list;
                })
             },
             //获取模块权限
             getModuleRight()
             {
                 var params={
                     "roleSN":this.roleSN
                 }
                RoleService.getModuleRight(params,(response)=>{
                  this.choosedModuleList=response.list;
                })
             },
           //添加
            add(fromData, toData, obj) {
                
             
            },
            //移除
            remove(fromData, toData, obj) {
               
            },
            //提交
            submit()
            {
               if(this.choosedModuleList.length==0)
               {
                    this.$message({
                        message: "请添加模块",
                        type: 'warning'
                    });
                    return;
               }
                //获取模块编号列表
                this.getModuleSNList(this.choosedModuleList);
                //设置模块权限
                var obj={
                    "roleSN":this.roleSN,
                    "moduleList":this.choosedModuleSNList
                }
                RoleService.setModuleRight(obj,(response)=>{
                     this.$message({
                        message: response.msg,
                        type: 'success'
                    });
                })
            },
            //获取模块编号列表
            getModuleSNList(arr) {
                for (var i = 0; i < arr.length; i++) {
                    this.choosedModuleSNList.push({
                        sn: arr[i].sn
                    })
                    if (arr[i].children != null) {
                        this.getModuleSNList(arr[i].children);
                    }
                }
            },
            //关闭
            close()
            {
              this.$emit("close");
            }
        },
        components:{
            TreeTransfer
        }
    }
</script>

<style lang="scss" scoped>

</style>