<template>
    <div>
        <el-container>
            <el-container>
                <el-container>
                    <el-main>
                        <el-table size="small" :data="list" border stripe header-cell-class-name="tableHeader">
                            <el-table-column prop="totalCount" label="设备总数">
                            </el-table-column>
                            <el-table-column prop="onlineCount" label="在线设备数">
                            </el-table-column>
                            <el-table-column label="在线率">
                                <template slot-scope="scope">
                                    {{scope.row.onlineRate}}%
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-main>
                </el-container>
            </el-container>
        </el-container>

    </div>
</template>
<script>
    import EquipmentInfoService from '../../service/equipment/EquipmentInfoService';
    export default {
        data() {
            return {
                list: [{
                    totalCount: 0,
                    onlineCount: 0,
                    onlineRate: 0
                }]
            }
        },
        mounted() {
            //获取在线率
            this.getOnlineRate();
        },
        methods: {
            //获取在线率
            getOnlineRate() {
                EquipmentInfoService.getOnlineRate(null, (response) => {
                    this.list[0].totalCount = response.obj.totalCount;
                    this.list[0].onlineCount = response.obj.onlineCount;
                    this.list[0].onlineRate = response.obj.onlineRate;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>