import ContentView from '../view/ContentView';
//系统管理路由
import SystemManageRoute from '../route/SystemManageRoute';
//设备路由
import EquipmentRoute from '../route/EquipmentRoute';
import CustomerRoute from '../route/CustomerRoute';
//地图管理路由
import MapManageRoute from '../route/MapManageRoute';
//区域路由
import RegionRoute from '../route/RegionRoute';
//工单路由
import WorkOrderRoute from '../route/WorkOrderRoute';
//项目路由
import ProjectRoute from '../route/ProjectRoute';
//维修路由
import RepairRoute from '../route/RepairRoute';
//供应商路由
import SupplierRoute from '../route/SupplierRoute';
//通知路由
import NoticeRoute from '../route/NoticeRoute';
//主屏路由
import MainscreenRoute from '../route/MainscreenRoute';
//报表理由
import ReportRoute from './ReportRoute';
export default
{
    path:'content',
    name:"content",
    component:ContentView,
    children:[
        //设备管理路由
        EquipmentRoute,
        //客户路由
        CustomerRoute,
        //地图管理路由
        MapManageRoute,
        //区域路由
        RegionRoute,
        //工单路由
        WorkOrderRoute,
        //项目路由
        ProjectRoute,
        //供应商路由
        SupplierRoute,
        //维修路由
        RepairRoute,
        //通知路由
        NoticeRoute,
        //系统管理路由
        SystemManageRoute,
        //主屏路由
        MainscreenRoute,
        //报表路由
        ReportRoute
    ]
}