<template>
    <div>
        <el-container>
            <el-container>
                <el-header>
                    <el-button size="small" icon="el-icon-plus" round @click="add">新增</el-button>
                </el-header>
                <el-main>
                    <el-table size="small" :data="list" border stripe header-cell-class-name="tableHeader">
                        <el-table-column type="index" label="序号" width="80px">
                            <template slot-scope="scope">
                                {{scope.$index+1+(pageIndex-1)*pageSize}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="eventTypeName" label="事件类型">
                        </el-table-column>
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                        <el-table-column label="操作" width="220px">
                            <template slot-scope="scope">
                                <el-button size="mini" icon="el-icon-delete" @click="remove(scope.row)" round>删除
                                </el-button>
                                <el-button size="mini" icon="el-icon-edit" @click="update(scope.row)" round>编辑
                                </el-button>
                                <!-- <el-button size="mini" icon="el-icon-files" @click="document(scope.row)" round>资料库 -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-footer>
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-main>
            </el-container>
        </el-container>
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="40%">
            <NewView ref="NewView" :key="dialogWindowKey" @submit="submitHandle" @close="closeHandle">
            </NewView>
        </el-dialog>
    </div>
</template>
<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import PolicyService from '../../../service/notice/PolicyService';
    import NewView from './NewView.vue';
    export default {
        data() {
            return {
                name: '',
                regionSN: '',
                typeSN: '',
                typeList: [{
                    key: "",
                    name: "全部"
                }],
                regionList: [{
                    key: "",
                    name: "全部"
                }],
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false
            }
        },
        mounted() {
            // //获取列表
            this.getList();
        },
        methods: {
            //获取区域列表
            getRegionList() {
                var paras = {
                    pageIndex: 1,
                    pageSize: 1000
                };
                RegionService.getList(paras, (response) => {
                    response.list.forEach(item => {
                        this.regionList.push(item);
                    });
                });
            },
            //获取类型列表
            getTypeList() {
                var params = {
                    "categorySN": "faultType",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    data.list.forEach(element => {
                        this.typeList.push(element);
                    });
                })
            },
            //获取列表
            getList() {
                var paras = {
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                PolicyService.getList(paras, (response) => {
                    this.list = response.list;
                    this.recordCount = response.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //查询
            search() {
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增策略";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("policy", "{}");
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑策略";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("policy", JSON.stringify(obj));
                this.dialogWindowVisible = true;
            },
            //删除
            remove(obj) {
                this.$confirm("您确定要删除此设备吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        sn: obj.sn
                    }
                    PolicyService.remove(paras, (response) => {
                        this.$message({
                            showClose: true,
                            message: response.msg,
                            type: 'success'
                        });
                        this.getList();
                    })
                }).catch(() => {})
            },
            //提交handle
            submitHandle(response) {
                this.$message({
                    message: response.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },
            //关闭handle
            closeHandle() {
                this.dialogWindowVisible = false;
            }
        },
        components: {
            NewView
        }
    }
</script>
<style scoped>
</style>