<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form label-width="100px" size="small">
                    <el-form-item label="数据字典:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshDictionaryCache" round>刷新缓存
                        </el-button>
                    </el-form-item>
                    <el-divider></el-divider>
                    <el-form-item label="部门:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshDepartmentCache" round>刷新缓存
                        </el-button>
                    </el-form-item>
                    <el-divider></el-divider>
                    <el-form-item label="角色:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshRoleCache" round>刷新缓存</el-button>
                    </el-form-item>
                    <el-divider></el-divider>
                    <!-- <el-form-item label="供应商:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshSupplierCache" round>刷新缓存</el-button>
                    </el-form-item>
                    <el-divider></el-divider> -->
                    <!-- <el-form-item label="部门:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshDepartmentCache" round>刷新缓存
                        </el-button>
                    </el-form-item>
                    <el-divider></el-divider> -->
                    <el-form-item label="用户:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshUserCache" round>刷新缓存</el-button>
                    </el-form-item>
                    <el-divider></el-divider>
                    <el-form-item label="客户信息:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshCustomerInfoCache" round>刷新缓存
                        </el-button>
                    </el-form-item>
                    <el-divider></el-divider>
                    <!-- <el-form-item label="区域信息:">
                        <el-button size="mini" icon="el-icon-share" @click="refreshRegionInfoCache" round>刷新缓存
                        </el-button>
                    </el-form-item>
                    <el-divider></el-divider> -->
                </el-form>

            </el-main>

        </el-container>
    </div>
</template>

<script>
    import CacheService from '../../../service/systemManage/CacheService'
    export default {
        data() {
            return {

            }
        },
        methods: {
            //刷新字典缓存
            refreshDictionaryCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_Dictionary((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            },
            //刷新部门缓存
            refreshDepartmentCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_Department((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            },
            //刷新角色缓存
            refreshRoleCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_Role((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            },
            //刷新供应商
            refreshSupplierCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_Supplier((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            },
            //刷新用户
            refreshUserCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_User((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            },
            //刷新设备信息
            refreshEquipmentInfoCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_EquipmentInfo((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            },
            //刷新客户信息
            refreshCustomerInfoCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_CustomerInfo((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            },
            //刷新区域信息
            refreshRegionInfoCache() {
                this.$confirm("您确定要刷新缓存吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    CacheService.refreshCache_RegionInfo((response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                    })
                }).catch(() => {})
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>