import MapManageView from '../view/mapManage/MapManageView';
import View_MapInfo_List from '../view/mapManage/mapInfo/ListView';
import View_MapInfo_New from '../view/mapManage/mapInfo/NewView';
export default
{
    path:"mapManage",
    component:MapManageView,
    children:[
        {
            path:'mapInfo/list',
            component:View_MapInfo_List
        },
        {
            path:'mapInfo/new',
            component:View_MapInfo_New
        }
    ]
}