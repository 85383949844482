import AxiosHelper from '../../util/AxiosHelper';
class UserService
{
   //获取列表
   static getList(params,handle)
   {
       AxiosHelper.get("/systemManage/user/getList",params,handle);
   }
   //通过用户id获取
   static getByUserId(params,handle)
   {
      AxiosHelper.get("/systemManage/user/getByUserId",params,handle);
   }
   //获取用户信息
   static getUserInfo(params,handle)
   {
      AxiosHelper.get("/systemManage/user/getUserInfo",params,handle);
   }
   //新增
   static add(obj,handle)
   {
      AxiosHelper.post("/systemManage/user/add",obj,handle); 
   }
   //修改
   static update(obj,handle)
   {
      AxiosHelper.post("/systemManage/user/update",obj,handle); 
   }
   //重置密码
   static resetPassword(obj,handle)
   {
      AxiosHelper.post("/systemManage/user/resetPassword",obj,handle); 
   }
   //设置故障类型权限
   static setFaultTypeRight(obj,handle)
   {
      AxiosHelper.post("/systemManage/user/setFaultTypeRight",obj,handle); 
   }
   //获取故障类型权限
   static getFaultTypeRight(params,handle)
   {
      AxiosHelper.get("/systemManage/user/getFaultTypeRight",params,handle);
   }
   //登陆
   static login(obj,handle)
   {
      AxiosHelper.post("/systemManage/user/login",obj,handle);
   }
}
export default UserService;