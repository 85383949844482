<template>
    <div>
        <el-container>
            <el-container>
                <el-container>
                    <el-main height="">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="返修信息" name="basicInfo">
                                <el-form :model="supplierRepair" ref="supplierRepair" :rules="rules" label-width="100px"
                                    size="small">
                                    <el-form-item label="客户:" prop="customerSN">
                                        <el-select v-model="supplierRepair.customerSN" filterable placeholder="请选择"
                                            @change="getProjectList()">
                                            <el-option v-for="item in customerList" :key="item.sn" :label="item.name"
                                                :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="项目:" prop="projectSN">
                                        <el-select v-model="supplierRepair.projectSN" filterable placeholder="请选择"
                                            @change="$forceUpdate()">
                                            <el-option v-for="item in projectList" :key="item.sn" :label="item.name"
                                                :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="故障类型:" prop="faultTypeSN">
                                        <el-select v-model="supplierRepair.faultTypeSN" filterable placeholder="请选择">
                                            <el-option v-for="item in faultTypeList" :key="item.sn" :label="item.name"
                                                :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="设备名称:" prop="equipmentName">
                                        <el-input v-model.trim="supplierRepair.equipmentName" placeholder="请输入设备名称">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="设备型号:">
                                        <el-input v-model.trim="supplierRepair.equipmentStyle" placeholder="请输入设备型号">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="报修人:" prop="requester">
                                        <el-input v-model.trim="supplierRepair.requester" placeholder="请输入报修人">
                                        </el-input>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane v-if="supplierRepair.statusSN=='rEJ11653963575459'" label="发货信息" name="second">
                                <el-form :model="expressInfo" ref="expressInfo" label-width="80px" :inline="false"
                                    size="normal">
                                    <el-form-item label="快递公司">
                                        <el-input readonly v-model="expressInfo.expressCompanyName" placeholder="" size="small">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="快递单号">
                                        <el-input readonly v-model="expressInfo.expressSN" placeholder="" size="small">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="供应商">
                                        <el-input readonly v-model="expressInfo.supplierName" placeholder="" size="small">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="收货地址">
                                        <el-input readonly v-model="expressInfo.address" placeholder="" size="small">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="收货人">
                                        <el-input readonly v-model="expressInfo.contacts" placeholder="" size="small">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="联系电话">
                                        <el-input readonly v-model="expressInfo.tel" placeholder="" size="small">
                                        </el-input>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                        </el-tabs>
                    </el-main>
                    <el-footer height="">
                        <el-button v-if="submitFlag==1" size="mini" type="primary" @click="submit">提交</el-button>
                        <el-button size="mini" @click="close">关闭</el-button>
                    </el-footer>
                </el-container>
            </el-container>
        </el-container>

    </div>
</template>
<script>
    import CustomerInfoService from '../../../service/customer/CustomerInfoService';
    import ProjectInfoService from '../../../service/project/ProjectInfoService';
    import SupplierRepairService from '../../../service/repair/SupplierRepairService';
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    export default {
        data() {
            return {
                activeName: 'basicInfo',
                submitFlag: sessionStorage.getItem("submitFlag"),
                action:sessionStorage.getItem("action"),
                expressInfo: {},
                customerList: [],
                projectList: [],
                faultTypeList: [],
                supplierRepair: JSON.parse(sessionStorage.getItem("supplierRepair")),
                rules: {
                    customerSN: {
                        required: true,
                        message: '请选择客户',
                        trigger: 'blur'
                    },
                    projectSN: {
                        required: true,
                        message: '请选择项目',
                        trigger: 'blur'
                    },
                    faultTypeSN: {
                        required: true,
                        message: '请选择故障类型',
                        trigger: 'blur'
                    },
                    equipmentName: {
                        required: true,
                        message: '请输入设备名称',
                        trigger: 'blur'
                    }
                }
            }
        },
        mounted() {
             //初始化
            this.init();
            //获取客户列表
            this.getCustomerList();
            //获取故障类型
            this.getFaultTypeList();

        },
        methods: {
            //初始化
            init()
            {
                if(this.action=='detail'||this.action=='update')
                {
                    //获取项目列表
                    this.getProjectList();
                      this.supplierRepair.projectSN= JSON.parse(sessionStorage.getItem("supplierRepair")).projectSN;
                }
                //状态为已发货，获取发货信息
              if(this.supplierRepair.statusSN=='rEJ11653963575459')
              {
                this.getExpressInfo();
              }
            },
            //获取客户列表
            getCustomerList() {
                var params = {
                    pageIndex: 1,
                    pageSize: 1000,
                    status: 1
                }
                CustomerInfoService.getList(params, (response) => {
                    this.customerList = response.list;
                })
            },
            //获取项目列表
            getProjectList() {
                this.supplierRepair.projectSN = '';
                this.projectList.splice(0, this.projectList.length);
                var params = {
                    customerSN: this.supplierRepair.customerSN,
                    pageIndex: 1,
                    pageSize: 1000,
                    status: 1
                }
                ProjectInfoService.getList(params, (response) => {
                    if (response.code == 0) {
                        response.list.forEach((item) => {
                            this.projectList.push(item);
                        })
                    }
                })
            },
            //获取故障类型
            getFaultTypeList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "faultType",
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    if (response.code == 0) {
                        response.list.forEach((item) => {
                            this.faultTypeList.push(item);
                        })
                    }
                })
            },
            //获取发货信息
            getExpressInfo()
            {
                var params={
                    sn:this.supplierRepair.sn
                }
               SupplierRepairService.getExpressInfo(params,(response)=>{
                  this.expressInfo=response.data;
               })
            },
            submit() {
                this.$refs.supplierRepair.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.supplierRepair.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                SupplierRepairService.add(this.supplierRepair, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                SupplierRepairService.update(this.supplierRepair, (data) => {
                    this.$emit("submit", data);
                });
            },
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>