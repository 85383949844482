import EquipmentView from '../view/equipment/EquipmentView';
import View_EquipmentInfo_List from '../view/equipment/equipmentInfo/ListView';
import View_EquipmentInfo_New from '../view/equipment/equipmentInfo/NewView';
import View_EquipmentInfo_Detail from '../view/equipment/equipmentInfo/DetailView';
export default {
    path: "equipment",
    component: EquipmentView,
    children: [
        //设备信息
        {
            path: 'equipmentInfo/list',
            component: View_EquipmentInfo_List
        },
        {
            path: 'equipmentInfo/new',
            component: View_EquipmentInfo_New
        },
        {
            path: 'equipmentInfo/detail',
            component: View_EquipmentInfo_Detail
        }
    ]
}