<template>
    <div>
        <el-container class="container">
            <el-aside class="aside" :width="menuWidth">
                <el-container>
                    <el-header class="title" height="9vh">
                        <img src="../assets/image/logo.png" alt="" v-show="isLogoShow">
                        <span v-show="isTitleShow">{{systemName}}</span>
                    </el-header>
                    <el-main class="menuContainer">
                        <el-menu :width="menuWidth" :default-active="currentMenuId.toString()" class="menu"
                            :collapse="isMenuCollapse" @select="selectMenu" @open="openMenu" @close="closeMenu"
                            background-color="#006699" text-color="#fff" active-text-color="#ffd04b">
                            <el-submenu :index="item.id.toString()" :key="item.id.toString()"
                                v-for="item in moduleList">
                                <template slot="title">
                                    <i :class="item.icon"></i>
                                    <span style="margin-left:0.5em;">{{item.name}}</span>
                                </template>
                                <el-menu-item :index="item1.id.toString()" :key="item1.id"
                                    v-for="item1 in item.children">
                                    <i :class="item1.icon"></i>
                                    <span style="margin-left:0.5em">{{item1.name}}</span>
                                </el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </el-main>
                </el-container>
            </el-aside>
            <el-container class="content">
                <el-header class="header" height="10vh">
                    <!-- <i v-show="userInfo.userId=='system'" class="el-icon-coordinate"></i>
                    <span v-show="userInfo.userId=='system'" class="companyName">{{userInfo.companyName}}</span> -->
                    <!-- <i class="el-icon-user"></i>
                    <span class="userName">{{userInfo.name}}</span> -->
                    <!-- <el-button size="mini" icon="el-icon-switch-button" @click="exit" class="exit" round>退出</el-button>
                     -->
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            管理中心<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-key" command="updatePassword">修改密码</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-back" command="exit">退出登陆</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-header>
                <el-main class="route" height="91vh">
                    <el-tabs v-model="currentTabName" type="card" @tab-click="clickTable" closable
                        @tab-remove="removeTab">
                        <el-tab-pane v-for="item in tabList" :key="item.id" :label="item.name" :name="item.name">
                        </el-tab-pane>
                    </el-tabs>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
        <el-dialog :title="dialogWindowTitle" :visible.sync="updatePasswordViewVisible" width="30%">
            <UpdatePasswordView ref="UpdatePasswordView" :key="dialogWindowKey" @close="updatePasswordViewClose">
            </UpdatePasswordView>
        </el-dialog>
    </div>
</template>
<script>
    import UpdatePasswordView from './UpdatePasswordView.vue';
    import UserService from '../service/systemManage/UserService'
    import RoleService from '../service/systemManage/RoleService';
    import DictionaryService from '../service/systemManage/DictionaryService';
    export default {
        name: '',
        data() {
            return {
                isLogoShow: true,
                isTitleShow: true,
                isMenuCollapse: false,
                menuWidth: "18vw",
                moduleList: [],
                tabIndex: 0,
                editableTabs: [],
                routers: [],
                userInfo: {},
                tabList: [],
                currentMenuId: "",
                currentMenuName: "",
                currentTabId: "",
                currentTabName: "",
                dialogWindowTitle: '',
                updatePasswordViewVisible: false,
                dialogWindowKey: '',
                systemName: ''
            }
        },
        mounted() {
            //获取系统信息
            this.getSystemInfo();
            // //初始化
            this.init();
        },
        methods: {
            //获取系统信息
            getSystemInfo() {
                var paras = {
                    pageIndex: 1,
                    pageSize: 1000,
                    categorySN: "systemInfo"
                }
                DictionaryService.getItemList(paras, (response) => {
                    var list = response.list;
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].flag == "name") {
                            this.systemName = list[i].data;
                        }
                    }
                })
            },
            // //初始化
            init() {
                var token = localStorage.getItem("access_token");
                if (token == null || token == "") {
                    this.$router.replace("/login");
                } else {
                    //token是否过期
                    if ((new Date(localStorage.getItem("token_expiration"))).getTime() < (new Date()).getTime()) {
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("token_expiration");
                        localStorage.removeItem("departmentSN");
                        this.$router.replace("/login");
                    } else {
                        this.$router.replace("/index/content/mainscreen/default");
                        // //获取用户信息
                        this.getUserInfo();
                    }
                }
            },
            //获取用户信息
            getUserInfo() {
                UserService.getUserInfo(null, (response) => {
                    this.userInfo = response.data;
                    //获取角色模块权限
                    this.getModuleList(this.userInfo.roleSN);
                })
            },
            //获取角色模块权限
            getModuleList(roleSN) {
                RoleService.getModuleRight({
                    "roleSN": roleSN,
                    "typeSN": "rImS1646621731783"
                }, (response) => {
                    this.moduleList = response.list;
                })
            },
            //选择菜单
            selectMenu(id) {
                var module = this.getModuleById(id);
                this.currentMenuId = module.id;
                this.currentMenuName = module.name;
                this.currentTabId = module.id;
                this.currentTabName = module.name;
                //是否存在tab
                let isExistTab = false;
                this.tabList.forEach((item) => {
                    if (item.id == id) {
                        isExistTab = true;
                    }
                });
                if (isExistTab == true) {
                    this.$router.replace("/index/content" + module.url)
                    return;
                }
                //添加tab
                this.addTab(id, module.name);
            },
            //获取模块
            getModuleById(id) {
                var module = null;
                this.moduleList.forEach(item => {
                    if (item.children != null) {
                        item.children.forEach(subItem => {
                            if (subItem.id == id) {
                                module = subItem;
                                return module;
                            }
                        });
                    }
                });
                return module;
            },
            //通过名称获取模块
            getModuleByName(name) {
                var module = null;
                this.moduleList.forEach(item => {
                    if (item.children != null) {
                        item.children.forEach(subItem => {
                            if (subItem.name == name) {
                                module = subItem;
                                return module;
                            }
                        });
                    }
                });
                return module;
            },
            //展开菜单
            openMenu(index) {},
            //关闭菜单
            closeMenu(index) {

            },
            //点击tab标签
            clickTable(obj) {
                var name = obj.name;
                var module = this.getModuleByName(name);
                this.currentMenuId = module.id;
                this.currentMenuName = module.name;
                this.$router.replace("/index/content" + module.url);

            },
            //添加tab标签
            addTab(id, name) {
                this.tabList.push({
                    name: name,
                    id: id
                });
                var module = this.getModuleById(id);
                this.currentTabId = id,
                    this.currentMenuName = name;
                this.$router.replace("/index/content" + module.url);
            },
            //移除tab标签
            removeTab(tabName) {
                //获取tab的索引
                var tabIndex = 0;
                for (var i = 0; i < this.tabList.length; i++) {
                    if (tabName == this.tabList[i].name) {
                        break;
                    }
                    tabIndex += 1;
                }
                this.tabList.splice(tabIndex, 1);
                if (tabName != this.currentTabName) {
                    return;
                } else {
                    if (this.tabList.length == 0) {
                        this.$router.replace("/index/content/mainscreen/default");
                        return;
                    }
                    this.currentTabId = this.tabList[this.tabList.length - 1].id;
                    this.currentTabName = this.tabList[this.tabList.length - 1].name;
                    var module = this.getModuleById(this.currentTabId);
                    this.$router.replace("/index/content" + module.url);
                }
            },
            handleCommand(command) {
                switch (command) {
                    //修改密码
                    case "updatePassword": {
                        this.updatePassword();
                        break;
                    }
                    //退出出登陆
                    case "exit": {
                        this.exit();
                        break;
                    }
                }
            },
            //修改密码
            updatePassword() {
                this.dialogWindowTitle = "修改密码";
                this.dialogWindowKey = (new Date()).getTime();
                this.updatePasswordViewVisible = true;
            },
            //修改密码视图关闭
            updatePasswordViewClose() {
                this.updatePasswordViewVisible = false;
            },
            //退出
            exit() {
                this.$confirm("您确定退出登陆吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    localStorage.removeItem("access_token");
                    this.$router.replace("/login");
                }).catch(() => {})
            }
        },
        computed: {
            key() {
                return this.$route.path + Math.random();
            }
        },
        components: {
            UpdatePasswordView
        }
    }
</script>

<style scoped>
    .container {
        height: 100vh;
    }

    .aside {
        background-color: #0099CC;
        overflow: hidden;
    }

    .aside .title {
        display: flex;
        background-color: #0099CC;
        color: whitesmoke;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: "黑体";
    }

    .title span {
        padding: 0.5em;
        font-size: 1.2em;
        font-weight: bold;
    }

    .title img {
        width: 36px;
        height: 36px;
    }

    .header {
        display: flex;
        /* background: rgb(244, 244, 245); */
        background-color: #0099CC;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .header .companyName {
        color: #409EFF;
    }

    .header i {
        margin-left: 1em;
    }

    .header .userName {
        margin-right: 1rem;
        color: white
    }

    .header .exit {
        margin-left: 1em;
    }

    .menuContainer {
        margin: 0px;
        padding: 0px;
        border-radius: 0.5vw;
    }

    .menu {
        padding: 0px;
        margin: 0px;
        height: 90vh;
        min-width: 3vw;
        border-radius: 1vh;
    }

    .el-submenu {
        background-color: red;
    }

    .route {
        margin: 0px;
        padding: 0;
        overflow: hidden;
        position: relative;
        /* background-color: rgb(248, 248, 248);  */

    }

    .el-dropdown-link {
        cursor: pointer;
        color: white;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }
</style>