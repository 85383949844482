<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form :model="equipmentInfo" ref="equipmentInfo" :rules="rules" label-width="150px" size="small">
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="设备类型:" prop="equipmentTypeSN">
                                <el-select v-model="equipmentInfo.equipmentTypeSN" filterable placeholder="请选择">
                                    <el-option v-for="item in equipmentTypeList" :key="item.sn" :label="item.name"
                                        :value="item.sn">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="区域:">
                                <el-select v-model="equipmentInfo.regionSN" filterable placeholder="请选择">
                                    <el-option v-for="item in regionList" :key="item.sn" :label="item.name"
                                        :value="item.sn">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="名称:" prop="name">
                                <el-input v-model.trim="equipmentInfo.name" placeholder="请输入名称"></el-input>
                            </el-form-item>

                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="规格/型号:">
                                <el-input v-model.trim="equipmentInfo.style" placeholder="请输规格/型号"></el-input>
                            </el-form-item>

                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="MAC地址/序列号:">
                                <el-input v-model.trim="equipmentInfo.mac" placeholder="请输入MAC地址/序列号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="访问地址:">
                                <el-input v-model.trim="equipmentInfo.url" placeholder="请输入访问地址"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="IP地址:">
                                <el-input  v-model.trim="equipmentInfo.ip" placeholder="请输入IP地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="端口:">
                                <el-input v-model.trim="equipmentInfo.port" placeholder="请输入端口"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="用户名:">
                                <el-input v-model.trim="equipmentInfo.userName" placeholder="请输入用户名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :offset="0">
                            <el-form-item label="密码:">
                                <el-input  type="password" v-model.trim="equipmentInfo.password" placeholder="请输入密码"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <!-- <el-col :span="12" :offset="0">
                            <el-form-item label="厂家:">
                                <el-input readonly v-model.trim="equipmentInfo.supplierName" placeholder="请输入供应商">
                                </el-input>
                            </el-form-item>
                        </el-col> -->
                        <!-- <el-col :span="12" :offset="0">
                            <el-form-item label="管理人:" prop="managerSN">
                                <el-select v-model="equipmentInfo.managerSN" filterable placeholder="请选择">
                                    <el-option v-for="item in managerList" :key="item.userId" :label="item.name"
                                        :value="item.userId">
                                        <span style="float: left">{{ item.departmentName }}</span>
                                        <span
                                            style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> -->
                        <el-col :span="12" :offset="0">
                            <el-form-item label="是否在线:">
                                <el-switch v-model="equipmentInfo.status" :active-value="1" :inactive-value="0"
                                    active-text="" inactive-text="" disabled>
                                </el-switch>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </el-form>
            </el-main>
            <el-footer height="">
                <el-button type="primary" @click="submit">提交</el-button>
                <el-button @click="close">关闭</el-button>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import EquipmentInfoService from '../../../service/equipment/EquipmentInfoService';
    import RegionService from '../../../service/region/RegionService';
    import UserService from '../../../service/systemManage/UserService';
    export default {
        data() {
            return {
                "equipmentTypeList": [],
                "regionList": [],
                "supplierList": [],
                "equipmentInfo": {
                    "status": 0
                },
                managerList: [],
                rules: {
                    equipmentTypeSN: {
                        required: true,
                        message: '设备类型不能为空',
                        trigger: 'blur'
                    },
                    regionSN: {
                        required: true,
                        message: '区域不能为空',
                        trigger: 'blur'
                    },
                    name: {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    },
                    supplierSN: {
                        required: true,
                        message: '厂家不能为空',
                        trigger: 'blur'
                    },
                    managerSN: {
                        required: true,
                        message: '管理人不能为空',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted() {
            //获取设备类型列表
            this.getEquipmentTypeList();
            //获取区域列表
            this.getRegionList();
            //获取厂家列表
            this.getSupplierList();
            //获取管理人列表
            this.getManagerList();
        },
        methods: {
            //获取设备类型列表
            getEquipmentTypeList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "equipmentType",
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.equipmentTypeList = data.list;
                })
            },
            //获取区域列表
            getRegionList() {
                var paras = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                RegionService.getList(paras, (response) => {
                    this.regionList = response.list;
                })
            },
            //获取厂家列表
            getSupplierList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "supplier",
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.supplierList = data.list;
                })
            },
            //获取管理人列表
            getManagerList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                UserService.getList(params, (response) => {
                    this.managerList = response.list;
                })
            },
            //提交
            submit() {
                this.$refs.equipmentInfo.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.equipmentInfo.id == null) {
                                this.add();
                            } else {
                                this.update();
                            }
                        }).catch(() => {})
                    }
                });
            },
            //新增
            add() {
                EquipmentInfoService.add(this.equipmentInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                EquipmentInfoService.update(this.equipmentInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style scope>
</style>