<template>
    <div>
        <el-container>
            <el-main height="">
                <el-tabs v-model="activeName" @tab-click="tabClick">
                    <el-tab-pane label="工单信息" name="workOrderInfo">
                        <el-form :model="workOrderInfo" ref="workOrderInfo" :rules="rules" label-width="150px"
                            size="small">
                            <el-row>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="客户:" prop="customerSN">
                                        <el-select v-model="workOrderInfo.customerSN" filterable placeholder="请选择">
                                            <el-option v-for="item in customerInfoList" :key="item.sn"
                                                :label="item.name" :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="工单类型:" prop="typeSN">
                                        <el-select v-model="workOrderInfo.typeSN" filterable placeholder="请选择">
                                            <el-option v-for="item in workOrderTypeList" :key="item.sn"
                                                :label="item.name" :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="设备类型:" prop="equipmentTypeSN">
                                        <el-select v-model="workOrderInfo.equipmentTypeSN" filterable placeholder="请选择">
                                            <el-option v-for="item in equipmentTypeList" :key="item.sn"
                                                :label="item.name" :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="故障类型:" prop="faultTypeSN">
                                        <el-select v-model="workOrderInfo.faultTypeSN" filterable placeholder="请选择">
                                            <el-option v-for="item in faultTypeList" :key="item.sn" :label="item.name"
                                                :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="保修状态:" prop="repairStatusSN">
                                        <el-select v-model="workOrderInfo.repairStatusSN" filterable placeholder="请选择">
                                            <el-option v-for="item in repairStatusList" :key="item.sn"
                                                :label="item.name" :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="紧急程度:" prop="levelSN">
                                        <el-select v-model="workOrderInfo.levelSN" filterable placeholder="请选择">
                                            <el-option v-for="item in levelList" :key="item.sn" :label="item.name"
                                                :value="item.sn">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="0">
                                    <el-form-item label="故障描述:">
                                        <el-input type="textarea" :rows="4" placeholder="请输入故障描述"
                                            v-model="workOrderInfo.description">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="24" :offset="0">
                                    <el-form-item label="备注:">
                                        <el-input type="textarea" :rows="4" placeholder="请输入备注"
                                            v-model="workOrderInfo.remark">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
							<el-row v-if="action!='add'">
							    <el-col :span="24" :offset="0">
							        <el-form-item label="售后回访:">
							            <el-input type="textarea" :rows="4" placeholder="请输入售后回访情况"
							                v-model="workOrderInfo.followUp">
							            </el-input>
							        </el-form-item>
							    </el-col>
							</el-row>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane v-if="action!='add'" label="联系方式" name="customerInfo">
                        <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false"
                            size="normal">
                            <el-row style="width:80%">
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="联系人：">
                                        <el-input size="small" readonly v-model="customerInfo.contacts"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="手机号：">
                                        <el-input size="small" readonly v-model="customerInfo.mobile"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :offset="0"></el-col>
                            </el-row>
                            <el-row style="width:80%">
                                <el-col :span="24" :offset="0">
                                    <el-form-item label="地址">
                                        <el-input size="small" readonly v-model="customerInfo.address"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane v-if="workOrderInfo.statusSN=='KByl1646200696391'||this.workOrderInfo.statusSN == 'Bgjm1646380441503'" label="处理情况" name="doCase">
                        <el-form :model="form" ref="form" :rules="rules" label-width="120px" :inline="false"
                            size="normal">
                            <el-row style="width:80%">
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="处理方式：">
                                        <el-input size="small" readonly v-model="workOrderDoCase.doWayName"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :offset="0">
                                    <el-form-item label="协助人：">
                                        <el-input size="small" readonly v-model="workOrderDoCase.helpMan"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" :offset="0"></el-col>
                            </el-row>
                            <el-row style="width:80%">
                                <el-col :span="24" :offset="0">
                                    <el-form-item label="情况说明">
                                        <el-input type="textarea" readonly :rows="4"
                                            v-model="workOrderDoCase.description">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row style="width:80%">
                                <el-col :span="24" :offset="0">
                                    <el-form-item label="现场照片">
                                        <div class="block" v-for="item in workOrderPicList" :key="item.id">
                                            <el-image :src="iconPath(item.filePath)" :fit="contain">
                                            </el-image>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane v-if="workOrderInfo.statusSN=='Bgjm1646380441503'" label="签收信息" name="checkInfo">
                        <el-form :model="form" ref="form" :rules="rules" label-width="120px" :inline="false"
                            size="normal">
                            <el-row style="width:80%">
                                <el-col :span="24" :offset="0">
                                    <el-form-item label="客户签名：">
                                        <img style="width:150px;height:100px" :src="checkInfo.sign">
                                        <!-- <el-input size="small" readonly v-model="customerInfo.contacts"></el-input> -->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row style="width:80%">
                                <el-col :span="24" :offset="0">
                                    <el-form-item label="签收时间：">
                                        <span>{{checkInfo.createTime}}</span>

                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </el-main>
            <el-footer height="">
                <el-button type="primary" v-if="ifShowSubmit" @click="submit">提交</el-button>
                <el-button @click="close">关闭</el-button>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
    import DictionaryService from '../../service/systemManage/DictionaryService';
    import CustomerInfoService from '../../service/customer/CustomerInfoService';
    import WorkOrderService from '../../service/WorkOrder/WorkOrderService';
    import ServerConfig from '../../config/ServerConfig';
    export default {
        data() {
            return {
                activeName: 'workOrderInfo',
                workOrderInfo: JSON.parse(localStorage.getItem("workOrderInfo")),
                customerInfo: {},
                checkInfo: {},
                workOrderDoCase: {},
                customerInfoList: [],
                workOrderTypeList: [],
                equipmentTypeList: [],
                faultTypeList: [],
                repairStatusList: [],
                levelList: [],
                workOrderPicList: [],
                rules: {
                    customerSN: {
                        required: true,
                        message: '客户不能为空',
                        trigger: 'blur'
                    },
                    typeSN: {
                        required: true,
                        message: '工单类型不能为共',
                        trigger: 'blur'
                    },
                    equipmentTypeSN: {
                        required: true,
                        message: '设备类型不能为空',
                        trigger: 'blur'
                    },
                    faultTypeSN: {
                        required: true,
                        message: '故障类型不能为空',
                        trigger: 'blur'
                    },
                    repairStatusSN: {
                        required: true,
                        message: '保修状态不能为空',
                        trigger: 'blur'
                    },
                    levelSN: {
                        required: true,
                        message: '紧急程度不能为空',
                        trigger: 'blur'
                    }
                },
                ifShowSubmit: true,
                action: localStorage.getItem("action")
            }
        },
        computed: {
            iconPath() {
                return function (path) {
                    return ServerConfig.fileServer + path;
                }
            }
        },
        mounted() {
            //初始化
            this.init();
            //获取客户列表
            this.getCustomerInfoList();
            //获取工单类型
            this.getWorkOrderTypeList();
            //获取设备类型
            this.getEquipmentTypeList();
            //获取故障类型
            this.getFaultTypeList();
            //获取保修状态列表
            this.getRepairStatusList();
            //获取紧急程度列表
            this.getLevelList();
            //获取客户信息
            this.getCustomerInfo();
            //获取处理情况
            if (this.workOrderInfo.statusSN == 'KByl1646200696391' || this.workOrderInfo.statusSN == 'Bgjm1646380441503') {
                this.getDoCase();
                this.getPicList();
            }
            //获取签收信息
            if (this.workOrderInfo.statusSN == 'Bgjm1646380441503') {
                this.getCheckInfo();
            }
        },
        methods: {
            init() {
                if (this.action == "detail")
                    this.ifShowSubmit = false;
            },
            tabClick(tab, event) {
                // this.activeName=tab.paneName;
            },
            //获取客户信息
            getCustomerInfo() {
                var paras = {
                    sn: this.workOrderInfo.customerSN
                }
                CustomerInfoService.getBySN(paras, (response) => {
                    this.customerInfo = response.data;
                })
            },
            //获取签收信息
            getCheckInfo() {
                var paras = {
                    sn: this.workOrderInfo.sn
                }
                WorkOrderService.getCheckInfo(paras, (response) => {
                    this.checkInfo = response.data;
                })
            },
            //获取处理情况
            getDoCase() {
                var paras = {
                    workOrderSN: this.workOrderInfo.sn
                }
                WorkOrderService.getDoCase(paras, (response) => {
                    this.workOrderDoCase = response.data;
                })
            },
            //获取照片
            getPicList() {
                var paras = {
                    workOrderSN: this.workOrderInfo.sn
                }
                WorkOrderService.getPicList(paras, (response) => {
                    this.workOrderPicList = response.list;
                    // this.workOrderPicList.forEach((item) => {
                    //     // 创建对象
                    //     var img = new Image();
                    //     // 改变图片的src
                    //     img.src =ServerConfig.fileServer+item.filePath;
                    //     alert(img.width);
                    //     alert(img.height);
                    // })
                })
            },
            //获取客户列表
            getCustomerInfoList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                CustomerInfoService.getList(params, (response) => {
                    if (response.code == 0) {
                        this.customerInfoList = response.list;
                    }
                })
            },
            //获取工单类型列表
            getWorkOrderTypeList() {
                var paras = {
                    pageIndex: 1,
                    pageSize: 1000,
                    categorySN: "workOrderType",
                    "status": 1
                }
                DictionaryService.getItemList(paras, (response) => {
                    this.workOrderTypeList = response.list;
                })
            },
            //获取设备类型列表
            getEquipmentTypeList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "equipmentType",
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.equipmentTypeList = data.list;
                })
            },
            //获取故障类型列表
            getFaultTypeList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "faultType",
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.faultTypeList = data.list;
                })
            },
            //获取保修状态列表
            getRepairStatusList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "repairStatus",
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.repairStatusList = data.list;
                })
            },
            //获取紧急程度
            getLevelList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "level",
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.levelList = data.list;
                })
            },
            //提交
            submit() {
                this.$refs.workOrderInfo.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.action == "add") {
                                this.add();
                            } else {
                                this.update();
                            }
                        }).catch(() => {})
                    }
                });
            },
            //新增
            add() {
                WorkOrderService.add(this.workOrderInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                WorkOrderService.update(this.workOrderInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style scope>
    .block {
        display: inline-block;
        margin-left: 1rem;
    }
</style>