<!--
 * @Descripttion: 
 * @Author: zhujian
 * @Date: 2020-07-31 22:40:19
 * @LastEditors: zhujian
 * @LastEditTime: 2020-09-16 17:38:58
-->
<template>
    <div class="container">
        <header class="header">
            <div class="logo">
                <img v-if="ifShowLogo" src="../assets/image/logo.png" alt="">
            </div>
            <div class="title"><span>{{systemName}}</span></div>
        </header>
        <main class="main">
            <div class="login">
                <div class="loginTitle">
                    <span>用户登陆</span>
                </div>
                <div class="loginInput">
                    <el-input placeholder="请输入用户名" class="inputItem" v-model.trim="userId">
                        <i slot="prefix" class="el-input__icon el-icon-user"></i>
                    </el-input>
                    <el-input placeholder="请输入密码" class="inputItem" v-model.trim="password" show-password
                        @keyup.enter.native="login">
                        <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
                    </el-input>
                    <div class="lgoingButon"></div>
                    <el-button type="primary" class="loginButton" @click="login">登陆</el-button>
                </div>
            </div>
        </main>
        <footer class="footer">
            <label>{{copyRight}}</label>
        </footer>
    </div>
</template>
<script>
import DictionaryService from '../service/systemManage/DictionaryService';
    import UserService from '../service/systemManage/UserService';
    export default {
        name: '',
        data() {
            return {
                userId: localStorage.getItem("userId"),
                password: '',
                systemName: "",
                ifShowLogo:true,
                copyRight: ""
            }
        },
        mounted() {
            //获取系统信息
            this.getSystemInfo();
        },
        methods: {
            //获取系统信息
            getSystemInfo() {
                var paras = {
                    pageIndex: 1,
                    pageSize: 1000,
                    categorySN: "systemInfo"
                }
                DictionaryService.getItemList(paras, (response) => {
                    var list = response.list;
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].flag == "name") {
                            this.systemName = list[i].data;
                        }
                        if (list[i].flag == "copyRight") {
                            this.copyRight = list[i].data;
                        }
                    }
                })
            },
            //登陆
            login() {
                if (this.userId == '') {
                    this.$message({
                        message: "用户名不能为空",
                        type: "warning"
                    });
                    return;
                }
                if (this.password == '') {
                    this.$message({
                        message: "密码不能为空",
                        type: "warning"
                    });
                    return;
                }
                //登陆
                var obj = {
                    "userId": this.userId,
                    "password": this.password
                }
                UserService.login(obj, (data) => {
                  
                    // //保存tonke到localstorage
                    localStorage.setItem("access_token", data.access_token);
                    localStorage.setItem("token_expiration", data.token_expiration);
                    // //保存用户名到localstorage
                    localStorage.setItem("departmentSN", data.departmentSN);
                    localStorage.setItem("userId", this.userId);
                    this.loading = false;
                    //跳转到首页
                    this.$router.replace("/index");
                });
            }
        },
    }
</script>

<style scoped>
    .container {
        display: flex;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        justify-content: space-between;
    }

    .header {
        height: 10vh;
        background-color: white;
        display: flex;
    }

    .header .logo {
        width: 10vw;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }

    .logo img {
        width: 30px;
        height: 30px;
    }

    .header .title {
        display: flex;
        align-items: center;
        padding-left: 0.5em;
    }

    .title span {
        font-weight: bold;
        font-size: 1.2em;
    }

    .main {
        flex-grow: 1;
        background-image: url("../assets/image/login_bg.png");
        background-position: center;
        background-size: cover;
        position: relative;
    }

    .login {
        background-color: white;
        position: absolute;
        right: 5em;
        bottom: 5em;
        padding: 3em;
        border-radius: 5px;
    }

    .login .loginTitle {
        color: rgba(46, 152, 238, 0.87);
        font-weight: bold;
    }

    .inputItem {
        margin-top: 2em;
        display: block;
        width: 20em;
    }

    .loginButton {
        width: 100%;
        margin-top: 2em;
    }

    .footer {
        height: 10vh;
        background-color: white;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .footer label {
        align-content: center;
    }
</style>