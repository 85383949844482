<template>
    <div>
        <el-container>
            <el-header height="">
                <el-button size="mini" icon="el-icon-plus" round @click="newAccount">新增</el-button>
            </el-header>
            <el-main height="">
                <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                    <el-table-column prop="userId" label="用户ID">
                    </el-table-column>
                    <el-table-column prop="userName" label="姓名">
                    </el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" disabled />
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="400px">
                        <template slot-scope="scope">
                            <el-button size="mini" icon="el-icon-refresh" @click="resetPassword(scope.row.userId)"
                                round>重置密码
                            </el-button>
                            <el-button v-if="scope.row.status==1" size="mini" icon="el-icon-video-pause" @click="forbidAccount(scope.row.userId)"
                                round>禁用账号
                            </el-button>
                              <el-button v-if="scope.row.status==0" size="mini" icon="el-icon-video-pause" @click="enableAccount(scope.row.userId)"
                                round>启用账号
                            </el-button>
                            <el-button size="mini" icon="el-icon-delete" @click="deleteAccount(scope.row.userId)" round>
                                删除账号
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
            <el-footer height="">
                <div class="add" v-if="addViewVisible">
                    <el-row>
                        <el-col class="title" :span="24" :offset="0">
                            <span>{{title}}</span>
                        </el-col>
                    </el-row>
                    <div class="content">
                        <el-row>
                            <el-col :span="12" :offset="0">
                                <el-row>
                                    <el-col :span="5" :offset="0"><span class="required">*</span>用户名:</el-col>
                                    <el-col :span="19" :offset="0">
                                        <el-input size="mini" v-model.trim="account.userId" placeholder="请输入用户名">
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12" :offset="0">
                                <el-row>
                                    <el-col :span="5" :offset="0"><span class="required">*</span>密码:</el-col>
                                    <el-col :span="19" :offset="0">
                                        <el-input size="mini" v-model.trim="account.password" type="password"
                                            placeholder="请输入密码"></el-input>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :offset="0">
                                <el-row>
                                    <el-col :span="5" :offset="0"><span class="required">*</span>姓名:</el-col>
                                    <el-col :span="19" :offset="0">
                                        <el-input size="mini" v-model.trim="account.userName" placeholder="请输入姓名">
                                        </el-input>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12" :offset="0">
                                <el-row>
                                    <el-col :span="6" :offset="0">状态:</el-col>
                                    <el-col :span="18" :offset="0">
                                        <el-switch v-model.trim="account.status" :active-value="1" :inactive-value="0"
                                            active-text="启用" inactive-text="禁用">
                                        </el-switch>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row class="footer">
                        <el-col :span="24" :offset="0">
                            <el-button type="primary" @click="submit">提交</el-button>
                            <el-button @click="close">关闭</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
    import AccountService from '../../../service/customer/AccountService';
    import MD5 from 'md5';
    export default {
        data() {
            return {
                title: '新增用户',
                list: [],
                customerInfo: JSON.parse(localStorage.getItem("customerInfo")),
                account: {
                    "status": 1
                },
                addViewVisible: false,
                editFlag: true
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var paras = {
                    "customerSN": this.customerInfo.sn
                };
                AccountService.getList(paras, (response) => {
                    this.list = response.list;
                })
            },
            //新增账号
            newAccount() {
                this.title = "新增账号";
                this.account = {
                    "status": 1
                };
                this.addViewVisible = true;
            },
            //提交
            submit() {
                if (this.account.userId == null || this.account.userId == "") {
                    this.$message({
                        message: '请输入用户名',
                        type: 'warning'
                    });
                    return;
                }
                if (this.account.password == null || this.account.password == "") {
                    this.$message({
                        message: '请输入密码',
                        type: 'warning'
                    });
                    return;
                }
                if (this.account.userName == null || this.account.userName == "") {
                    this.$message({
                        message: '请输入姓名',
                        type: 'warning'
                    });
                    return;
                }
                this.$confirm("您确定要提交吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    this.account.customerSN = this.customerInfo.sn;
                    this.account.password = MD5(this.account.password);
                    AccountService.add(this.account, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.addViewVisible = false;
                        this.getList();
                    });
                })

            },
            //删除账号
            deleteAccount(userId) {
                this.$confirm("您确定要删除此账号吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        userId: userId
                    }
                    AccountService.delete(paras, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    });
                })
            },
            //重置密码
            resetPassword(userId) {
                this.$confirm("您确定要重置密码吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        userId: userId
                    }
                    AccountService.resetPassword(paras, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    });
                })
            },
            //禁止账号
            forbidAccount(userId) {
                this.$confirm("您确定要禁用此账号吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        userId: userId,
                        status: 0
                    }
                    AccountService.setStatus(paras, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    });
                })
            },
             //启用账号
            enableAccount(userId) {
                this.$confirm("您确定要启用此账号吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        userId: userId,
                        status: 1
                    }
                    AccountService.setStatus(paras, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    });
                })
            },
            //关闭
            close() {
                this.addViewVisible = false;
            }
        }
    }
</script>
<style scoped>
    .title {
        padding: 0.5rem;
        background-color: #F7F7F7;
    }

    .content {
        padding: 0.5rem;
    }

    .add {
        border: solid 1px #ccc;
    }

    .el-row {
        margin-bottom: 0.5rem;
    }

    .footer {
        padding: 0.5rem;
    }

    .required {
        color: red;
    }
</style>