import AxiosHelper from '../../util/AxiosHelper';
class RegionService{
    //新增
   static add(params,handle)
   {
     AxiosHelper.post("/region/regionInfo/add",params,handle);
   }
   //修改
   static update(params,handle)
   {
    AxiosHelper.post("/region/regionInfo/update",params,handle);
   }
   //获取列表
   static getList(params,handle)
   {
    AxiosHelper.get("/region/regionInfo/getList",params,handle);
   }
   //删除
   static delete(params,handle)
   {
    AxiosHelper.post("/region/regionInfo/delete",params,handle);
   }
}
export default RegionService