import AxiosHelper from '../../util/AxiosHelper';
class AlarmInfoService
{
    //新增
   static add(paras,handle)
   {
       AxiosHelper.post("/alarm/alarmInfo/add",paras,handle);
   }
   //获取列表
   static getList(paras,handle)
   {
       AxiosHelper.get("/alarm/alarmInfo/getList",paras,handle);
   }
   //恢复故障
   static resume(paras,handle)
   {
    AxiosHelper.post("/alarm/alarmInfo/updateStatus",paras,handle);
   }
   //获取完成率
   static getFinishRate(paras,handle)
   {
    AxiosHelper.post("/alarm/alarmInfo/getFinishRate",paras,handle);
   }
    //获取当天分类数量统计
    static getTodayCategoryCountStatistics(paras,handle)
    {
     AxiosHelper.get("/alarm/alarmInfo/getTodayCategoryCountStatistics",paras,handle);
    }
    //获取月分类数量统计
   static getMonthCategoryCountStatistics(paras,handle)
   {
    AxiosHelper.get("/alarm/alarmInfo/getMonthCategoryCountStatistics",paras,handle);
   }
}
export default AlarmInfoService;