<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form :model="region" ref="region" :rules="rules" label-width="100px" size="small">
                    <el-form-item label="名称:" prop="name">
                        <el-input v-model.trim="region.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="地图:">
                        <el-select v-model="value" v-model.trim="region.mapSN" filterable placeholder="请选择">
                            <el-option v-for="item in mapList" :key="item.sn" :label="item.name"
                                :value="item.sn">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-switch v-model="region.status" :active-value="1" :inactive-value="0" active-text=""
                            inactive-text="">
                        </el-switch>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit">提交</el-button>
                        <el-button @click="close">关闭</el-button>
                    </el-form-item>
                </el-form>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import RegionService from '../../service/region/RegionService';
    import MapInfoService from '../../service/mapManage/MapInfoService';
    export default {
        data() {
            return {
                mapList:[],
                "region":JSON.parse(sessionStorage.getItem("region")),
                rules: {
                    name: {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted() {
           //获取地图列表
           this.getMapList();
        },
        methods: {
            //获取地图列表
            getMapList()
            {
                var params={
                    pageIndex:1,
                    pageSize:1000,
                    status:1
                }
               MapInfoService.getList(params,(response)=>{
                   this.mapList=response.list;
               })
            },
            //提交
            submit() {
                this.$refs.region.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.region.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                RegionService.add(this.region, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                RegionService.update(this.region, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>