import RepairView from '../view/repair/RepairView';
import SupplierRepairView from '../view/repair/supplierRepair/ListView';
export default {
    path:'repair',
    component:RepairView,
    children:[
        {
            path:'supplierRepair',
            component:SupplierRepairView
        }
    ]
}