<template>
    <div>
        <el-container>
            <el-container>
                <el-header height="">
                    <label for="">名称:</label>
                    <el-input size="mini" v-model.trim="name" placeholder="请输入名称" class="inputSearch"></el-input>
                    <el-button size="mini" icon="el-icon-search" round @click="search">查询</el-button>
                    <el-button size="mini" icon="el-icon-plus" round @click="add">新增</el-button>
                </el-header>
                <el-main height="">
                    <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                        <el-table-column type="index" label="序号" width="80px">
                            <template slot-scope="scope">
                                {{scope.$index+1+(pageIndex-1)*pageSize}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="mapTypeName" label="地图类型" />
                        <el-table-column prop="name" label="地图名称" />

                        <el-table-column label="操作" width="360px">
                            <template slot-scope="scope">
                                <el-button size="mini" round icon="el-icon-edit" @click="update(scope.row)">编辑
                                </el-button>
                                <el-button size="mini" round icon="el-icon-delete" @click="remove(scope.row.sn)">删除
                                </el-button>
                                <el-button size="mini" round icon="el-icon-files" @click="document(scope.row)">地图文件
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-footer height="">
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-main>
            </el-container>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="40%">
            <NewMapInfoView ref="NewMapInfoView" :key="dialogWindowKey" @close="newMapInfoViewClose"
                @submit="newMapInfoViewSubmit"></NewMapInfoView>
        </el-dialog>
        <el-dialog :title="dialogWindowTitle" :visible.sync="documentViewVisible" width="60%">
            <DocumentView ref="DocumentView" :key="dialogWindowKey" @close="documentViewClose">
            </DocumentView>
        </el-dialog>
    </div>
</template>

<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import MapInfoService from '../../../service/mapManage/MapInfoService';
    import NewMapInfoView from '../../../view/mapManage/mapInfo/NewView';
    import DocumentView from '../../../view/mapManage/mapInfo/DocumentView';
    export default {
        data() {
            return {
                name: '',
                list: [],
                list: [],
                systemSN: '', //系统编号
                systemList: [{
                    "id": "",
                    "sn": "",
                    "name": "--全部--"
                }], //系统列表
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false,
                documentViewVisible: false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var params = {
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                MapInfoService.getList(params, (data) => {
                    this.list = data.list;
                    this.recordCount = data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增地图信息";
                sessionStorage.setItem("systemSN", this.systemSN);
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑地图信息";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
                this.$nextTick(() => {
                    this.$refs.NewMapInfoView.mapInfo = Object.assign({}, obj);
                });
            },
            //删除
            remove(sn) {
                this.$confirm("您确定要删除此数据吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var obj = {
                        "sn": sn
                    }
                    MapInfoService.delete(obj, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    })
                }).catch(() => {})
            },
            //查询
            search() {
                this.getList();
            },
            //新增地图信息视图关闭
            newMapInfoViewClose() {
                this.dialogWindowVisible = false;
            },
            documentViewClose() {
                this.documentViewVisible = false;
            },
            //资料库
            document(obj) {
                this.dialogWindowTitle = "地图文件";
                sessionStorage.setItem("mapSN", obj.sn);
                this.dialogWindowKey = (new Date()).getTime();
                this.documentViewVisible = true;
            },
            //新增地图信息视图提交
            newMapInfoViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.pageIndex=1;
                this.getList();
            }
        },
        components: {
            NewMapInfoView,
            DocumentView
        }


    }
</script>

<style scoped>
    .aside {
        border-right: solid 1px #F2F6FC;
    }

    .menu {
        padding-right: 1px;
        margin: 0px;
        height: 92vh;
        min-width: 3vw;

    }

    .systemType {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        background-color: lightblue;
        height: 30px;
        line-height: 30px;

    }
</style>