<template>
    <div>
        <el-form :model="member" ref="member" :rules="rules" label-width="100px" :inline="false" size="normal">
            <el-form-item label="姓名" prop="name">
                <el-input size="small" v-model.trim="member.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
                <el-input size="small" v-model.trim="member.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="电子邮件">
                <el-input size="small" v-model.trim="member.email" placeholder="请输入电子邮件"></el-input>
            </el-form-item>
            <el-form-item label="企业微信号">
                <el-input size="small" v-model.trim="member.userId" placeholder="请输入平台账号"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
                <el-button @click="close">取消</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>
<script>
    import MemberService from '../../../service/notice/MemberService';
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import SiteInfoService from '../../../service/site/SiteInfoService';
    export default {
        data() {
            return {
                noticeGroupSN: '',
                member: JSON.parse(sessionStorage.getItem("member")),
                supplierList: [],
                siteList:[],
                rules: {
                    name: {
                        required: true,
                        message: '姓名不能为空',
                        trigger: 'blur'
                    }
                }
            }
        },
        mounted() {
            //初始化
            this.init();
            //获取厂家列表
            this.getSupplierList();
            //获取站点列表
            this.getSitelist();

        },
        methods: {
            //初始化
            init() {
                this.noticeGroupSN = sessionStorage.getItem("groupSN");
            },
            //获取站点列表
            getSitelist()
            {
                var params = {
                    pageIndex: 1,
                    pageSize: 1000
                }
                SiteInfoService.getList(params, (response) => {
                    this.siteList = response.list;
                })
            },
            //获取厂家列表
            getSupplierList() {
                var params = {
                    "categorySN": "supplier",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    this.supplierList = data.list;
                })
            },
            //提交
            submit() {
                this.$refs.member.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.member.id == null) {
                                this.add();
                            } else {
                                this.update();
                            }
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                this.member.noticeGroupSN = sessionStorage.getItem("groupSN");
                MemberService.add(this.member, (response) => {
                    this.$emit("submit", response);
                })
            },
            //修改
            update() {
                MemberService.update(this.member, (response) => {
                    this.$emit("submit", response);
                })
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>