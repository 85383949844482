import AxiosHelper from '../../util/AxiosHelper';
class CustomerInfoService
{
    //新增
   static add(paras,handle)
   {
     AxiosHelper.post("/customer/customerInfo/add",paras,handle);
   }
   //修改
   static update(paras,handle)
   {
    AxiosHelper.post("/customer/customerInfo/update",paras,handle);
   }
   //删除
   static delete(paras,handle)
   {
    AxiosHelper.post("/customer/customerInfo/delete",paras,handle);
   }
   //获取列表
   static getList(paras,handle)
   {
    AxiosHelper.get("/customer/customerInfo/getList",paras,handle);
   }
   //通过编号获取
   static getBySN(paras,handle)
   {
    AxiosHelper.get("/customer/customerInfo/getBySN",paras,handle);
   }
}
export default CustomerInfoService;