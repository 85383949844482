<template>
    <div>
        <el-container>
            <el-container>
                <el-header>
                    <label for="">名称:</label>
                    <el-input size="mini" v-model.trim="name" placeholder="请输入名称" class="inputSearch"></el-input>
                    <el-button size="mini" icon="el-icon-search" round @click="search">查询</el-button>
                    <el-button size="mini" icon="el-icon-plus" round @click="add">新增</el-button>
                </el-header>
                <el-main>
                    <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                        <el-table-column type="index" label="序号" width="80px">
                            <template slot-scope="scope">
                                {{scope.$index+1+(pageIndex-1)*pageSize}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="名称">
                        </el-table-column>
                         <el-table-column label="状态" width="120px">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==1">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="240px">
                            <template slot-scope="scope">
                                <el-button size="mini" round icon="el-icon-edit" @click="update(scope.row)">编辑
                                </el-button>
                                <el-button size="mini" round icon="el-icon-delete" @click="remove(scope.row.sn)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-footer>
                        <el-row>
                            <el-col :span="24" align="right">
                                <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                    :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                                </el-pagination>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-main>
                
            </el-container>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="40%">
            <NewRegionView ref="NewRegionView" :key="dialogWindowKey" @close="newRegionViewClose"
                @submit="newRegionViewSubmit"></NewRegionView>
        </el-dialog>
    </div>
</template>

<script>
     import RegionService from '../../service/region/RegionService';
    //新增视图
    import NewRegionView from '../../view/region/NewView'
    export default {
        data() {
            return {
                name: '',
                systemSN: "", //系统编号
                status: '',
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var params = {
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                RegionService.getList(params, (data) => {
                    this.list = data.list;
                    this.recordCount = data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增区域";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("region",'{"status":1}')
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑区域";
                this.dialogWindowKey = (new Date()).getTime();
                 sessionStorage.setItem("region",JSON.stringify(obj));
                this.dialogWindowVisible = true; 
            },
            //删除区域
            remove(sn) {
                this.$confirm("您确定要删除此信息吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var obj = {
                        "sn": sn
                    }
                    RegionService.delete(obj, (response) => {
                        this.$message({
                            message: response.msg,
                            type: 'success'
                        })
                        this.getList();
                    })
                }).catch(() => {})
            },
            //查询
            search() {
                this.getList();
            },
            //新增区域视图关闭
            newRegionViewClose() {
                this.dialogWindowVisible = false;
            },
            //新增区域视图提交
            newRegionViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            }
        },
        components: {
            NewRegionView
        }
    }
</script>

<style  scoped>
.aside
{
 border-right: solid 1px #F2F6FC;
}
 .menu {
        padding-right: 1px;
        margin: 0px;
        height: 92vh;
        min-width: 3vw;
       
    }
  .systemType
    {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        background-color:lightblue;
        height: 30px;
        line-height: 30px;

    }
</style>