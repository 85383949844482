import NoticeView from '../view/notice/NoticeView';
import NoticeInterfaceView from '../view/notice/noticeInterface/ListView';
import NoticePolicyView from '../view/notice/noticePolicy/ListView';
import MemberView from '../view/notice/member/ListView';
import NoticeTemplateView from '../view/notice/noticeTemplate/ListView';
export default
{
    path:'notice',
    component:NoticeView,
    children:[
        {
            path:'noticeInterface',
            component:NoticeInterfaceView
        },
        {
            path:'noticePolicy',
            component:NoticePolicyView
        },
        {
            path:'member',
            component:MemberView
        },
        {
            path:'noticeTemplate',
            component:NoticeTemplateView
        }
    ]
}
