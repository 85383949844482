<template>
    <div>
        <el-container>
            <el-main height="50vh">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="基本信息" name="basicInfo">
                        <el-form :model="customerInfo" ref="customerInfo" :rules="rules" label-width="100px"
                            size="small">
                            <el-form-item label="名称:" prop="name">
                                <el-input v-model.trim="customerInfo.name" placeholder="请输入名称"></el-input>
                            </el-form-item>
                            <el-form-item label="地址:">
                                <el-input v-model.trim="customerInfo.address" placeholder="请输入地址"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人:">
                                <el-input v-model.trim="customerInfo.contacts" placeholder="请输入联系人"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号码:">
                                <el-input v-model.trim="customerInfo.mobile" placeholder="请输入手机号码"></el-input>
                            </el-form-item>
                            <el-form-item label="状态:">
                                <el-switch v-model="customerInfo.status" :active-value="1" :inactive-value="0"
                                    active-text="启用" inactive-text="禁用">
                                </el-switch>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submit">提交</el-button>
                                <el-button @click="close">关闭</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="其他信息" name="second">
                    </el-tab-pane>
                </el-tabs>
            </el-main>
        </el-container>

    </div>
</template>

<script>
    import CustomerInfoService from '../../../service/customer/CustomerInfoService';
    export default {
        data() {
            return {
                "activeName": "basicInfo",
                "customerInfo": JSON.parse(sessionStorage.getItem("customerInfo")),
                "accountInfo":[],
                rules: {
                    name: {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted() {

        },
        methods: {
            handleClick(obj)
            {
              
            },
            //提交
            submit() {
                this.$refs.customerInfo.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.customerInfo.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                CustomerInfoService.add(this.customerInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                CustomerInfoService.update(this.customerInfo, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>