<template>
    <div>
        <el-container>
            <el-main height="">
                <el-form :model="module" ref="module" :rules="rules" label-width="100px" size="small">
                    <el-form-item label="模块类型:" prop="typeSN">
                        <el-select v-model="module.typeSN"  placeholder="请选择">
                            <el-option v-for="item in typeList" :key="item.id" :label="item.name"
                                :value="item.sn">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="名称:" prop="name">
                        <el-input v-model.trim="module.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item label="URL:" prop="url">
                        <el-input v-model.trim="module.url" placeholder="请输入URL"></el-input>
                    </el-form-item>
                    <el-form-item label="上级模块:">
                        <el-select v-model="module.parentSN" filterable placeholder="请选择">
                            <el-option v-for="item in moduleList" :key="item.id" :label="item.name" :value="item.sn">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="图标:">
                        <el-input v-model.trim="module.icon" placeholder="请输入图标名"></el-input>
                    </el-form-item>
                    <el-form-item label="排序号:">
                        <el-input-number v-model="module.seq" controls-position="right" :min="1"></el-input-number>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-switch v-model="module.status" :active-value="1" :inactive-value="0" active-text="启用"
                            inactive-text="禁用">
                        </el-switch>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit">提交</el-button>
                        <el-button @click="close">关闭</el-button>
                    </el-form-item>
                </el-form>

            </el-main>

        </el-container>

    </div>
</template>

<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import ModuleService from '../../../service/systemManage/ModuleService';
    export default {
        data() {
            return {
                 "module": {
                    "seq": 1,
                    "status": 1
                },
                typeList: [],
                "moduleList": [],
                rules: {
                    typeSN: {
                        required: true,
                        message: '请选择模块类型',
                        trigger: 'blur'
                    },
                    name: {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    },
                    url: {
                        required: true,
                        message: 'URL不能为空',
                        trigger: 'blur'
                    },
                    parentSN: {
                        required: true,
                        message: '请选择上级模块',
                        trigger: 'blur'
                    }
                },
            }
        },
        mounted() {
            //获取模块类型列表
            this.getTypeList();
            //获取上级模块列表
            this.getModuleList();
        },
        methods: {
            //获取模块类型列表
            getTypeList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "moduleType",
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    this.typeList = response.list;
                })
            },
            //获取上级模块列表
            getModuleList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1,
                    "parentSN": ""
                }
                ModuleService.getParentList(null, (response) => {
                    this.moduleList = response.list;
                })
            },
            //提交
            submit() {
                this.$refs.module.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            if (this.module.id == null)
                                this.add();
                            else
                                this.update();
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                ModuleService.add(this.module, (data) => {
                    this.$emit("submit", data);
                });
            },
            //修改
            update() {
                ModuleService.update(this.module, (data) => {
                    this.$emit("submit", data);
                });
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>