<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">名称:</label>
                <el-input v-model.trim="name" placeholder="请输入名称" class="inputSearch" size="mini"></el-input>
                <el-button @click="search" size="mini" icon="el-icon-search" round>查询</el-button>
                <el-button @click="add" size="mini" icon="el-icon-plus" round>新增</el-button>
            </el-header>
            <el-main height="">
                <el-table size="small" :data="list" border stripe header-cell-class-name="tableHeader">
                    <el-table-column type="index" label="序号" width="80px">
                        <template slot-scope="scope">
                            {{(scope.$index+1)}}
                        </template>
                    </el-table-column>
                     <el-table-column prop="typeName" label="类型">
                    </el-table-column>
                    <el-table-column prop="icon" label="图标" width="80px">
                        <template slot-scope="scope">
                            <i :class="scope.row.icon"></i>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <el-table-column prop="url" label="URL" width="360px">
                    </el-table-column>
                    <el-table-column prop="seq" label="排序号" width="80px">
                    </el-table-column>
                    <el-table-column prop="parentName" label="上级模块" width="120px">
                    </el-table-column>
                    <el-table-column label="状态" width="80px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==1">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120px">
                        <template slot-scope="scope">
                            <el-dropdown>
                                <el-button size="small">
                                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item divided=true><span @click="update(scope.row)">修改</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item divided=true><span @click="remove(scope.row)">删除</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item divided=true><span @click="showIconView(scope.row)">图标</span>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer height="">
                    <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="30%">
            <NewModuleView ref="NewModuleView" :key="dialogWindowKey" @close="newModuleViewClose"
                @submit="newModuleViewSubmit">
            </NewModuleView>
        </el-dialog>
        <el-dialog :title="dialogWindowTitle" :visible.sync="iconViewVisible" width="50%">
            <IconView ref="IconView" :key="dialogWindowKey" @close="iconViewClose">
            </IconView>
        </el-dialog>
    </div>
</template>

<script>
    import ModuleService from '../../../service/systemManage/ModuleService';
    import NewModuleView from '../../../view/systemManage/module/NewView';
    import IconView from '../../../view/systemManage/module/IconView.vue';
    export default {
        data() {
            return {
                name: '',
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false,
                iconViewVisible: false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var params = {
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                ModuleService.getList(params, (data) => {
                    this.list = data.list;
                    this.recordCount = data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增模块";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "修改模块";
                this.dialogWindowKey = (new Date()).getTime();
                this.$nextTick(() => {
                    this.$refs.NewModuleView.module = Object.assign({}, obj);
                });
                this.dialogWindowVisible = true;
            },
            //删除
            remove(obj) {
                this.$confirm("您确定要删除此模块吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        sn: obj.sn
                    }
                    ModuleService.delete(paras, (response) => {
                        this.$message({
                            showClose: true,
                            message: response.msg,
                            type: 'success'
                        });
                        this.getList();
                    })
                }).catch(() => {})
            },
            //查询
            search() {
                this.getList();
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增模块视图关闭
            newModuleViewClose() {
                this.dialogWindowVisible = false;
            },
            //新增模块视图提交
            newModuleViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },

            //显示图标视图
            showIconView(obj) {
                this.dialogWindowTitle = "图标管理";
                sessionStorage.setItem("sn", obj.sn);
                this.dialogWindowKey = (new Date()).getTime();
                this.iconViewVisible = true;
            },
            //图标视图关闭
            iconViewClose() {
                this.iconViewVisible = false;
            }
        },
        components: {
            NewModuleView,
            IconView
        }


    }
</script>

<style lang="scss" scoped>

</style>