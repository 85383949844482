<template>
    <div>
        <el-container>
            <el-container>
                <el-main>
                    <el-form :model="policy" ref="policy" :rules="rules" label-width="80px" :inline="false"
                        size="normal">
                        <el-form-item label="事件" prop="eventTypeSN">
                            <el-select size="small" v-model.trim="policy.eventTypeSN" placeholder="请选择">
                                <el-option v-for="item in eventTypeList" :key="item.sn" :label="item.name"
                                    :value="item.sn">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="名称" prop="name">
                            <el-input size="small" v-model.trim="policy.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                        <el-form-item label="通知方式">
                            <el-select size="small" multiple v-model.trim="policy.noticeWaySNList" placeholder="请选择">
                                <el-option v-for="item in noticeWayList" :key="item.sn" :label="item.name"
                                    :value="item.sn">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="通知组">
                            <el-select size="small" multiple v-model.trim="policy.noticeGroupSNList" placeholder="请选择">
                                <el-option v-for="item in groupList" :key="item.sn" :label="item.name" :value="item.sn">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                         <el-form-item label="通知模板" prop="noticeTemplateSN">
                            <el-select size="small" v-model.trim="policy.noticeTemplateSN" placeholder="请选择">
                                <el-option v-for="item in noticeTemplateList" :key="item.sn" :label="item.name" :value="item.sn">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submit">提交</el-button>
                            <el-button @click="close">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import PolicyService from '../../../service/notice/PolicyService';
    import NoticeTemplateService from '../../../service/notice/NoticeTemplateService';
    export default {
        data() {
            return {
                policy: JSON.parse(sessionStorage.getItem("policy")),
                noticeWayList: [],
                groupList: [],
                eventTypeList: [],
                noticeTemplateList:[],
                rules: {
                    eventTypeSN: {
                        required: true,
                        message: '请选择事件类型',
                        trigger: 'blur'
                    },
                    name: {
                        required: true,
                        message: '名称不能为空',
                        trigger: 'blur'
                    },
                    noticeTemplateSN:{
                          required: true,
                        message: '通知模板不能为空',
                        trigger: 'blur'
                    }
                }
            }
        },
        mounted() {
            this.init();
            //获取事件类型列表
            this.getEventTypeList();
            //获取通知方式
            this.getNoticeWayList();
            //获取通知组
            this.getNoticeGroupList();
            //获取通知模板列表
            this.getNoticeTemplateList();
        },
        methods: {
            //初始化
            init()
            {
                if(this.policy.id!=null)
                {
                   this.policy.noticeWaySNList=JSON.parse(sessionStorage.getItem("policy")).noticeWaySNList.split(',');
                     this.policy.noticeGroupSNList=JSON.parse(sessionStorage.getItem("policy")).noticeGroupSNList.split(',');
                }
            },
            //获取事件类型
            getEventTypeList() {
                var params = {
                    "categorySN": "eventType",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    this.eventTypeList = response.list;
                })
            },
            //获取通知方式
            getNoticeWayList() {
                var params = {
                    "categorySN": "noticeWay",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    this.noticeWayList = response.list;
                })
            },
            //获取通知组
            getNoticeGroupList() {
                var params = {
                    "categorySN": "noticeGroup",
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "status": 1
                }
                DictionaryService.getItemList(params, (response) => {
                    this.groupList = response.list;
                })
            },
            //获取通知模板列表
            getNoticeTemplateList()
            {
              var params={
                  pageIndex:1,
                  pageSize:1000,
                  status:1
              }
              NoticeTemplateService.getList(params,(response)=>{
                this.noticeTemplateList=response.list;
              })

            },
            //提交
            submit() {
                this.$refs.policy.validate((valid) => {
                    if (valid == true) {
                        this.$confirm("您确定要提交吗?", "确定", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }).then(() => {
                            var noticeWaySNList = "";
                            this.policy.noticeWaySNList.forEach(element => {
                                noticeWaySNList += (element + ",");
                            });
                            if (noticeWaySNList != "") {
                                noticeWaySNList = noticeWaySNList.substring(0, noticeWaySNList.length -
                                    1);
                            }
                            var noticeGroupSNList = "";
                            this.policy.noticeGroupSNList.forEach(element => {
                                noticeGroupSNList += (element + ",");
                            });
                            if (noticeGroupSNList != "") {
                                noticeGroupSNList = noticeGroupSNList.substring(0, noticeGroupSNList.length - 1);
                            }
                            this.policy.noticeWaySNList = noticeWaySNList;
                            this.policy.noticeGroupSNList = noticeGroupSNList;
                            if (this.policy.id == null) {
                                this.add();
                            } else {
                                this.update();
                            }
                        }).catch(() => {})

                    }
                });
            },
            //新增
            add() {
                 PolicyService.add(this.policy,(response)=>{
                     this.$emit("submit",response);
                 })
            },
            //修改
            update() {
                PolicyService.update(this.policy, (response) => {
                    this.$emit("submit", response);
                })
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>