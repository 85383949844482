import MainscreenView from '../view/mainscreen/MainscreenView';
import View_Default from '../view/mainscreen/DefaultView';
export default
{
   path:'mainScreen',
   name:'mainScreen',
   component:MainscreenView,
   children:[
      {
         path:"default",
         component:View_Default
      }
   ]
}