import AxiosHelper from '../../util/AxiosHelper';
class NoticeTemplateService
{
    //新增
   static add(paras,handle)
   {
       AxiosHelper.post("/notice/noticeTemplate/add",paras,handle);
   }
   //修改
   static update(paras,handle)
   {
    AxiosHelper.post("/notice/noticeTemplate/update",paras,handle);
   }
   //删除
   static remove(paras,handle)
   {
    AxiosHelper.post("/notice/noticeTemplate/delete",paras,handle);
   }
   //获取列表
   static getList(paras,handle)
   {
       AxiosHelper.get("/notice/noticeTemplate/getList",paras,handle);
   }
}
export default NoticeTemplateService;