import AxiosHelper from '../../util/AxiosHelper';
//供应商信息服务
class SupplierInfoService
{
    //获取供应商信息
    static getList(params,handle)
    {
        AxiosHelper.get("/supplier/supplierInfo/getList",params,handle);
    }
    //新增
    static  add(obj,handle)
    {
      AxiosHelper.post("/supplier/supplierInfo/add",obj,handle);
    }
    //修改
    static update(obj,handle)
    {
        AxiosHelper.post("/supplier/supplierInfo/update",obj,handle);
    }
     //获取文档列表
   static getDocumentList(params,handle)
   {
      AxiosHelper.get("/supplier/supplierInfo/getDocumentList",params,handle);
   }
   //删除文件
   static deleteFile(obj,handle)
   {
    AxiosHelper.post("/file/delete",obj,handle);
   }
   //下载文件
   static downloadFile(parms,handle)
   {
      AxiosHelper.download("/file/download",parms,handle);
   }
}
export default SupplierInfoService;