//供应商视图
import SupplierView from '../view/supplier/SupplierView';
//供应商列表视图
import SupplierInfoView from '../view/supplier/supplierInfo/ListView';
export default
{
    path:'supplier',
    component:SupplierView,
    children:[
       {
           path:'supplierInfo',
           component:SupplierInfoView
       }
    ]
}