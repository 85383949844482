<template>
    <div>
        <el-container>
            <el-main height="">
                <el-row>
                    <el-col :span="6" :offset="0">
                        <label for="">选择组:</label>
                    </el-col>
                    <el-col :span="18" :offset="0">
                        <el-select size="small" multiple v-model.trim="groups" placeholder="请选择">
                            <el-option v-for="item in groupList" :key="item.sn" :label="item.name" :value="item.sn">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </el-main>
            <el-footer height="">
                <el-button size="small" type="primary" @click="submit">提交</el-button>
                <el-button size="small" @click="close">关闭</el-button>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
    import DictionaryService from '../../service/systemManage/DictionaryService';
    import WorkOrderService from '../../service/WorkOrder/WorkOrderService';
    export default {
        data() {
            return {
                workOrderSN:sessionStorage.getItem("workOrderSN"),
                groupList: [],
                groups: ''
            }
        },
        mounted() {
            //获取组列表
            this.getGroupList();
        },
        methods: {
            //获取组列表
            getGroupList() {
                var params = {
                    pageIndex: 1,
                    pageSize: 100,
                    categorySN: "noticeGroup",
                    status: 1
                }
                DictionaryService.getItemList(params, (response) => {
                    this.groupList = response.list;
                })
            },
            //提交
            submit() {
                if (this.groups == "") {
                    this.$message({
                        message: '请选择组',
                        type: 'warning'
                    });
                    return;
                }
                 this.$confirm("您确定要下发此工单吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var groupSNList = "";
                            this.groups.forEach(element => {
                                groupSNList += (element + ",");
                            });
                            if (groupSNList != "") {
                                groupSNList = groupSNList.substring(0, groupSNList.length - 1);
                            }
                    var paras = {
                        sn:this.workOrderSN,
                        groups: groupSNList
                    }
                    WorkOrderService.pushOrder(paras,(response)=>{
                         this.$emit("submit",response);
                    })
                }).catch(() => {})
            },
            //关闭
            close() {
                this.$emit("close");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>