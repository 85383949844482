import AxiosHelper from '../../util/AxiosHelper';
class TaskOrderService
{
   //新增
   static add(params,handle)
   {
      AxiosHelper.post("/workOrder/workOrderInfo/add",params,handle);
   }
    //修改
    static update(params,handle)
    {
       AxiosHelper.post("/workOrder/workOrderInfo/update",params,handle);
    }
    //删除
    static delete(params,handle)
    {
       AxiosHelper.post("/workOrder/workOrderInfo/delete",params,handle);
    }
    //获取列表
   static getList(params,handle)
   {
      AxiosHelper.get("/workOrder/workOrderInfo/getList",params,handle);
   }
   //下发工单
   static pushOrder(params,handle)
   {
      AxiosHelper.post("/workOrder/workOrderInfo/pushOrder",params,handle);
   }
   //获取处理情况
   static getDoCase(params,handle)
   {
      AxiosHelper.get("/workOrder/workOrderInfo/getDoCase",params,handle);
   }
   //获取照片
   static getPicList(params,handle)
   {
      AxiosHelper.get("/workOrder/workOrderInfo/getPicList",params,handle);
   }
   //获取签收信息
   static getCheckInfo(params,handle)
   {
      AxiosHelper.get("/workOrder/workOrderInfo/getCheckInfo",params,handle);
   }
   // 导出工单
   static exportToExcel(params,handle)
   {
      AxiosHelper.export("/workOrder/workOrderInfo/exportToExcel",params,handle);
   }
}
export default TaskOrderService;