import AxiosHelper from '../../util/AxiosHelper';
class SupplierRepairService
{
    //新增
   static add(paras,handle)
   {
     AxiosHelper.post("/repair/supplierRepair/add",paras,handle);
   }
   //修改
   static update(paras,handle)
   {
    AxiosHelper.post("/repair/supplierRepair/update",paras,handle);
   }
   //删除
   static delete(paras,handle)
   {
    AxiosHelper.post("/repair/supplierRepair/delete",paras,handle);
   }
   //获取列表
   static getList(paras,handle)
   {
    AxiosHelper.get("/repair/supplierRepair/getList",paras,handle);
   }
   //发货登记
   static addExpress(paras,handle)
   {
    AxiosHelper.post("/repair/supplierRepair/addExpress",paras,handle);
   }
   //获取发货信息
   static getExpressInfo(paras,handle)
   {
    AxiosHelper.get("/repair/supplierRepair/getExpressInfo",paras,handle);
   }
   //完成
   static finish(paras,handle)
   { AxiosHelper.post("/repair/supplierRepair/finish",paras,handle);
   }
}
export default SupplierRepairService;