<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">名称:</label>
                  <el-input size="mini"  v-model.trim="name" placeholder="请输入名称"  class="inputSearch" ></el-input>
                <el-button  size="mini" icon="el-icon-search" round @click="search">查询</el-button>
                <el-button size="mini" icon="el-icon-plus" round  @click="add">新增</el-button>
            </el-header>
           <el-main height="">
               <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                       <el-table-column type="index" label="序号" width="80px">
                           <template slot-scope="scope" >
                               {{scope.$index+1+(pageIndex-1)*pageSize}}
                           </template>
                        </el-table-column>
                        <el-table-column prop="name" label="名称" >
                        </el-table-column>
                         <el-table-column prop="address" label="地址" >
                        </el-table-column>
                         <el-table-column prop="tel" label="电话" >
                        </el-table-column>
                         <el-table-column prop="contacts" label="联系人" >
                        </el-table-column>
                         <el-table-column prop="mobile" label="手机号" >
                        </el-table-column>
                        <el-table-column label="状态" width="120px">
                            <template slot-scope="scope">
                                 <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" disabled />
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120px">
                            <template slot-scope="scope">
                                  <el-dropdown>
                                        <el-button size="small">
                                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item><span @click="update(scope.row)">修改</span>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                            </template>
                        </el-table-column>
               </el-table>
               <el-footer height="">
                   <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next" :total="recordCount"
                                :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
               </el-footer>
           </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewSupplierView ref="NewSupplierView" :key="dialogWindowKey" @close="newSupplierViewClose" @submit="newSupplierViewSubmit"></NewSupplierView>
        </el-dialog>
         <el-dialog :title="dialogWindowTitle" :visible.sync="documentViewVisible" width="50%">
            <DocumentView ref="DocumentView" :key="dialogWindowKey" @close="documentViewClose">
            </DocumentView>
        </el-dialog>
    </div>
</template>

<script>
import SupplierInfoService from '../../../service/supplier/SupplierInfoService';
//新增供应商视图
import NewSupplierView from '../../../view/supplier/supplierInfo/NewView';
import DocumentView from '../../../view/supplier/supplierInfo/DocumentView';
    export default {
        data()
        {
           return{
               name:'',
               list:[],
               pageIndex:1,
               pageSize:10,
               recordCount:0,
               dialogWindowKey:'',//对话框窗口key
               dialogWindowTitle:'',//对话框窗口标题
               dialogWindowVisible:false,
               documentViewVisible:false
           }
        },
        mounted()
        {
           //获取列表
           this.getList();
        },
        methods:{
            //获取列表
            getList()
            {
               var params={
                   "name":this.name,
                   "pageIndex":this.pageIndex,
                   "pageSize":this.pageSize
               }
               SupplierInfoService.getList(params,(data)=>{
                  this.list=data.list;
                  this.recordCount=data.recordCount;
               })
            },
            //页码改变
            pageChanged(pageIndex)
            {
               this.pageIndex=pageIndex;
               this.getList();
            },
            //新增
            add()
            {
                this.dialogWindowTitle="新增供应商";
              this.dialogWindowKey=(new Date()).getTime();
              this.dialogWindowVisible=true;
            },
            //修改
            update(obj)
            {
                  this.dialogWindowTitle="编辑供应商";
                this.dialogWindowKey=(new Date()).getTime();
                this.dialogWindowVisible=true;
                 this.$nextTick(()=>{
                    this.$refs.NewSupplierView.supplierInfo=Object.assign({},obj);
                });
            },
            //查询
            search()
            {
               this.getList();
            },
            //新增供应商视图关闭
            newSupplierViewClose()
            {
               this.dialogWindowVisible=false;
            },
             documentViewClose()
            {
                this.documentViewVisible=false;
            },
            //资料库
            document(obj)
            {
                 this.dialogWindowTitle = "供应商资料库";
                 sessionStorage.setItem("supplierSN",obj.sn);
                this.dialogWindowKey = (new Date()).getTime();
                this.documentViewVisible = true;
            },
            //新增供应商视图提交
            newSupplierViewSubmit(data)
            {
                this.$message({
                   message:data.msg,
                   type:'success'
               })
                this.dialogWindowVisible=false;
               this.getList();
            }
        },
        components:{
            NewSupplierView,DocumentView
        }

        
    }
</script>

<style lang="scss" scoped>

</style>