<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">设备类型:</label>
                <el-select size="small" v-model="typeSN" filterable placeholder="请选择" style="width:160px">
                    <el-option v-for="item in equipmentTypeList" :key="item.sn" :label="item.name" :value="item.sn">
                    </el-option>
                </el-select>
                <label for="">区域:</label>
                <el-select size="small" v-model="regionSN" placeholder="请选择" style="width:160px;margin-right:1em">
                    <el-option v-for="item in regionList" :key="item.sn" :label="item.name" :value="item.sn">
                    </el-option>
                </el-select>
                <label for="">状态:</label>
                <el-select size="small" v-model="equipmentStatus" placeholder="请选择" style="width:120px">
                    <el-option v-for="item in equipmentStatusList" :key="item.value" :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>
                <label for="">名称:</label>
                <el-input size="small" v-model.trim="name" placeholder="请输入名称" class="inputSearch"></el-input>
                <el-button size="small" icon="el-icon-search" round @click="search">查询</el-button>
                <el-button size="small" icon="el-icon-plus" round @click="add">新增</el-button>
            </el-header>
            <el-main height="">
                <el-table size="small" v-loading="$store.state.loading" :data="list" border stripe
                    header-cell-class-name="tableHeader">
                    <el-table-column type="index" label="序号" width="60px">
                        <template slot-scope="scope">
                            {{scope.$index+1+(pageIndex-1)*pageSize}}
                        </template>
                    </el-table-column>
                    <el-table-column label="类型">
                        <template slot-scope="scope">
                            {{scope.row.equipmentTypeName}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <!-- <el-table-column prop="style" label="规格/型号">
                    </el-table-column> -->
                    <el-table-column prop="ip" label="IP地址">
                    </el-table-column>
                    <el-table-column prop="regionName" label="区域">
                    </el-table-column>
                    <el-table-column label="在线" width="50px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==1">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==2">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF9900;display: inline-block; border-radius: 0.4em;">
                                </div>&nbsp;<span></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="录像" width="50px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.equipmentTypeSN=='p9yr1630549368677'">
                                <span>--</span>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.status==0">
                                    <div
                                        style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="scope.row.equipmentTypeSN=='bMIp1630549379899'">
                                        <div v-if="scope.row.recordStatus==0">
                                            <div
                                                style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                            </div>
                                        </div>
                                        <div v-if="scope.row.recordStatus==1">
                                            <div
                                                style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="信号" width="50px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.equipmentTypeSN=='p9yr1630549368677'">
                                <span>--</span>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.status==0">
                                    <div
                                        style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="scope.row.signalStatus==1">
                                        <div
                                            style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                        </div>
                                    </div>
                                    <div v-if="scope.row.signalStatus==0">
                                        <div
                                            style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="硬件故障" width="100px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.equipmentTypeSN=='p9yr1630549368677'">
                                <span>--</span>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.status==0">
                                    <div
                                        style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="scope.row.hardwareStatus==1">
                                        <div
                                            style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                        </div>
                                    </div>
                                    <div v-if="scope.row.hardwareStatus==0">
                                        <div
                                            style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="操作" width="120px">
                        <template slot-scope="scope">
                            <el-dropdown>
                                <el-button size="small" >
                                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item><span @click="update(scope.row)">编辑</span></el-dropdown-item>
                                    <el-dropdown-item divided=true><span @click="deleteEquipment(scope.row)">删除</span></el-dropdown-item>
                                     <el-dropdown-item v-if="scope.row.equipmentTypeSN=='p9yr1630549368677'" divided=true><span @click="diskInfo(scope.row)">磁盘信息</span></el-dropdown-item>
                                    <el-dropdown-item divided=true><span @click="alarmLog(scope.row)">故障记录</span></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer height="">
                    <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewEquipmentInfoView ref="NewEquipmentInfoView" :key="dialogWindowKey" @close="equipmentInfoViewClose"
                @submit="equipmentInfoViewSubmit"></NewEquipmentInfoView>
        </el-dialog>
        <el-dialog :title="dialogWindowTitle" :visible.sync="detailViewVisible" width="50%">
            <EquipmentInfoDetailView ref="EquipmentInfoDetailView" :key="dialogWindowKey" @close="detailViewClose">
            </EquipmentInfoDetailView>
        </el-dialog>
        <el-dialog :title="dialogWindowTitle" :visible.sync="documentViewVisible" width="50%">
            <DocumentView ref="DocumentView" :key="dialogWindowKey" @close="documentViewClose">
            </DocumentView>
        </el-dialog>
        <el-dialog :title="dialogWindowTitle" :visible.sync="alarmLogViewVisible" width="70%">
            <AlarmLogView ref="AlarmLogView" :key="dialogWindowKey" @close="alarmlogViewClose">
            </AlarmLogView>
        </el-dialog>
          <el-dialog :title="dialogWindowTitle" :visible.sync="diskInfoViewVisible" width="70%">
            <DiskInfoView ref="AlarmLogView" :key="dialogWindowKey">
            </DiskInfoView>
        </el-dialog>
    </div>
</template>
<script>
    import DictionaryService from '../../../service/systemManage/DictionaryService';
    import equipmentInfoService from '../../../service/equipment/EquipmentInfoService';
    import NewEquipmentInfoView from '../../../view/equipment/equipmentInfo/NewView';
    import EquipmentInfoDetailView from '../../../view/equipment/equipmentInfo/DetailView';
    import DocumentView from '../../equipment/equipmentInfo/DocumentView';
    import RegionService from '../../../service/region/RegionService';
    import AlarmLogView from './AlarmLogView.vue';
    import DiskInfoView from './DiskInfoView.vue';
    export default {
        data() {
            return {
                name: '',
                regionSN: '',
                typeSN: '',
                regionList: [{
                    sn: '',
                    "name": "全部"
                }],
                equipmentTypeList: [{
                    sn: '',
                    "name": "全部"
                }],
                equipmentStatus: '',
                equipmentStatusList: [{
                        value: '',
                        "name": "全部"
                    },
                    {
                        value: 1,
                        "name": "在线"
                    },
                    {
                        value: 0,
                        "name": "离线"
                    }
                ],
                list: [],
                pageIndex: 1,
                pageSize: 10,
                recordCount: 0,
                dialogWindowKey: '', //对话框窗口key
                dialogWindowTitle: '', //对话框窗口标题
                dialogWindowVisible: false,
                detailViewVisible: false,
                documentViewVisible: false,
                alarmLogViewVisible: false,
                diskInfoViewVisible:false
            }
        },
        mounted() {
            //获取区域列表
            this.getRegionList();
            //获取设备类型
            this.getEquipmentTypeList();
            //获取列表
            this.getList();
        },
        methods: {
            //获取区域列表
            getRegionList() {
                var paras = {
                    pageIndex: 1,
                    pageSize: 1000
                };
                RegionService.getList(paras, (response) => {
                    response.list.forEach(item => {
                        this.regionList.push(item);
                    });
                });
            },
            //获取设备类型列表
            getEquipmentTypeList() {
                var params = {
                    "pageIndex": 1,
                    "pageSize": 1000,
                    "categorySN": "equipmentType",
                    "status": 1
                }
                DictionaryService.getItemList(params, (data) => {
                    data.list.forEach(item => {
                        this.equipmentTypeList.push(item);
                    });
                })
            },
            //获取列表
            getList() {
                var params = {
                    typeSN: this.typeSN,
                    regionSN: this.regionSN,
                    status: this.equipmentStatus,
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                equipmentInfoService.getList(params, (data) => {
                    this.list = data.list;
                    this.recordCount = data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
                this.getList();
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增设备";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
            },
            //详情
            detail(obj) {
                this.dialogWindowTitle = "设备详情";
                this.dialogWindowKey = (new Date()).getTime();
                this.detailViewVisible = true;
                this.$nextTick(() => {
                    this.$refs.EquipmentInfoDetailView.equipmentInfo = Object.assign({}, obj);
                });
            },
            //磁盘信息
            diskInfo(obj)
            {
                this.dialogWindowTitle = "磁盘信息";
                this.dialogWindowKey = (new Date()).getTime();
                sessionStorage.setItem("equipmentInfo",JSON.stringify(obj));
                this.diskInfoViewVisible = true;

            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "编辑设备";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
                this.$nextTick(() => {
                    this.$refs.NewEquipmentInfoView.equipmentInfo = Object.assign({}, obj);
                });
            },
            //告警记录
            alarmLog(obj) {
                this.dialogWindowTitle = "故障记录";
                this.dialogWindowKey = (new Date()).getTime();
                this.alarmLogViewVisible = true;
                sessionStorage.setItem("equipmentSN", obj.sn);
            },
            //告警记录视图关闭
            alarmlogViewClose() {
                this.alarmLogViewVisible = false;
            },
            //删除设备
            deleteEquipment(obj) {
                this.$confirm("您确定要删除此设备吗?", "确定", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    var paras = {
                        sn: obj.sn
                    }
                    equipmentInfoService.deleteEquipment(paras, (response) => {
                        this.$message({
                            showClose: true,
                            message: response.msg,
                            type: 'success'
                        });
                        this.getList();
                    })
                }).catch(() => {})
            },
            //查询
            search() {
                this.getList();
            },
            //关闭
            equipmentInfoViewClose() {
                this.dialogWindowVisible = false;
            },
            detailViewClose() {
                this.detailViewVisible = false;
            },
            documentViewClose() {
                this.documentViewVisible = false;
            },
            //资料库
            document(obj) {
                this.dialogWindowTitle = "设备资料库";
                sessionStorage.setItem("equipmentSN", obj.sn);
                this.dialogWindowKey = (new Date()).getTime();
                this.documentViewVisible = true;
            },
            //提交
            equipmentInfoViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            }
        },
        components: {
            NewEquipmentInfoView,
            EquipmentInfoDetailView,
            DocumentView,
            AlarmLogView,
            DiskInfoView
        }


    }
</script>

<style lang="scss" scoped>
</style>