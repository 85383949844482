<template>
    <div>
        <el-container>
            <el-header height="">
                <label for="">用户ID:</label>
                <el-input size="mini" v-model.trim="userId" placeholder="请输入用户ID" class="inputSearch"></el-input>
                <label for="">名称:</label>
                <el-input size="mini" v-model.trim="name" placeholder="请输入姓名" class="inputSearch"></el-input>
                <el-button @click="search" size="mini" icon="el-icon-search" round>查询</el-button>
                <el-button @click="add" size="mini" icon="el-icon-plus" round>新增</el-button>
            </el-header>
            <el-main height="">
                <el-table size="mini" :data="list" border stripe header-cell-class-name="tableHeader">
                    <el-table-column type="index" label="序号" width="80px">
                        <template slot-scope="scope">
                            {{(scope.$index+1)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="userId" label="用户ID">
                    </el-table-column>
                    <el-table-column prop="name" label="姓名">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="部门">
                    </el-table-column>
                    <el-table-column prop="roleName" label="角色">
                    </el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.status==0">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#FF6666;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                            <div v-if="scope.row.status==1">
                                <div
                                    style="width:0.8em;height:0.8em;background-color:#99CC99;display: inline-block; border-radius: 0.4em;">
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"  width="240px">
                        <template slot-scope="scope">
                            <el-button size="mini" icon="el-icon-edit" @click="update(scope.row)" round>编辑</el-button>
                            <!-- <el-button size="mini" icon="el-icon-setting" @click="setRight(scope.row.userId)" round>权限设置
                            </el-button> -->
                            <el-button size="mini" icon="el-icon-refresh" @click="resetPassword(scope.row.userId)" round>重置密码
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-footer height="">
                    <el-row>
                        <el-col :span="24" align="right">
                            <el-pagination v-show="recordCount>pageSize" background layout="prev, pager, next"
                                :total="recordCount" :page-size="pageSize" @current-change="pageChanged">
                            </el-pagination>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-main>
        </el-container>
        <!--新增视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="dialogWindowVisible" width="50%">
            <NewUserView ref="NewUserView" :key="dialogWindowKey" @close="newUserViewClose" @submit="newUserViewSubmit">
            </NewUserView>
        </el-dialog>
        <!-- 重置密码视图 -->
        <el-dialog :title="dialogWindowTitle" :visible.sync="resetPasswordViewVisible" width="50%">
            <ResetPasswordView ref="ResetPasswordView" :key="dialogWindowKey" @close="resetPasswordViewClose">
            </ResetPasswordView>
        </el-dialog>
        <!-- 权限视图-->
        <el-dialog :title="dialogWindowTitle" :visible.sync="rightViewVisible" width="50%">
            <RightView ref="RightView" :key="dialogWindowKey" @close="rightViewClose">
            </RightView>
        </el-dialog>
    </div>
</template>

<script>
    import UserService from '../../../service/systemManage/UserService';
    import NewUserView from '../../../view/systemManage/user/NewView';
    import ResetPasswordView from '../../../view/systemManage/user/ResetPasswordView';
    import RightView from '../../../view/systemManage/user/RightView';
    export default {
        data() {
            return {
                "userId": '',
                "name": '',
                "list": [],
                "pageIndex": 1,
                "pageSize": 10,
                "recordCount": 0,
                "dialogWindowKey": '', //对话框窗口key
                "dialogWindowTitle": '', //对话框窗口标题
                "dialogWindowVisible": false,
                "resetPasswordViewVisible": false,
                "rightViewVisible": false
            }
        },
        mounted() {
            //获取列表
            this.getList();
        },
        methods: {
            //获取列表
            getList() {
                var params = {
                    "userId": this.userId,
                    "name": this.name,
                    "pageIndex": this.pageIndex,
                    "pageSize": this.pageSize
                }
                if( localStorage.getItem("userId")=="system")
                {
                    params.isSystemAccount=1;
                }
                else
                {
                    params.isSystemAccount=0;
                }
                UserService.getList(params, (data) => {
                    this.list = data.list;
                    this.recordCount = data.recordCount;
                })
            },
            //页码改变
            pageChanged(pageIndex) {
                this.pageIndex = pageIndex;
            },
            //新增
            add() {
                this.dialogWindowTitle = "新增用户";
                this.dialogWindowKey = (new Date()).getTime();
                this.dialogWindowVisible = true;
            },
            //修改
            update(obj) {
                this.dialogWindowTitle = "修改用户";
                this.dialogWindowKey = (new Date()).getTime();
                this.$nextTick(() => {
                    this.$refs.NewUserView.user = Object.assign({}, obj);
                });
                this.dialogWindowVisible = true;
            },
            //设置权限
            setRight(userId) {
                sessionStorage.setItem("userId",userId);
                this.dialogWindowTitle = "权限设置";
                this.dialogWindowKey = (new Date()).getTime();
                this.rightViewVisible = true;
            },
            //重置密码
            resetPassword(userId) {
                this.dialogWindowTitle = "重置密码";
                this.dialogWindowKey = (new Date()).getTime();
                this.$nextTick(() => {
                    this.$refs.ResetPasswordView.passwordInfo.userId = userId;
                });
                this.resetPasswordViewVisible = true;
            },
            //查询
            search() {
                this.getList();
            },
            //新增用户视图关闭
            newUserViewClose() {
                this.dialogWindowVisible = false;
            },
            //重置密码视图关闭
            resetPasswordViewClose() {
                this.resetPasswordViewVisible = false;
            },
            //新增用户视图提交
            newUserViewSubmit(data) {
                this.$message({
                    message: data.msg,
                    type: 'success'
                })
                this.dialogWindowVisible = false;
                this.getList();
            },
            //权限视图关闭
            rightViewClose() {
                this.rightViewVisible = false;
            }
        },
        components: {
            NewUserView,
            ResetPasswordView,
            RightView
        }


    }
</script>

<style lang="scss" scoped>

</style>